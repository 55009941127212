// CLIENT ONLY FILE
import Evaporate from 'evaporate'
import Md5 from 'js-md5'
import { sha256 as Sha256 } from 'js-sha256'

import { upsertAdminString } from '@editor/admin/adminStore'
import { MediaInterface, MediaPlaybackState } from '@editor/interfaces'

type UploadCallbacks = {
  complete?: () => void
  progress?: (_value: number) => void
}

/**
 * Calls uploadAsset to upload media's file and handles setting of playback state state on media
 */
export const uploadMediaToCloud = async (
  media: MediaInterface,
  callbacks?: UploadCallbacks
): Promise<void> => {
  const { file, _id } = media

  if (!file) {
    throw new Error('File not found')
  }

  try {
    await uploadAsset(_id, file, undefined, callbacks)
    await media.updateMediaPlaybackState(MediaPlaybackState.uploaded)
  } catch (err) {
    media.updateMediaPlaybackState(MediaPlaybackState.failedToUpload)
    console.error(err)
  }
}

export const createUploader = (config?: Partial<Evaporate.CreateConfig>) => {
  const uploader =
    typeof window !== 'undefined'
      ? Evaporate.create({
          signerUrl: '/api/cdn/signMultipartUpload',
          aws_key: process.env.NEXT_PUBLIC_AWS_KEY_ID!,
          bucket: process.env.NEXT_PUBLIC_AWS_BUCKET_NAME!,
          awsRegion: process.env.NEXT_PUBLIC_AWS_REGION!,
          awsSignatureVersion: '4',
          cloudfront: true,
          sendCanonicalRequestToSignerUrl: true,
          logging: false,
          computeContentMd5: true,
          cryptoHexEncodedHash256: (data) => {
            const crypto = Sha256.create()
            crypto.update(data!)
            return crypto.hex()
          },
          cryptoMd5Method: (data) => {
            const crypto = Md5.create()
            crypto.update(data)
            return crypto.base64()
          },
          ...config
        })
      : undefined

  return uploader
}

const defaultUploader = createUploader()

/**
 * Uploads a given file to /api/cdn/:id url using evaporate
 */
export const uploadAsset = async (
  id: string,
  file: File,
  overwriteUploader?: Promise<Evaporate>,
  callbacks?: UploadCallbacks
) => {
  if (!defaultUploader) throw new Error('Evaporate instance not there')
  const uploaderInstance =
    overwriteUploader !== undefined
      ? await overwriteUploader
      : await defaultUploader

  await uploaderInstance.add({
    file,
    name: id,
    contentType: file.type,
    progress: (progress, stats) => {
      if (stats.readableSpeed) {
        upsertAdminString(
          'AnnoProject.uploadSpeed',
          stats.readableSpeed,
          'Upload speed'
        )
      }
      if (callbacks?.progress) callbacks.progress(progress)
    },
    complete: () => {
      if (callbacks?.complete) callbacks.complete()
    },
    error: (msg) => {
      throw new Error(msg)
    }
  })
}
