import { action, makeAutoObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import { addManualAsset } from '@editor/api/cdn/utils'
import { typedMutation } from '@editor/graphql/client'
import { setProjectId } from '@editor/graphql/config'
import {
  UpdateProjectThumbnailDocument,
  UpdateProjectThumbnailMutationFn
} from '@graphql/schema/client'
import { Project } from '@prisma/client'

import multiplayerStore from './multiplayerStore'
import settingStore from './settingsStore'
import videoContextStore from './videoContextStore'

class ProjectStore {
  /**
   * @deprecated Not used anywhere and not being updated
   */
  @observable projectId: string = 'default'

  @observable project: Project | undefined
  @observable owner: string | undefined
  @observable projectName: string | undefined

  @observable isThumbnailUpdating = false

  constructor() {
    makeAutoObservable(this)
  }

  setProjectLink = (owner: string) => {
    this.owner = owner
  }

  getProjectLink = () => {
    if (this.owner && this.project) {
      return `/${this.owner}/${this.project?.projectName}`
    }
    return undefined
  }

  setProject = async (project: Project) => {
    setProjectId(project.id)
    this.project = project
    settingStore.setSettings({
      ...settingStore.settings,
      dimensions: {
        // BANG: know dimensions shape
        width: project.dimensions[0]!,
        height: project.dimensions[1]!
      }
    })
    settingStore.updateName(project.projectName + '.mp4')

    await multiplayerStore.startYjs({
      firstStart: false,
      isMultiplayer: project.multiplayer
    })
  }

  @action updateProject = (newData: Partial<Project>) => {
    if (!this.project) throw new Error('No project to update')
    this.project = { ...this.project, ...newData }
  }

  /**
   * Generated a thumbnail at current playhead and updates it in the project
   */
  @action updateThumbnail = async () => {
    if (this.isThumbnailUpdating) return

    if (!this.project)
      throw new Error("Can't make thumbnail because you're not in a project")

    this.isThumbnailUpdating = true

    const blob = await videoContextStore.takeScreenshot()
    if (!blob) {
      this.isThumbnailUpdating = false
      throw new Error("Couldn't get blob of video context canvas")
    }

    try {
      const projectId = this.project.id
      const id = `${this.project.projectName}-${nanoid()}.png`
      const thumbnail = await addManualAsset({
        id,
        data: blob,
        contentType: blob.type
      })

      await typedMutation<UpdateProjectThumbnailMutationFn>().mutate({
        mutation: UpdateProjectThumbnailDocument,
        variables: { project: { thumbnail }, updateProjectId: projectId }
      })

      this.updateProject({ thumbnail })
    } catch (err) {
      console.error('Error while updating project thumbnail', err)
    }

    this.isThumbnailUpdating = false
  }
}

export default new ProjectStore()
