import {
  MediaPosition,
  MediaProps,
  MediaType,
  VideoFormat,
  VideoInterface,
  Widen
} from '@editor/interfaces'
import {
  getAudioBufferFromUrl,
  getPathFromAudioBuffer
} from '@editor/mediahelpers/audio'

import { getVideoThumbnail } from '../helpers/video'
import { getVideoProps } from '../utils'

import { getFileProps, Media } from './Media'

export type getVideoFileProps = Required<getFileProps>

export type VideoProps = Widen<
  Omit<MediaProps, 'type'> & {
    _id: string
    format: string
    defaultPosition: MediaPosition
  }
>

export class Video extends Media implements VideoInterface {
  format: VideoFormat
  defaultPosition: MediaPosition

  constructor({ format, defaultPosition, ...props }: VideoProps) {
    super({
      ...props,
      type: MediaType.video
    })
    this.format = format
    this.defaultPosition = defaultPosition
  }

  static getFileProps = async (file: File) => {
    return Media.getFileProps(
      file,
      MediaType.video
    ) as Promise<getVideoFileProps>
  }

  static getUrlProps = async (url: string) => {
    return getVideoProps(url)
  }

  generateThumbnail = async () => {
    try {
      const thumbBlob = await getVideoThumbnail(this.source)
      const thumbnail = URL.createObjectURL(thumbBlob)
      return thumbnail
    } catch (err) {
      // An error might occur if a user is in multiplayer and a media
      // is added but not yet uploaded, so is safe to ignore for now
      console.error(err)
      return ''
    }
  }

  /**
   * Generates a svg path of the the video's audio waveform
   */
  generateSvgPath = async () => {
    const audioBuffer = await getAudioBufferFromUrl(this.source)

    const path = getPathFromAudioBuffer(audioBuffer)

    return path
  }
}
