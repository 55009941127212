import React from 'react'

import { Cursor } from '@editor/components/icons/Cursor'
import { MultiplayerUser, TimelineMode } from '@editor/interfaces'

const MultiplayerUserCursor = ({ user }: { user: MultiplayerUser }) => {
  return (
    <div
      className="absolute pointer-events-none z-50 flex items-start multiplayer-cursor"
      // TODO CHECK IF OUTSIDE USERS VIEW AND DON'T SHOW
      style={{ top: user.state.cursorY, left: user.state.cursorX }}>
      <div
        className="absolute w-6 h-6 rounded-full -translate-x-1/2 -translate-y-1/2"
        style={{
          backgroundColor: user.color,
          opacity: 0.3,
          zIndex: 45
        }}
      />

      <Cursor
        className="w-4 h-4"
        color={user.color}
        type={user.state.timelineMode === TimelineMode.cut ? 'cut' : 'default'}
      />

      <span
        className="ml-1 px-1.5 capitalize text-gray-300 py-1 rounded-sm -translate-y-1/2 text-xs"
        style={{ backgroundColor: user.color, lineHeight: '1' }}>
        {user.name}
      </span>
    </div>
  )
}

export default MultiplayerUserCursor
