import { action, makeAutoObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

/**
 * Global store for managing the context menu. This usually shouldn't be accessed
 * directly, the `useContextMenu` hook provides abstractions for enabling/disabling
 * the contet menu.
 *
 * There can be only one context menu active at a time, so components must
 * "reserve" access via the `useContextMenu` hook.
 *
 * I haven't tested this extensively so there will be bugs.
 */
class ContextMenuStore {
  constructor() {
    makeAutoObservable(this)
  }

  @observable isOpen = false
  @observable activeId = nanoid()

  @action toggleOpen = () => {
    this.isOpen = !this.isOpen
  }

  @action setOpen = (open: boolean) => {
    this.isOpen = open
  }

  @action disableIfEnabled = (id?: string) => {
    if (this.isOpen) {
      if (!!id && this.activeId !== id) return
      this.isOpen = false
    }
  }

  @action enableIfDisabled = (id: string) => {
    if (!this.isOpen) {
      this.activeId = id
      this.isOpen = true
    }
  }
}

export default new ContextMenuStore()
