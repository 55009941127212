/**
 * Returns a string in the format hh:mm:ss from a number string
 * @param secs number which is in seconds
 * @param milliSecondPrecession This is number of milliseconds you want to show Max is 3
 * @returns
 */
const getSecondsInTimeStamp = (
  secs: number,
  milliSecondPrecession?: number
) => {
  // '1970-01-01T00:00:30.000Z'
  // We want from the 11th character
  let numberOfChars = 8
  if (milliSecondPrecession && milliSecondPrecession > 0) {
    numberOfChars += Math.min(milliSecondPrecession, 3) + 1 // The max millisecond precession is 3 and the +1 is for the '.'
  }

  if (!secs) return `00:00`

  const timeStamp = new Date(secs * 1000)
    .toISOString()
    // We don't want the Z
    .substr(11, numberOfChars)
  return timeStamp
}

/**
 * Returns a string in the format hh:mm:ss based on if there are
 * @param secs number which is in seconds
 */
const parseCommentSeconds = (secs: number) => {
  if (secs === null)
    return {
      readable: '',
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    }

  const date = new Date(secs * 1000)

  let readable = ''

  if (date.getUTCHours()) {
    readable = date.toISOString().slice(11, -5)
  }
  readable = date.toISOString().slice(14, -5)

  return {
    readable,
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    seconds: date.getUTCSeconds(),
    milliseconds: date.getUTCMilliseconds()
  }
}

export default getSecondsInTimeStamp

export { getSecondsInTimeStamp, parseCommentSeconds }
