import {
  Container,
  MediaElementId,
  MediaElementInterface,
  MediaElements,
  MediaTrack,
  TrackType
} from '@editor/interfaces'
import { getRealStartAndEnd, warnError } from '@editor/utils'

import { mediaStore } from '../../index'

// Main file for all things tracks

/**
 * Returns an array of string[] which is the track from the trackType and index
 *
 * This function is basically used to abstract away the prime check as the prime track doesn't have subtracks
 *
 * @param tracks - All the tracks
 * @param trackType - {@link TrackType}
 * @param index  - index within the tracktype
 * @returns string[]
 */
export const getTrackFromType = (
  tracks: MediaTrack,
  trackType: TrackType,
  index = 0
) => {
  if (tracks && tracks[trackType]) {
    if (trackType === 'prime') {
      return tracks.prime
    } else {
      if (!tracks[trackType][index]) {
        return null
      }
      return tracks[trackType][index]
    }
  }
  throw Error('Tracks or given trackType is undefined')
}

/**
 * Returns string[] which is the track from the container object
 * @param container - {@link Container} {trackType: 'prime' | 'above' | 'below', index: number}
 * @returns string[]
 */
export const getTrackFromContainer = (container: Container) => {
  const tracks = mediaStore.tracks

  const { trackType, index } = container

  if (trackType === 'prime') {
    return tracks.prime
  } else {
    if (tracks[trackType][index] === undefined) {
      throw new Error('The track referenced in the container does not exists')
    }

    return tracks[trackType][index]!
  }
}

/**
 * WARNING: SLOW ALGO FOR LARGE N (No of tracks) or M(No of Elements in track)
 * O(N*M)
 */
export const getTrackFromMediaElementId = (id: string): Container | null => {
  const tracks = mediaStore.tracks

  const prime = tracks.prime

  if (prime.indexOf(id) !== -1) {
    return { trackType: 'prime', index: 0 }
  } else {
    for (const [index, track] of tracks.above.entries()) {
      if (track.indexOf(id) !== -1) {
        return { trackType: 'above', index }
      }
    }

    for (const [index, track] of tracks.below.entries()) {
      if (track.indexOf(id) !== -1) {
        return { trackType: 'below', index }
      }
    }
  }
  return null
}

export const getZIndexFromContainer = (container: Container) => {
  const { trackType, index } = container

  if (trackType === 'prime') {
    return 0
  } else if (trackType === 'above') {
    return index + 1
  } else {
    return -(index + 1)
  }
}

/**
 * Checks if a track is empty at a given time
 */
const checkTrackIsEmptyAtTimeStamp = (
  track: Array<MediaElementInterface | undefined>,
  time: number
) => {
  for (const mediaElement of track) {
    if (!mediaElement) {
      warnError(
        'Media element on track is undefined, while checking tracks for empty space'
      )
      return
    }
    const { realStart, realEnd } = getRealStartAndEnd(mediaElement)

    if (realStart <= time && realEnd >= time) {
      return false
    }
  }
  return true
}

/**
 * Moves a media element to a new track and removes the element from the old track
 * The function will copy all tracks and return a new tracks object, use mediaStore.updateTracks with this
 */
export const moveMediaElementToTrack = (
  mediaId: MediaElementId,
  fromTrack: Container,
  toTrack: Container,
  overwriteTracks?: MediaTrack
) => {
  const tracks = overwriteTracks
    ? { ...overwriteTracks }
    : { ...mediaStore.tracks }

  const fromTrackType = fromTrack.trackType
  const toTrackType = toTrack.trackType

  const fromTrackIndex = fromTrack.index
  const toTrackIndex = toTrack.index

  const fromTrackArray = getTrackFromType(tracks, fromTrackType, fromTrackIndex)
  const toTrackArray = getTrackFromType(tracks, toTrackType, toTrackIndex)

  if (!fromTrackArray || !toTrackArray) {
    warnError("Can't move media element to track, track is undefined")
    return
  }

  const fromTrackIndexInArray = fromTrackArray.indexOf(mediaId)
  const toTrackIndexInArray = toTrackArray.indexOf(mediaId)

  if (fromTrackIndexInArray === -1) {
    throw new Error('Media element is not in the track')
  }

  if (toTrackIndexInArray !== -1) {
    throw new Error('Media element is already in the track')
  }

  fromTrackArray.splice(fromTrackIndexInArray, 1)
  toTrackArray.push(mediaId)

  return tracks
}

/**
 * Finds lowest available track to add a text box in
 */
export const findEmptyContainerAtTimestamp = (
  tracks: MediaTrack,
  mediaElements: MediaElements,
  time: number
): Container => {
  // Will ignore bottom tracks here

  for (const [index, track] of tracks.above.entries()) {
    if (
      checkTrackIsEmptyAtTimeStamp(
        track.map((id) => mediaElements[id]),
        time
      )
    ) {
      return { trackType: 'above', index }
    }
  }

  return { trackType: 'above', index: tracks.above.length }
}

// Utility object that holds functions about track
export const track = {
  getTrackFromType,
  getTrackFromContainer,
  getTrackFromMediaElementId,
  getZIndexFromContainer,
  findEmptyContainerAtTimestamp,
  moveMediaElementToTrack
}
