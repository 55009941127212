import { makeAutoObservable, observable } from 'mobx'

class CacheStore {
  constructor() {
    makeAutoObservable(this)
  }

  @observable private handle: Cache | undefined
  @observable name = 'modfy'

  async getHandle() {
    if (!this.handle) {
      this.handle = await caches.open(this.name)
    }
    return this.handle
  }
}

export default new CacheStore()
