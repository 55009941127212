import { action, makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import {
  MediaPlaybackState,
  MediaType,
  TextInterface
} from '@editor/interfaces'
import { warnError } from '@editor/utils/warnError'

import {
  multiplayerStore,
  previewSelectMedia,
  store,
  videoContextStore
} from '../../index'
import { getTrackFromMediaElementId, getZIndexFromContainer } from '../utils'

import { StaticMedia } from './StaticMedia'

type Style = TextInterface['style']

export type TextProps = Pick<TextInterface, 'text'> & {
  _id?: string
  style?: Partial<Style>
  autoFocus?: boolean
}

const defaultStyle: Style = {
  fontSize: 48,
  textColor: '#fff',
  fontFamily: 'Inter',
  backgroundColor: 'rgba(0,0,0,0)',
  borderRadius: 0,
  fontWeight: 400,
  textAlign: 'left',
  lineHeight: 1, // will make it take the height of font itself
  verticalAlign: 'top'
}

export class Text extends StaticMedia implements TextInterface {
  // This will trigger the text element to focus
  // By default it will be false
  autoFocus: boolean = false
  text: string
  defaultPosition = null

  constructor({ text, style, _id, autoFocus }: TextProps) {
    super({
      _id: _id || nanoid(),
      name: text,
      type: MediaType.text,
      duration: Infinity,
      mediaPlaybackState: MediaPlaybackState.playable
    })
    this.text = text
    this.style = { ...defaultStyle, ...style }
    this.elements = [this._id]
    this.autoFocus = autoFocus || false

    // NOTE: Initialize all class variables before calling the makeObservable function
    // because when makeObservable is called before initializing them it will see that those properties are undefined
    // Had an error in prod with message: "Uncaught Error: [MobX] minified error nr: 1 observable,ObservableObject.text."
    makeObservable(this, {
      text: observable,
      style: observable,
      updateText: action,
      updateStyle: action
    })
  }

  elements: [string]

  style: Style

  /**
   * Returns first media element if there are multiple
   */
  get mediaElement() {
    const element = this.getMediaElement(this.elements[0])
    if (!element)
      throw new Error(`Text ${this._id}'s media element is undefined`)
    return element
  }

  reDrawText = () => {
    const { _id } = this
    const mediaElement = this.mediaElement
    if (!mediaElement) {
      return warnError('Error drawing text', {
        elements: this.elements,
        mediaElement: store.getState().media.mediaElements
      })
    }
    const { type, startOnTimeline, duration } = mediaElement

    const container = getTrackFromMediaElementId(_id)
    if (container) {
      const zIndex = getZIndexFromContainer(container)
      videoContextStore.reDrawTextOnCanvas({
        id: _id,
        textInterface: this,
        startTime: startOnTimeline,
        endTime: startOnTimeline + duration,
        type,
        zIndex: zIndex
      })
    }
  }

  private _handleTextSideEffects = (
    ignore?: boolean,
    options?: { skipDraw?: boolean; skipUpdate?: boolean }
  ) => {
    const { skipDraw, skipUpdate } = options || {
      skipDraw: false,
      skipUpdate: false
    }
    if (ignore) return
    if (!skipDraw) this.reDrawText()
    if (!skipUpdate) this._updateMultiplayer()
  }

  private _updateMultiplayer = () => {
    const { multiplayerInstance } = multiplayerStore
    multiplayerInstance?.setMedia({ ...this })
  }

  updateStyle = (newStyle: Partial<Style>, ignoreSideEffects?: boolean) => {
    this.style = { ...this.style, ...newStyle }
    this._handleTextSideEffects(ignoreSideEffects)
  }

  updateText = (newText: string, ignoreSideEffects?: boolean) => {
    this.text = newText
    this._handleTextSideEffects(ignoreSideEffects)
  }

  /**
   * This is a function used to focus the text for the first time once it is created
   * It is only meant to focus the element for the user that created it
   *
   * TODO: Move playhead automatically into where the text is created so it can be focused
   */
  focusElement = () => {
    if (this.autoFocus) {
      // Texts only has one media element so not a problem
      const mediaElement = this.mediaElement
      if (!mediaElement) return warnError(`Media element undefined`)
      store.dispatch(previewSelectMedia({ ...mediaElement }))
      // This behaviour should be only one time
      this.autoFocus = false
    }
  }
}
