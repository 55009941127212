/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

/**
 * 1 is success and 0 indicates an error
 *
 * These are semnatics carried over from the C/C++ program
 * because it's easier to do check the return with if statements
 */
export enum FramecodecStatusCode {
  Error = 0,
  Success = 1
}

type EnvironmentType = 'WEB' | 'NODE' | 'SHELL' | 'WORKER'

type WebAssemblyImports = Array<{
  name: string
  kind: string
}>

type WebAssemblyExports = Array<{
  module: string
  name: string
  kind: string
}>

interface EmscriptenModule {
  print(str: string): void
  printErr(str: string): void
  arguments: string[]
  environment: EnvironmentType
  preInit: Array<{ (): void }>
  preRun: Array<{ (): void }>
  postRun: Array<{ (): void }>
  onAbort: { (what: any): void }
  onRuntimeInitialized: { (): void }
  preinitializedWebGLContext: WebGLRenderingContext
  noInitialRun: boolean
  noExitRuntime: boolean
  logReadFiles: boolean
  filePackagePrefixURL: string
  wasmBinary: ArrayBuffer

  destroy(object: object): void
  getPreloadedPackage(
    remotePackageName: string,
    remotePackageSize: number
  ): ArrayBuffer
  instantiateWasm(
    imports: WebAssemblyImports,
    successCallback: (module: WebAssembly.Module) => void
  ): WebAssemblyExports
  locateFile(url: string, scriptDirectory: string): string
  onCustomMessage(event: MessageEvent): void

  // USE_TYPED_ARRAYS == 1
  HEAP: Int32Array
  IHEAP: Int32Array
  FHEAP: Float64Array

  // USE_TYPED_ARRAYS == 2
  HEAP8: Int8Array
  HEAP16: Int16Array
  HEAP32: Int32Array
  HEAPU8: Uint8Array
  HEAPU16: Uint16Array
  HEAPU32: Uint32Array
  HEAPF32: Float32Array
  HEAPF64: Float64Array

  TOTAL_STACK: number
  TOTAL_MEMORY: number
  FAST_MEMORY: number

  addOnPreRun(cb: () => any): void
  addOnInit(cb: () => any): void
  addOnPreMain(cb: () => any): void
  addOnExit(cb: () => any): void
  addOnPostRun(cb: () => any): void

  preloadedImages: any
  preloadedAudios: any

  _malloc(size: number): number
  _free(ptr: number): void

  FS: any // rip types

  intArrayFromString(str: string): number[]
  _malloc(len: number): number
  writeArrayToMemory(arr: number[], ptr: number): void
}

export type FrameX = EmscriptenModule & {
  _init: (
    fmt: number,
    outputFilename: number,
    inputPixFmt: number,
    outputPixFmt: number,
    threads: number,
    fps: number,
    width: number,
    height: number,
    sampleRate: number,
    channels: number,
    bitRate: number
  ) => void
  _add_video_frame: () => void
  _add_audio_frame: (ptr: number, len: number) => void
  _get_audio_buf_size: () => number
  _get_input_buffer_ptr: () => number
  _write_trailer: () => void
  _flush_video: () => void
  _flush_audio: () => void
  _set_opt: (key: number, val: number, len: number, cap: number) => void
}

export type Opts = FilenameOpt | DimensionsOpt | FramerateOpt

export type FilenameOpt = {
  key: 'filename'
  name: string
}

export type DimensionsOpt = {
  key: 'dimensions'
  width: number
  height: number
}

export type FramerateOpt = {
  key: 'framerate'
  fps: number
}
