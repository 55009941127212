import { MediaPosition } from '@editor/interfaces'

import { settingStore } from '../../index'

export const getDefaultMediaPosition = ({
  height,
  width
}: {
  height: number
  width: number
}): MediaPosition => {
  const { width: renderWidth, height: renderHeight } = settingStore.dimensions

  const aspectRatio = width / height
  const renderRatio = renderWidth / renderHeight

  if (Math.abs(aspectRatio - renderRatio) < 0.00001) {
    width = renderWidth
    height = renderHeight
  } else {
    // Max height should be render height
    // Max width should be render width

    const tempWidth = renderHeight * aspectRatio
    if (tempWidth <= renderWidth) {
      width = tempWidth
      height = renderHeight
    } else {
      const tempHeight = renderWidth / aspectRatio
      if (tempHeight <= renderHeight) {
        width = renderWidth
        height = tempHeight
      } else {
        // Find a height/width pair that works

        let maxWidth = renderWidth - 60
        let maxHeight = maxWidth * aspectRatio
        while (maxWidth > renderWidth || maxHeight > renderHeight) {
          maxWidth = maxWidth - 60
          maxHeight = maxWidth * aspectRatio
        }
        // Setting the maximum width/height which satisfies the following
        // width <= renderWidth && height <= renderHeight
        width = maxWidth
        height = maxHeight
      }
    }
  }

  const x = (renderWidth - width) / 2
  const y = (renderHeight - height) / 2

  return {
    width,
    height,
    scale: 1,
    x,
    y
  }
}
