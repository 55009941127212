import { action, computed, makeAutoObservable, observable, toJS } from 'mobx'
import posthog from 'posthog-js'

import {
  AnnotateMode,
  MediaType,
  TlDrawElementInterface
} from '@editor/interfaces'
import { findEmptyContainerAtTimestamp } from '@editor/state/media/utils'
import { deepEqual } from '@editor/utils'
import { TDDocument, TDShape, TDShapeType, TldrawApp } from '@modfy/tldraw'

import { TlDrawElement } from '../media'
import { store } from '..'

import annotateModeStore from './annotateModeStore'
import { mediaStore, settingStore } from '.'

export const NO_ZOOM_IS_ALLOWED = 'No zoom is allowed'

class TlDrawStore {
  @observable app: TldrawApp | null = null

  @observable previewDimensions = {
    height: 1,
    width: 1
  }

  constructor() {
    makeAutoObservable(this)

    // @ts-ignore
    if (typeof window !== 'undefined') window.tldrawStore = this
  }

  @action setApp = (app: TldrawApp | null) => {
    this.app = app

    this.app?.setSetting('isDarkMode', true)
    this.app?.setSetting('isSnapping', true)
  }

  @action setPreviewSizeFromEmbed = (height: number, width: number) => {
    const scale = width / 1920

    this.previewDimensions = { height, width }
    this.forceResize()
    this.app?.setCamera([0, 0], scale, 'Set preview size')
  }

  @action setPreviewSizeFromVideo = (video: HTMLVideoElement) => {
    let scale: number
    let height: number
    let width: number

    if (video.videoWidth > video.videoHeight) {
      // for the preview scale we just take a fixed width and calculate the scale for it relative to that
      scale = video.clientWidth / 1920

      // width is greater than height so the video element's width will be the current video width
      width = video.clientWidth
      // we calculate the current height of the video inside the video element
      // cannot use video.clientHeight as it is always constant even if video is smaller
      height = video.videoHeight * (video.clientWidth / video.videoWidth)
    } else {
      // for the preview scale we just take a fixed height and calculate the scale for it relative to that
      scale = video.clientHeight / 1080

      // height is greater than width so video element's height would be the current video height
      height = video.clientHeight
      // we calculate the current width of the video inside the video element
      // cannot use video.clientWidth as it is always constant even if video is smaller
      width = video.videoWidth * (video.clientHeight / video.videoHeight)
    }

    this.previewDimensions = { height, width }
    this.forceResize()
    this.app?.setCamera([0, 0], scale, 'Set preview size')
  }

  /**
   * This will get the shapes that have changed in tldraw
   * and create/update/delete them in media store accordingly
   */
  @action handleShapesUpdate = (
    shapes: Record<string, TDShape | undefined>,
    currentMediaObj: Record<string, MediaType>
  ) => {
    for (const shapeId of Object.keys(shapes)) {
      // shape converted to js obj instead of mobx proxy
      const jsShape = toJS(shapes[shapeId])

      // get media for this shapeId
      const media = jsShape
        ? mediaStore.mediaObject<TlDrawElementInterface, true>(jsShape.id, true)
        : undefined

      // if the shape was deleted
      if (!jsShape) {
        // Only delete the shape if it is visible or else the user might have deleted it
        if (
          shapeId in currentMediaObj &&
          currentMediaObj[shapeId] === MediaType.tlDrawElement
        ) {
          this.posthogCapture('delete_shape', { shapeId })
          console.log('deleting shape', shapeId)
          mediaStore.removeMedia(shapeId)
        }
      }
      // if exists update the shape on it
      else if (media) {
        if (media.updateShape) {
          // only update if necessary, otherwise will go in a loop because of update event firing
          const equals = deepEqual(jsShape, media.tlDrawShape)
          if (!equals) media.updateShape(jsShape)
        } else {
          console.error('Unable to update shape', jsShape.id)
        }
      }
      // if media doesn't exist create one under the playhead
      else if (!media) {
        const media = new TlDrawElement({
          _id: jsShape.id,
          name: jsShape.type,
          tlDrawShape: jsShape,
          duration: Infinity
        })

        const {
          media: { mediaElements },
          playback: { playhead }
        } = store.getState()

        const playheadInSeconds = playhead / settingStore.fps

        const container = findEmptyContainerAtTimestamp(
          mediaStore.tracks,
          mediaElements,
          playheadInSeconds
        )

        mediaStore.addStaticMedia(media, {
          container,
          startOnTimeline: playheadInSeconds
        })

        this.posthogCapture('create_shape', {
          id: jsShape.id,
          shape: jsShape.type
        })
      }
    }
  }

  /**
   * Runs whenever the document updates and updates our state accordingly
   */
  @action updateDocument = async (
    document: TDDocument,
    reason?: string | undefined
  ) => {
    // Prevent selecting of tools if not in draw mode
    if (
      reason?.startsWith('selected_tool:') &&
      annotateModeStore.toolMode !== AnnotateMode.draw
    ) {
      return this.selectSelectTool()
    }

    // Not in drawing mode so dont do anything
    if (annotateModeStore.toolMode !== AnnotateMode.draw) {
      return
    }

    switch (reason) {
      case NO_ZOOM_IS_ALLOWED:
        break
      case 'set_hovered_id':
        break
      case 'panned':
        this.app?.setCamera([0, 0], 1, NO_ZOOM_IS_ALLOWED)
        break
    }
  }

  /**
   * Returns shapes from the default page of document, if no document passed returns shapes from current one in state
   */
  @computed getShapes = (document?: TDDocument) => {
    if (document) return document.pages.page?.shapes || {}
    return this.app?.document.pages.page?.shapes || {}
  }

  selectSelectTool = () => this.app?.selectTool('select')
  selectPenTool = () => this.app?.selectTool(TDShapeType.Draw)

  /**
   * Force a recalculation of tldraw preview's viewport by emitting a window resize event
   */
  forceResize = () => {
    // Emit after a delay because of the animations
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
  }

  /**
   * Helper to add project id and version to event
   */
  posthogCapture = (name: string, properties: Record<string, any> = {}) => {
    posthog.capture(name, {
      ...properties,
      projectId: annotateModeStore.project?.id,
      projectVersion: annotateModeStore.project?.currentVersion
    })
  }
}

export default new TlDrawStore()
