import { useEffect, useState } from 'react'

import { MediaElements, MediaTrack } from '@editor/interfaces'
import { getRealEnd } from '@editor/utils/realStartEnd'

import { getTrackList } from '../getTrackList'

const calculateTrackDuration = (
  track: string[],
  mediaElements: MediaElements
) => {
  const trackMediaElements = track.map((id) => mediaElements[id])

  return trackMediaElements.reduce<number>((trackDuration, mediaElem) => {
    if (!mediaElem) return trackDuration
    // Get end of this media on timeline
    const mediaEnd = getRealEnd(mediaElem)
    // If this media's end is more than track duration, set this media's end as track duration
    return Math.max(mediaEnd, trackDuration)
  }, 0)
}

export function useDuration(
  tracks: MediaTrack,
  mediaElements: MediaElements,
  // eslint-disable-next-line no-unused-vars
  callback?: (value: number) => void
) {
  const [duration, setDuration] = useState(0)

  const trackList = getTrackList(tracks)

  useEffect(() => {
    if (!mediaElements) return

    let _duration = duration

    const primeDuration = calculateTrackDuration(tracks.prime, mediaElements)
    _duration = Math.max(primeDuration, _duration)

    for (const track of tracks.above) {
      const trackDuration = calculateTrackDuration(track, mediaElements)
      _duration = Math.max(trackDuration, _duration)
    }

    for (const track of tracks.below) {
      const trackDuration = calculateTrackDuration(track, mediaElements)
      _duration = Math.max(trackDuration, _duration)
    }

    if (callback) callback(_duration)

    setDuration(_duration)

    /***
     * Do not add callback to this, it will infinite loop and cause more hooks on next render and throw an error
     *
     * Time wasted : 15 mins
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracks, mediaElements, trackList])

  return duration
}
