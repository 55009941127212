import { ADT } from 'ts-adt'

import {
  Container,
  Frames,
  MediaElementInterface,
  MediaInterface,
  MediaPlaybackState,
  MediaPosition,
  ThirdPartyEmbedType
} from '.'

export type MediaPlaybackStateCallbackType = (
  _mediaPlaybackState: MediaPlaybackState
) => void

export type SourceChangeCallbackType = (_source: string) => void

export type VideoContextCallback = ADT<{
  mediaPlaybackState: { fn: MediaPlaybackStateCallbackType }
  sourceChange: { fn: SourceChangeCallbackType }
}>

export const MediaPlaybackStateCallback = (
  fn: MediaPlaybackStateCallbackType
): VideoContextCallback => ({ _type: 'mediaPlaybackState', fn })

export const SourceChangeCallback = (
  fn: SourceChangeCallbackType
): VideoContextCallback => ({ _type: 'sourceChange', fn })

export type FileInput = ADT<{
  regular: { file: File }
  // For loom/hevc videos we generate the ID before hand so we can
  // send it to the lambda func so it can upload to S3
  hevc: { file: File; id: string }
  loom: { file: File; id: string }
  gdrive: { file: File; id: string }
  reddit: { file: File; id: string }
  // These are yt, soundcloud etc videos which will be shown in an embed
  thirdPartEmbed: { url: string; embedType: ThirdPartyEmbedType }
  // from custom links
  customFile: { file: File; id: string }
}>

/**
 * ADT for our custom clipboard, which can have many things
 *
 * Currently will support copying files in file viewer and copying elements
 */
export type ModfyClipboard = ADT<{
  files: { items: MediaInterface[] }
  elements: { items: MediaElementInterface[] }
}>

export const MediaElementsClipboard = (
  mediaElements: MediaElementInterface[]
): ModfyClipboard => ({ _type: 'elements', items: mediaElements })

/**
 * ADT for paste location, which can be either a container or a position
 *
 * A container would be pasting into a track
 * A position would be pasting into preview
 *
 * This is for later
 */
export type PasteLocation = ADT<{
  track: { container?: Container; time: Frames }
  point: { position: MediaPosition }
  folder: { path: string }
}>
