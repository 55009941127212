// Hair space character for precise justification
const SPACE = '\u200a'

interface CanvasTxtOptions {
  debug: boolean
  align: 'left' | 'center' | 'right'
  vAlign: 'top' | 'middle' | 'bottom'
  fontSize: number
  fontWeight: string | number
  fontStyle: string
  fontVariant: string | number
  font: string
  lineHeight: null | number
  justify: boolean
}

/**
 * Credits:
 * https://github.com/geongeorge/Canvas-Txt
 */
export class CanvasTxt implements CanvasTxtOptions {
  debug = false
  align = 'center' as CanvasTxtOptions['align']
  vAlign = 'middle' as CanvasTxtOptions['vAlign']
  fontSize = 14
  fontWeight = ''
  fontStyle = ''
  fontVariant = ''
  font = 'Arial'
  lineHeight = null
  justify = false

  constructor(options: Partial<CanvasTxtOptions>) {
    this.setOptions(options)
  }

  setOptions = (options: Partial<CanvasTxtOptions>) => {
    for (const key of Object.keys(options)) {
      this[key] = options[key]
    }
  }

  /**
   * Draw text on canvas
   */
  drawText = (
    ctx: CanvasRenderingContext2D,
    text: string,
    position: {
      x: number
      y: number
      width: number
      height: number
    }
  ) => {
    const { x, y, width, height } = position
    if (width <= 0 || height <= 0 || this.fontSize <= 0) {
      // width or height or font size cannot be 0
      throw new Error('Width, height, fontSize cannot be less than 0')
    }

    // End points
    const xEnd = x + width
    const yEnd = y + height

    const { fontStyle, fontVariant, fontWeight, fontSize, font } = this
    const style = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}px ${font}`
    const finalTextStyle = style.replaceAll('  ', ' ').trim()
    ctx.font = finalTextStyle

    let txtY = y

    let textAnchor: number

    if (this.align === 'right') {
      textAnchor = xEnd
      ctx.textAlign = 'right'
    } else if (this.align === 'left') {
      textAnchor = x
      ctx.textAlign = 'left'
    } else {
      textAnchor = x + width / 2
      ctx.textAlign = 'center'
    }

    // added one-line only auto line break feature
    const textArr: string[] = []
    const tempTextArr = text.split('\n')

    const spaceWidth = this.justify ? ctx.measureText(SPACE).width : 0

    tempTextArr.forEach((txt) => {
      let textWidth = ctx.measureText(txt).width
      if (textWidth <= width) {
        textArr.push(txt)
      } else {
        let tempText = txt
        const lineLen = width
        let textLen: number
        let textPixLen: number
        let textToPrint: string
        textWidth = ctx.measureText(tempText).width
        while (textWidth > lineLen) {
          textLen = 0
          textPixLen = 0
          textToPrint = ''
          while (textPixLen < lineLen) {
            textLen++
            textToPrint = tempText.substr(0, textLen)
            textPixLen = ctx.measureText(tempText.substr(0, textLen)).width
          }
          // Remove last character that was out of the box
          textLen--
          textToPrint = textToPrint.substr(0, textLen)
          // if statement ensures a new line only happens at a space, and not amidst a word
          const backup = textLen
          if (tempText.substr(textLen, 1) !== ' ') {
            while (tempText.substr(textLen, 1) !== ' ' && textLen !== 0) {
              textLen--
            }
            if (textLen === 0) {
              textLen = backup
            }
            textToPrint = tempText.substr(0, textLen)
          }

          textToPrint = this.justify
            ? this.justifyLine(ctx, textToPrint, spaceWidth, SPACE, width)
            : textToPrint

          tempText = tempText.substr(textLen)
          textWidth = ctx.measureText(tempText).width
          textArr.push(textToPrint)
        }
        if (textWidth > 0) {
          textArr.push(tempText)
        }
      }
      // end foreach tempTextArr
    })
    const charHeight = this.lineHeight
      ? this.lineHeight * this.getTextHeight(ctx, text, style)
      : 0 // close approximation of height with width
    const vHeight = charHeight * (textArr.length - 1)
    const negOffset = vHeight / 2

    let debugY = y
    // Vertical Align
    if (this.vAlign === 'top') {
      txtY = y
    } else if (this.vAlign === 'bottom') {
      txtY = yEnd - vHeight
      debugY = yEnd
    } else {
      // defaults to center
      debugY = y + height / 2
      txtY -= negOffset
    }

    // print all lines of text
    textArr.forEach((txtLine) => {
      txtLine = txtLine.trim()
      ctx.fillText(txtLine, textAnchor, txtY)
      txtY += charHeight
    })

    if (this.debug) {
      // Text box
      ctx.lineWidth = 3
      ctx.strokeStyle = '#00909e'
      ctx.strokeRect(x, y, width, height)

      ctx.lineWidth = 2
      // Horizontal Center
      ctx.strokeStyle = '#f6d743'
      ctx.beginPath()
      ctx.moveTo(textAnchor, y)
      ctx.lineTo(textAnchor, yEnd)
      ctx.stroke()
      // Vertical Center
      ctx.strokeStyle = '#ff6363'
      ctx.beginPath()
      ctx.moveTo(x, debugY)
      ctx.lineTo(xEnd, debugY)
      ctx.stroke()
    }

    const TEXT_HEIGHT = vHeight + charHeight

    return { height: TEXT_HEIGHT }
  }

  // Calculate Height of the font
  getTextHeight = (
    ctx: CanvasRenderingContext2D,
    text: string,
    style: string
  ) => {
    const previousTextBaseline = ctx.textBaseline
    const previousFont = ctx.font

    ctx.textBaseline = 'bottom'
    ctx.font = style
    const { actualBoundingBoxAscent: height } = ctx.measureText(text)

    // Reset baseline
    ctx.textBaseline = previousTextBaseline
    ctx.font = previousFont

    return height
  }

  /**
   * This function will insert spaces between words in a line in order
   * to raise the line width to the box width.
   * The spaces are evenly spread in the line, and extra spaces (if any) are inserted
   * between the first words.
   *
   * It returns the justified text.
   */
  justifyLine = (
    ctx: CanvasRenderingContext2D,
    line: string,
    spaceWidth: number,
    spaceChar: string,
    width: number
  ) => {
    const text = line.trim()

    const lineWidth = ctx.measureText(text).width

    const nbSpaces = text.split(/\s+/).length - 1
    const nbSpacesToInsert = Math.floor((width - lineWidth) / spaceWidth)

    if (nbSpaces <= 0 || nbSpacesToInsert <= 0) return text

    // We insert at least nbSpacesMinimum and we add extraSpaces to the first words
    const nbSpacesMinimum = Math.floor(nbSpacesToInsert / nbSpaces)
    let extraSpaces = nbSpacesToInsert - nbSpaces * nbSpacesMinimum

    const spaces: string[] = []
    for (let i = 0; i < nbSpacesMinimum; i++) {
      spaces.push(spaceChar)
    }
    const finalSpaces = spaces.join('')

    const justifiedText = text.replace(/\s+/g, (match) => {
      const allSpaces = extraSpaces > 0 ? finalSpaces + spaceChar : finalSpaces
      extraSpaces--
      return match + allSpaces
    })

    return justifiedText
  }
}

export default CanvasTxt
