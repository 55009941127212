import {
  FileNode,
  FolderNode,
  MediaElementInterface,
  MediaInterface,
  MediaTrack,
  MultiplayerActionCut,
  MultiplayerActions,
  MultiplayerActionType,
  PanelMap,
  PreviewSelectedMedia
} from '@editor/interfaces'
import { multiplayerStore } from '@editor/state/index'

const handleMultiplayerAction = (action: MultiplayerActionType) => {
  const { multiplayerInstance } = multiplayerStore

  const { payload, type } = action

  if (!multiplayerInstance) {
    throw new Error('Multiplayer instance is undefined')
  }

  // If someone mistakenly passes in an array to `multiplayerAction`
  // and doesn't set second parameter to 'multiplayer'
  if (!payload && !type) {
    throw new Error(`Invalid action: ${JSON.stringify(action)}`)
  }

  switch (type) {
    case MultiplayerActions.SetMediaElement: {
      const mediaElement = payload as MediaElementInterface
      multiplayerInstance.setMediaElement(mediaElement)
      break
    }
    case MultiplayerActions.DeleteMediaElement: {
      const mediaElemId = payload as string
      multiplayerInstance.removeMediaElement(mediaElemId)
      break
    }
    case MultiplayerActions.SetFile: {
      const file = payload as FileNode
      multiplayerInstance.setFile(file)
      break
    }
    case MultiplayerActions.DeleteFile: {
      const fileId = payload as string
      multiplayerInstance.removeFile(fileId)
      break
    }
    case MultiplayerActions.SetFolder: {
      const folder = payload as FolderNode
      multiplayerInstance.setFolder(folder)
      break
    }
    case MultiplayerActions.DeleteFolder: {
      const folderId = payload as string
      multiplayerInstance.removeFolder(folderId)
      break
    }
    case MultiplayerActions.updateFolders: {
      const panelMap = payload as PanelMap
      multiplayerInstance.updateFolders(panelMap)
      break
    }
    case MultiplayerActions.SetMedia: {
      const media = payload as MediaInterface
      multiplayerInstance.setMedia(media)
      break
    }
    case MultiplayerActions.DeleteMedia: {
      const mediaId = payload as string
      multiplayerInstance.removeMedia(mediaId)
      break
    }
    case MultiplayerActions.UpdateTracks: {
      const tracks = payload as MediaTrack
      multiplayerInstance.updateTrack('prime', tracks.prime)
      multiplayerInstance.updateTrack('above', tracks.above)
      multiplayerInstance.updateTrack('below', tracks.below)
      break
    }
    case MultiplayerActions.UpdateTrackPrime: {
      const track = payload as MediaTrack['prime']
      multiplayerInstance.updateTrack('prime', track)
      break
    }
    case MultiplayerActions.UpdateTrackAbove: {
      const track = payload as MediaTrack['above']
      multiplayerInstance.updateTrack('above', track)
      break
    }
    case MultiplayerActions.UpdateTrackBelow: {
      const track = payload as MediaTrack['below']
      multiplayerInstance.updateTrack('below', track)
      break
    }
    case MultiplayerActions.SelectMedia: {
      const selectedMedia = payload as PreviewSelectedMedia
      multiplayerInstance.setSelectedMedia(selectedMedia)
      break
    }
    case MultiplayerActions.AddMedia: {
      const media = payload as MediaInterface
      multiplayerInstance.setMedia(media)
      break
    }
    case MultiplayerActions.Cut: {
      const { medias, tracks } = payload as MultiplayerActionCut['payload']
      multiplayerInstance.executeCut(medias, tracks)
      break
    }
    default: {
      break
    }
  }
}

/**
 * Helper function for updating the multiplayer state
 */
const executeMultiplayerActions = (actions: MultiplayerActionType[]) => {
  const { multiplayerInstance } = multiplayerStore

  const executeActions = () => {
    const multipleActions = actions as MultiplayerActionType[]

    for (const action of multipleActions) {
      handleMultiplayerAction(action)
    }
  }
  multiplayerInstance?.transact(executeActions)
}

export default executeMultiplayerActions
