import { settingStore, timelineStore } from '@editor/state/mobx'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '../hook'

const timelineSlice = createSlice({
  name: 'timelineSlice',
  initialState: {
    timelineWidth: 0,
    // This is the scale that converts seconds to pixels
    // If you have 951 seconds -> 57060 px
    // that is 1 seconds * timelineScale = px
    timelineScale: 60,
    totalFrames: 0,
    // In seconds
    totalDuration: 0,
    displayDuration: 0
  },
  reducers: {
    genericSetter: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = action.payload

      Object.assign(state, { [key]: value })
    },
    setDuration: (state, action: PayloadAction<number>) => {
      const duration = action.payload
      if (state.totalDuration === 0 && duration > 0) {
        // Default case
        let scale = state.timelineScale
        const minWidth =
          timelineStore.globalTimelineRef.current?.offsetWidth || 0
        while (scale * duration < minWidth && scale < 200) {
          scale += 10
        }
        state.timelineScale = scale
      }

      state.totalDuration = duration
      state.totalFrames = duration * settingStore.fps
    },
    setScale: (
      state,
      action: PayloadAction<{
        scale: number
        minWidth: number
        increment?: boolean
      }>
    ) => {
      const { scale, minWidth, increment } = action.payload

      const { totalDuration, timelineScale } = state

      let appliedScale = increment ? timelineScale + scale : scale

      appliedScale = appliedScale < 1 ? 1 : appliedScale

      /**
       * This is the grace given, so the scale can be 1.2 times the total duration, not more
       */
      if (totalDuration * 1.2 * appliedScale > minWidth) {
        state.timelineScale = appliedScale
      }
    },
    setTimelineWidth: (state, action: PayloadAction<number>) => {
      state.timelineWidth = Math.floor(action.payload)
    },
    setDisplayDuration: (state, action: PayloadAction<number>) => {
      state.displayDuration = Math.ceil(action.payload)
    }
  }
})

export const {
  genericSetter,
  setDuration,
  setScale,
  setTimelineWidth,
  setDisplayDuration
} = timelineSlice.actions

export default timelineSlice.reducer

export const useTimeline = () => {
  const timelineSlice = useAppSelector((state) => state.timeline)

  const scaledWidth =
    timelineSlice.displayDuration * timelineSlice.timelineScale

  return { ...timelineSlice, scaledWidth }
}
