import { Container } from '@editor/interfaces'

import { mediaStore } from '../../index'

/**
 * A utility function to find the container of an element from its id
 *
 * The function will traverse all the tracks and return where the id is located.
 *
 * If overMode is enabled it will only check for the tracks itself, not within them
 */
export const getContainer = (
  id: string,
  overMode = false
): Container | null => {
  if (id === 'prime') {
    return { trackType: 'prime', index: 0 }
  } else if (id.includes('above')) {
    // Eg above-2 -> 2
    const indexStr = id.split('-').pop()
    if (indexStr) {
      const index = parseInt(indexStr)

      return { trackType: 'above', index }
    }
  } else if (id.includes('below')) {
    // Eg above-2 -> 2
    const indexStr = id.split('-').pop()
    if (indexStr) {
      const index = parseInt(indexStr)

      return { trackType: 'below', index }
    }
  }

  const tracks = mediaStore.tracks

  if (!overMode) {
    if (tracks.prime.includes(id)) {
      return { trackType: 'prime', index: 0 }
    } else {
      for (const [index, row] of tracks.above.entries()) {
        if (row.includes(id)) {
          return { trackType: 'above', index }
        }
      }

      for (const [index, row] of tracks.below.entries()) {
        if (row.includes(id)) {
          return { trackType: 'below', index }
        }
      }
    }
  }

  return null
}
