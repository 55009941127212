import { useCallback, useEffect, useState } from 'react'

import useResizeObserver, {
  UseResizeObserverCallback
} from '@react-hook/resize-observer'

import { debounce } from '../debounce'

/**
 * Used to get the width of an element
 * @returns [ref, width] Ref to pass to the element, element's width
 */
export const useElementWidth = (propNode?: HTMLDivElement | null) => {
  const [width, setWidth] = useState(0)

  const [node, setNode] = useState<HTMLDivElement | null>(propNode || null)

  useEffect(() => {
    if (propNode) {
      setNode(propNode)
    }
  }, [propNode])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback<UseResizeObserverCallback>(
    debounce((entry) => {
      if (entry) {
        const newWidth = entry.contentRect.width

        // Only set the width if width is 0 (is unset)
        // and if node actually has a width
        if (newWidth !== 0) {
          setWidth(newWidth)
        }
      }
    }, 300),
    []
  )

  useResizeObserver(node, callback)

  const ref = <T extends HTMLDivElement | null>(node: T) => {
    setNode(node)
  }

  return [ref, width] as const
}

/**
 * Used to get the height of an element
 * @returns [ref, height] Ref to pass to the element, element's height
 */
export const useElementHeight = () => {
  const [height, setHeight] = useState(0)

  const ref = <T extends HTMLDivElement | null>(node: T) => {
    if (node && node.offsetHeight !== 0) {
      setHeight(node.offsetHeight)
    }
  }

  return [ref, height] as const
}
