import { autorun } from 'mobx'
import { useEffect } from 'react'

import {
  modeStore,
  multiplayerStore,
  usePlaybackSlice
} from '@editor/state/index'
import { debounceLeading } from '@editor/utils'

/**
 * This hook will listen for current user state update
 * and update the user's multiplayer presence
 */
const useCurrentUserSync = () => {
  const { isPlaying, playhead } = usePlaybackSlice()

  /**
   * Sync current user's cursor and timelineMode
   */
  useEffect(() => {
    const dispose = autorun(() => {
      const timelineMode = modeStore.timelineMode
      multiplayerStore.multiplayerInstance?.setUserState({ timelineMode })
    })

    // Debounce this with a very small timeout to prevent
    // overwhelming yjs with updates
    const updateUserCursor = debounceLeading((e: MouseEvent) => {
      const { clientX: cursorX, clientY: cursorY } = e
      multiplayerStore.multiplayerInstance?.setUserState({
        cursorX,
        cursorY
      })
    }, 0.05)

    document.addEventListener('mousemove', updateUserCursor)
    return () => {
      document.removeEventListener('mousemove', updateUserCursor)
      dispose()
    }
  }, [])

  /**
   * Sync current user's isPlaying
   */
  useEffect(() => {
    multiplayerStore.multiplayerInstance?.setUserState({
      isPlaying
    })
  }, [isPlaying])

  /**
   * Sync current user's playhead
   */
  useEffect(() => {
    multiplayerStore.multiplayerInstance?.setUserState({
      playhead
    })
  }, [playhead])
}

export default useCurrentUserSync
