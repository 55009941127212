import { MediaElementInterface } from '@editor/interfaces'

/**
 * This file is kept around to not break the old code but with the change to duration
 * this is less important at least for realStart
 */

/**
 * Returns the realEnd point on the timeline which is computed
 * by adding the global start +  local start + duration
 */
export const getRealEnd = (mediaElement: MediaElementInterface | undefined) => {
  return (mediaElement?.duration || 0) + (mediaElement?.startOnTimeline || 0)
}

/**
 * Returns the startSecond of the mediaElement
 */
export const getRealStart = (
  mediaElement: MediaElementInterface | undefined
) => {
  return mediaElement?.startOnTimeline || 0
}

export const getRealStartAndEnd = (mediaElement: MediaElementInterface) => {
  return {
    realStart: getRealStart(mediaElement),
    realEnd: getRealEnd(mediaElement)
  }
}
