import { action, computed, makeAutoObservable, observable } from 'mobx'

import { VideoSettings } from '@editor/interfaces'

class ProjectSettingStore {
  settings: VideoSettings = {
    fps: 30,
    format: {
      format: 'MP4',
      codec: 'H264'
    },
    outputFileName: 'output.mp4',
    dimensions: {
      width: 1920,
      height: 1080
    },
    sampleRate: 48000
  }

  projectId?: string

  constructor() {
    makeAutoObservable(this, {
      settings: observable,
      projectId: observable,
      fps: computed,
      dimensions: computed
    })
  }

  get fps(): number {
    return this.settings.fps
  }

  get dimensions() {
    return this.settings.dimensions
  }

  @action setSettings(settings: VideoSettings) {
    this.settings = settings
  }

  @action updateName(name: string) {
    this.settings.outputFileName = name
  }

  @action setProjectId(id: string) {
    this.projectId = id
  }
}

export default new ProjectSettingStore()
