import { makeAutoObservable, observable } from 'mobx'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'

class ZoomStore {
  // This zoom value is in percentage
  @observable zoomValue: number = 55

  @observable private _handlers?: ReactZoomPanPinchRef | undefined = undefined

  constructor() {
    makeAutoObservable(this)

    // @ts-ignore
    if (typeof window !== 'undefined') window.zoomStore = this
  }

  setHandlers = (handlers: ReactZoomPanPinchRef | undefined) => {
    this._handlers = handlers
  }

  /**
   * Zooms using the centerView command
   */
  setZoomValue = (value: number) => {
    this._handlers?.centerView(value / 100, 500, 'easeInOutCubic')
  }

  /**
   * Updates local zoom value without actually zooming
   * Also sets the zoom on tldraw preview
   */
  updateZoomValue = (value: number) => {
    this.zoomValue = value
  }

  zoomIn = () => {
    this._handlers?.zoomIn()
  }

  zoomOut = () => {
    this._handlers?.zoomOut()
  }

  zoomToFit = () => {
    this._handlers?.zoomToElement('zoomPlayer')
  }
}

export default new ZoomStore()
