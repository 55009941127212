import ismobilejs from 'ismobilejs'
import { useEffect, useState } from 'react'

export const useIsMobile = () => {
  const [result, setResult] = useState<{
    isMobile: boolean
    isTablet: boolean
  }>({ isMobile: false, isTablet: false })

  useEffect(() => {
    const data = ismobilejs(navigator.userAgent)
    setResult({ isMobile: data.phone, isTablet: data.tablet })
  }, [])

  return result
}
