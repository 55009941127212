import chroma from 'chroma-js'

import { computeControlPoints } from './bezier-spline'
import { WaveryOption, WaveryPoint } from './types'

const svgNS = 'http://www.w3.org/2000/svg'

const defaultOptions: WaveryOption = {
  width: 800,
  height: 600,
  segmentCount: 20,
  layerCount: 2,
  variance: 0.75,
  strokeWidth: 0,
  strokeColor: 'none',
  gradientColors: [
    {
      colorValue: 'yellow',
      position: 0
    },
    {
      colorValue: 'red',
      position: 0.5
    },
    {
      colorValue: 'navy',
      position: 1
    }
  ]
}

/**
 * Generates random points for each layer
 *
 * Note for later (@patheticGeek):
 * To use a seed function so we can generate the same points again in case of just a color change
 * we can use `seedrandom` library and pass in a seed (a random string) and use
 * the function from there instead of Math.random()
 */
function generatePoints(
  width: number,
  height: number,
  segmentCount: number,
  layerCount: number,
  variance: number
): WaveryPoint[][] {
  // Dimensions of a single cell (one segment in one layer)
  const cellWidth = width / segmentCount
  const cellHeight = height / layerCount

  // Limits the points need to be in depending of variance
  const moveLimitX = cellWidth * variance * 0.5
  const moveLimitY = cellHeight * variance

  const layers: WaveryPoint[][] = []

  // Generate points for each layer and add to layers
  for (let y = cellHeight; y < height; y += cellHeight) {
    const points: WaveryPoint[] = []

    // Start the point from 0
    points.push({ x: 0, y: Math.floor(y) })

    // Generate random points between start and end and within limits
    for (let x = cellWidth; x < width; x += cellWidth) {
      const varietalY = y - moveLimitY / 2 + Math.random() * moveLimitY
      const varietalX = x - moveLimitX / 2 + Math.random() * moveLimitX
      points.push({
        x: Math.floor(varietalX),
        y: Math.floor(varietalY)
      })
    }

    // End the line at width
    points.push({ x: width, y: Math.floor(y) })

    // push the points for this layer
    layers.push(points)
  }

  return layers
}

/**
 * Generates a svg <path> element with given points and makes a smooth
 * curvy connection between those points using bezier curves
 * then closes the path
 *
 * The path passes the points as follows:
 * leftCornerPoint -> ...curvePoints... -> rightCornerPoint -> back to start (leftCornerPoint)
 *
 * To read more about the commands used to create the path
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d
 */
function generateClosedPath(
  curvePoints: WaveryPoint[],
  leftCornerPoint: WaveryPoint,
  rightCornerPoint: WaveryPoint,
  filleColor: string,
  strokeColor: string,
  strokeWidth: number
): SVGElement {
  const xPoints = curvePoints.map((p: WaveryPoint): number => p.x)
  const yPoints = curvePoints.map((p: WaveryPoint): number => p.y)

  // compute the control points for making a smooth besizer curve between the points
  const xControlPoints = computeControlPoints(xPoints)
  const yControlPoints = computeControlPoints(yPoints)

  // this is the d attribute of path element, containing the commands for drawing the line
  // start the path from the leftCornerPoint and connect the first point
  let path =
    `M ${leftCornerPoint.x},${leftCornerPoint.y} ` +
    `C ${leftCornerPoint.x},${leftCornerPoint.y} ` +
    `${xPoints[0]},${yPoints[0]} ` +
    `${xPoints[0]},${yPoints[0]} `

  // go over the rest of curvePoints and add them with the control points for the bezier curve
  for (let i = 0; i < xPoints.length - 1; i++) {
    path +=
      `C ${xControlPoints.p1[i]},${yControlPoints.p1[i]} ` +
      `${xControlPoints.p2[i]},${yControlPoints.p2[i]} ` +
      `${xPoints[i + 1]},${yPoints[i + 1]} `
  }

  // finally close the path connecting the last point to rightCornerPoint, then going back to start (z command)
  path +=
    `C ${xPoints[xPoints.length - 1]},${yPoints[xPoints.length - 1]} ` +
    `${rightCornerPoint.x},${rightCornerPoint.y} ` +
    `${rightCornerPoint.x},${rightCornerPoint.y} Z`

  // create the <path> element and return
  const svgPath = document.createElementNS(svgNS, 'path')
  svgPath.setAttributeNS(null, 'fill', filleColor)
  svgPath.setAttributeNS(null, 'stroke', strokeColor)
  svgPath.setAttributeNS(null, 'stroke-width', strokeWidth.toString())
  svgPath.setAttributeNS(null, 'd', path)

  return svgPath
}

export default class Wavery {
  options: WaveryOption
  points: WaveryPoint[][]

  constructor(options: Partial<WaveryOption>) {
    this.options = { ...defaultOptions, ...options }
    this.points = generatePoints(
      this.options.width,
      this.options.height,
      this.options.segmentCount,
      this.options.layerCount,
      this.options.variance
    )
  }

  generateSvg(): SVGElement {
    // create the svg element
    const svg = document.createElementNS(svgNS, 'svg')
    svg.setAttribute('width', this.options.width.toString())
    svg.setAttribute('height', this.options.height.toString())
    svg.setAttribute('xmlns', svgNS)

    // generate a color scale that will be used to color the layers
    const colorScale = chroma
      .scale(this.options.gradientColors.map((c) => c.colorValue))
      .domain(
        this.options.gradientColors.map((c) => c.position * this.points.length)
      )

    // fill the background using <rect>
    const rect = document.createElementNS(svgNS, 'rect')
    rect.setAttributeNS(null, 'x', '0')
    rect.setAttributeNS(null, 'y', '0')
    rect.setAttributeNS(null, 'height', this.options.height.toString())
    rect.setAttributeNS(null, 'width', this.options.width.toString())
    rect.setAttributeNS(null, 'fill', colorScale(0).hex())
    rect.setAttributeNS(null, 'stroke', this.options.strokeColor)
    rect.setAttributeNS(
      null,
      'stroke-width',
      this.options.strokeWidth.toString()
    )
    svg.appendChild(rect)

    // generate the path element for each layer and append to svg
    for (let i = 0; i < this.points.length; i++) {
      const pathElem = generateClosedPath(
        // @ts-expect-error noUncheckedIndexedAccess
        this.points[i],
        { x: 0, y: this.options.height },
        { x: this.options.width, y: this.options.height },
        // get color for this layer from the color scale
        colorScale(i + 1).hex(),
        this.options.strokeColor,
        this.options.strokeWidth
      )
      svg.appendChild(pathElem)
    }

    return svg
  }
}
