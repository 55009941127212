/**
 * A convenience function useful for computing an inline JSX element that
 * needs a closure:
 *
 * Ex/
 * {
 *  isActive ?
 *      inlineClosure(
 *        findContainer(id),
 *        (container) => <Item container={container} background={container.background}/>
 *      )
 *      : null
 * }
 *
 * Assume `findContainer` is an expensive function, without `inlineClosure` we would
 * have to invoke the function multiple times or use an ugly inline anonymous function
 */
export const inlineClosure = <Dependency = unknown, Return = unknown>(
  dep: Dependency,
  func: (_dep: Dependency) => Return
): Return => func(dep)
