import { MediaType } from '.'

export type FileType = Extract<
  MediaType,
  MediaType.video | MediaType.audio | MediaType.image
>

/**
 * FileData is the data a FileNode carries
 */
export type FileData = {
  name: string
  id: string
  /**
   * The type of media this file belongs to
   */
  type: FileType
  /**
   * The mime type of the actual file uploaded
   */
  contentType: string
  size: number
  sha256: string
}

/**
 * FileTreeNode is the base type of node in the file system
 */
export type FileTreeNode = {
  label: string
  id: string
}

/**
 * FileNode is a FileTreeNode for files
 */
export type FileNode = {
  data: FileData
} & FileTreeNode

/**
 * FolderNode is a FileTreeNode for folders
 */
export type FolderNode = {
  /**
   * An array of its children's IDs
   */
  children: string[]
} & FileTreeNode

/**
 * The ID for the selections for the 2nd and 3rd file panels (first panel always has root dir).
 */
export type FilePanelSelections = Array<string | undefined>

/**
 * Convenience type for all panels
 */
export type FilePanels = [RootNode: string, ...Selections: FilePanelSelections]

/**
 * The ID of the selected element for each panel (the item that gets highlighted)
 */
export type FileSelections = Array<string | undefined>

export type FilePanelIndex = number

export type FoldersMap = Record<string, FolderNode>

export type FilesMap = Record<string, FileNode>

export type FileViewerMode = 'multi-panel' | 'thumbnail'

/**
 * Enum of possible viewer modes. Not using a Typescript enum because
 * we need to iterate over them
 */
export const FileViewerModeEnum: Array<FileViewerMode> = [
  'multi-panel',
  'thumbnail'
]

/**
 * The keys contain `container-${id of folder}` and the values are the
 * IDs of the the folders children
 */
export type PanelMap = Record<string, string[]>

export const isFileTreeNode = (node: any): node is FileTreeNode =>
  node !== undefined &&
  (node as FileTreeNode).id !== undefined &&
  (node as FileTreeNode).label !== undefined

export const isFileData = (file: any): file is FileData =>
  file !== undefined &&
  (file as FileData).type !== undefined &&
  (file as FileData).name !== undefined &&
  (file as FileData).size !== undefined &&
  (file as FileData).id !== undefined

export const isFileNode = (node: any): node is FileNode =>
  node !== undefined &&
  isFileTreeNode(node) &&
  isFileData((node as FileNode).data)

export const isFolderNode = (node: any): node is FolderNode =>
  node !== undefined &&
  isFileTreeNode(node) &&
  (node as FolderNode).children?.length !== undefined
