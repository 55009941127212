/**
 * Checks if two arrays are different also
 * recursively compares arrays and objects inside them
 */
export const hasArrayChanged = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return true

  let i = 0
  for (const el of arr1) {
    if (Array.isArray(el) && Array.isArray(arr2[i])) {
      if (hasArrayChanged(el, arr2[i])) return true
    } else if (typeof el === 'object' && typeof arr2[i] === 'object') {
      if (hasUpdatedKeys(el, arr2[i])) return true
    } else {
      if (el !== arr2[i]) return true
    }
    i += 1
  }

  return false
}

/**
 * Checks if the object in second param has any keys
 * that have different value from object in first param
 * Note: Does not perform a quality check so won't detect
 * removed values unless set to null or undefined
 */
export const hasUpdatedKeys = (
  object1: { [name: string]: any },
  object2: { [name: string]: any }
): boolean => {
  if (object1.length !== object2.length) return true

  for (const key of Object.keys(object2)) {
    const val1 = object1[key]
    const val2 = object2[key]
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (hasArrayChanged(val1, val2)) return true
    } else if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (hasUpdatedKeys(val1, val2)) return true
    } else {
      if (val1 !== val2) return true
    }
  }

  return false
}

export const deepEqual = (object1: object, object2: object) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}

function isObject(object: any): object is object {
  return object != null && typeof object === 'object'
}
