export type GetVideoThumbOptions = {
  seekTo?: number
  quality?: number
}

export const getVideoThumbnail = (
  file: File | string,
  options: GetVideoThumbOptions = { seekTo: 0 }
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video')

    const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)

    videoPlayer.crossOrigin = 'anonymous'
    videoPlayer.src = fileUrl

    videoPlayer.load()
    videoPlayer.addEventListener('error', (e) => {
      console.log(e)
      reject(new Error(e.message))
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      if (!options.seekTo) {
        options.seekTo = videoPlayer.duration / 2
      }
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < options.seekTo) {
        reject(new Error('options.seekTo more than length of video'))
        return
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        // Instead of going to a fractional (3.14) just goto 3 instead
        videoPlayer.currentTime = Math.floor(options.seekTo || 0)
      }, 200)
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas')
        canvas.width = videoPlayer.videoWidth
        canvas.height = videoPlayer.videoHeight
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
        // return the canvas image as a blob
        ctx?.canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob)
            else reject(new Error('Blob could not be created'))
          },
          'image/jpeg',
          options.quality || 0.75 /* quality */
        )
      })
    })
  })
}

export default getVideoThumbnail
