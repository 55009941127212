import blobShape from 'blobshape'
import { Canvg } from 'canvg'
import chroma from 'chroma-js'

import { BackgroundElementInterface } from '@editor/interfaces'

import Wavery from './wavery'

/**
 * Draw an svg on a canvas using canvg library
 */
export const drawSvgOnCanvas = (svg: string, canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Cannot get canvas context')

  if (!svg) {
    console.log('no svg not drawing')
    return
  }

  // replace {width} and {height} with actual height of media element
  const finalSvg = svg
    .replaceAll('{width}', `${canvas.width}`)
    .replaceAll('{height}', `${canvas.height}`)

  const cvg = Canvg.fromString(ctx, finalSvg)

  // NOTE: This is an async function but it doesn't need to be one
  // So currently not awaiting it
  cvg.render()
}

type GenerateBackgroundElemSvgFunc = (_props: {
  backgroundElement: BackgroundElementInterface
}) => string

/**
 * Generates the svg for a static element by its type
 */
export const generateBackgroundElementSvg: GenerateBackgroundElemSvgFunc = (
  props
) => {
  const {
    backgroundElement: { properties }
  } = props

  switch (properties.type) {
    case 'Solid':
      return generateSolidSvg(props)
    case 'Gradient':
      return generateGradientSvg(props)
    case 'Wave':
      return generateWaveSvg(props)
    case 'Blob':
      return generateBlobSvg(props)
    default:
      return ''
  }
}

/**
 * Generates a simple svg with a <rect> to show a solid color
 */
const generateSolidSvg: GenerateBackgroundElemSvgFunc = (props) => {
  const {
    backgroundElement: { properties }
  } = props

  if (properties.type !== 'Solid') return ''

  const color = properties.color

  // The {width} and {height} will be replaced when drawing on canvas with media element's width and height
  return `
    <svg width="{width}px" height="{height}px" viewBox="0 0 {width} {height}">
      <rect x="0" y="0" width="100%" height="100%" fill="${color}"></rect>
    </svg>`
}

/**
 * Generates a svg with a linear gradient
 */
const generateGradientSvg: GenerateBackgroundElemSvgFunc = (props) => {
  const {
    backgroundElement: { properties }
  } = props

  if (properties.type !== 'Gradient') return ''

  // The gradient starts from (x1, y1) and goes to (x2, y2)
  let gradientCoords = { x1: '0%', y1: '0%', x2: '100%', y2: '0%' }
  // Base on direction set the coordinates
  switch (properties.direction) {
    case 'from left': {
      gradientCoords = { x1: '0%', y1: '0%', x2: '100%', y2: '0%' }
      break
    }
    case 'from right': {
      gradientCoords = { x1: '100%', y1: '0%', x2: '0%', y2: '0%' }
      break
    }
    case 'from top': {
      gradientCoords = { x1: '0%', y1: '0%', x2: '0%', y2: '100%' }
      break
    }
    case 'from bottom': {
      gradientCoords = { x1: '0%', y1: '100%', x2: '0%', y2: '0%' }
      break
    }
    default: {
      gradientCoords = { x1: '0%', y1: '0%', x2: '0%', y2: '0%' }
      break
    }
  }

  const { x1, y1, x2, y2 } = gradientCoords

  // Generate a simple rect filled with a gradient
  // The {width} and {height} will be replaced when drawing on canvas with media element's width and height
  return `
    <svg width="{width}px" height="{height}px" viewBox="0 0 {width} {height}">
      <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)"/>
      <defs>
        <linearGradient id="gradient" x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stop-color="${properties.from}"/>
          <stop offset="100%" stop-color="${properties.to}"/>
        </linearGradient>
      </defs>
    </svg>`
}

/**
 * Generates a wave svg using the wavery library
 */
const generateWaveSvg: GenerateBackgroundElemSvgFunc = (props) => {
  const {
    backgroundElement: { properties, svg: oldSvg }
  } = props

  if (properties.type !== 'Wave') return ''

  try {
    // If not secondary color chosen, select a 2 shade brighter color
    const secondaryColor =
      properties.toColor || chroma(properties.color).brighten(2).hex()

    const wavery = new Wavery({
      width: 1920,
      height: 1080,
      segmentCount: properties.waves,
      layerCount: properties.layers,
      variance: properties.variance,
      strokeWidth: 0,
      strokeColor: 'none',
      gradientColors: [
        // The color for the top most layer
        { colorValue: secondaryColor, position: 0 },
        // The color for the bottom most layer
        { colorValue: properties.color, position: 1 }
      ]
    })

    const svg = wavery.generateSvg()

    // Here, the svg will have a fixed height and width instead of having media element's height an width
    return svg.outerHTML
  } catch (err) {
    console.log(err)
    // if an error occurs it would be due to wrong color being entered so just return the last svg for now
    return oldSvg
  }
}

/**
 * Generates a random blob using the blobshape library
 */
const generateBlobSvg: GenerateBackgroundElemSvgFunc = (props) => {
  const {
    backgroundElement: { properties }
  } = props

  if (properties.type !== 'Blob') return ''

  const { path } = blobShape({
    size: properties.size,
    growth: properties.growth,
    edges: properties.points
  })

  // Will offset blob to (-offset, -offset) to center it properly within the svg
  const offset = properties.size / 2

  // The {width} and {height} will be replaced when drawing on canvas with media element's width and height
  // Using the <use> with the <path> defined separately so that we can align it in middle using %ages
  // instead of having to access the media elem's height and width
  return `
    <svg width="{width}px" height="{height}px" viewBox="0 0 {width} {height}">
      ${
        properties.backgroundColor
          ? `<rect x="0" y="0" width="100%" height="100%" fill="${properties.backgroundColor}" />`
          : ''
      }
      <use href="#blob" x="50%" y="50%" transform="translate(-${offset},-${offset})" />
      <defs>
        <path id="blob" d="${path}" fill="${properties.color}" />
      </defs>
    </svg>`
}
