import { nanoid } from 'nanoid'

import {
  AudioInterface,
  BackgroundElementInterface,
  ImageInterface,
  MediaElementId,
  MediaElementInterface,
  MediaElementParams,
  MediaInterface,
  MediaType,
  TextInterface,
  ThirdPartyEmbedInterface,
  TransitoryDuration,
  VideoInterface
} from '@editor/interfaces'
import { settingStore } from '@editor/state/mobx'

import {
  Audio,
  BackgroundElement,
  CommentClass,
  Image,
  Text,
  ThirdPartyEmbed,
  TlDrawElement,
  TLDrawElementProps,
  Video
} from '..'

/**
 * Helper function to create the appropriate media element
 */
export function createMediaInterface(
  value: MediaInterface,
  count = 0
): MediaInterface | null {
  if (count > 1000) {
    return null
  }

  const { type } = value

  let mediaInterface: MediaInterface | undefined

  switch (type) {
    case MediaType.video: {
      mediaInterface = new Video(value as VideoInterface)
      break
    }
    case MediaType.audio: {
      mediaInterface = new Audio(value as AudioInterface)
      break
    }
    case MediaType.text: {
      mediaInterface = new Text(value as TextInterface)
      break
    }
    case MediaType.image: {
      mediaInterface = new Image(value as ImageInterface)
      break
    }
    case MediaType.backgroundElement: {
      mediaInterface = new BackgroundElement(
        value as BackgroundElementInterface
      )
      break
    }
    case MediaType.tlDrawElement: {
      mediaInterface = new TlDrawElement(value as TLDrawElementProps)
      break
    }
    case MediaType.comments: {
      mediaInterface = new CommentClass(value as CommentClass)
      break
    }
    case MediaType.thirdPartyEmbed: {
      mediaInterface = new ThirdPartyEmbed(value as ThirdPartyEmbedInterface)
      break
    }
    default: {
      throw new Error(`Cannot create media type ${type}`)
    }
  }

  // This is a random function that is defined for all media
  // It makes sure the class is initialized correctly
  if (mediaInterface.upload !== undefined) {
    // Has function definitions
    return mediaInterface
  }
  return createMediaInterface(value, count + 1)
}

export const createMediaElement = (
  props: MediaElementParams,
  isStatic: boolean
): MediaElementInterface => {
  const { width, height } = settingStore.settings.dimensions

  const defaultPosition = {
    x: 0,
    y: 0,
    width: width,
    height: height,
    scale: 0
  }

  const { position, ...overwrites } = props

  const duration = isStatic ? 5 : props.usableDuration

  const id = nanoid() as MediaElementId

  const newMediaElement: MediaElementInterface = {
    position: { ...defaultPosition, ...position },
    id,
    color: 'rgb(1, 108, 135)',
    defaultStart: 0,
    start: 0,
    // If the usable duration is infinity, this is a static media, so by default set duration to 5
    duration,
    transitoryDuration: duration as TransitoryDuration,
    isPrime: false,
    parentStart: props.start || 0,
    display: true,
    playbackRate: props.playbackRate || 1,
    ...overwrites
  }
  return newMediaElement
}
