var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import withApollo from "next-with-apollo";
import posthog from "posthog-js";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { LevaConfig } from "@editor/admin/LevaConfig";
import { Apollo, client } from "@editor/graphql/apollo/ApolloProvider";
import { modeStore } from "@editor/state";
import { usePosthog } from "@editor/utils/hooks";
import { ErrorBoundary } from "@sentry/nextjs";
import withTwindApp from "@twind/next/app";
import "core-js/proposals/reflect-metadata";
import "reflect-metadata";
import "@fontsource/inter";
import ErrorPage from "../components/ErrorPage";
import GoogleAnalytics from "../components/GoogleAnalytics";
import { twindConfig } from "../styles/twind";
import errorToString from "../util/errorToString";
import "../styles/global.css";
const ModfyApp = ({ Component, pageProps }) => {
  usePosthog(posthog, "phc_MgRJTH8hFDxmRaEceqIMfW6aXJaiC2WRU3t09IGrBdt", {
    api_host: "https://app.posthog.com",
    loaded: (posthog2) => {
      if (process.env.NODE_ENV === "development")
        posthog2.opt_out_capturing();
    }
  });
  useEffect(() => {
    modeStore.setIsAnno(true);
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(LevaConfig, null), /* @__PURE__ */ React.createElement(GoogleAnalytics, null), /* @__PURE__ */ React.createElement(DefaultSeo, {
    title: "Annotate your videos",
    openGraph: {
      type: "website",
      locale: "en_IE",
      url: "https://anno.so",
      site_name: "Modfy",
      title: "Annotate your video",
      description: "Annotate, comment & share your videos",
      images: [
        {
          url: "https://anno.so/images/banner.png",
          alt: "Og image"
        }
      ]
    },
    twitter: {
      handle: "@modfydotvideo",
      site: "@modfydotvideo",
      cardType: "summary_large_image"
    },
    additionalLinkTags: [
      {
        rel: "icon",
        href: "/images/logo.svg"
      }
    ]
  }), /* @__PURE__ */ React.createElement(Head, null, /* @__PURE__ */ React.createElement("link", {
    crossOrigin: "anonymous",
    rel: "preconnect",
    href: "https://stijndv.com"
  }), /* @__PURE__ */ React.createElement("link", {
    crossOrigin: "anonymous",
    rel: "stylesheet",
    href: "https://stijndv.com/fonts/Eudoxus-Sans.css"
  })), /* @__PURE__ */ React.createElement(Toaster, {
    position: "top-right"
  }), /* @__PURE__ */ React.createElement(ErrorBoundary, {
    fallback: ({ error, resetError }) => /* @__PURE__ */ React.createElement(ErrorPage, {
      errorMsg: errorToString(error),
      detailedError: error.stack,
      resetBoundary: resetError
    })
  }, /* @__PURE__ */ React.createElement(Apollo, null, /* @__PURE__ */ React.createElement(Component, __spreadValues({}, pageProps)))));
};
export function reportWebVitals(metric) {
  console.log(metric);
}
export default withTwindApp(twindConfig, withApollo(() => client)(ModfyApp));
