/**
 * This store is used for handling drag actions with DndContext globally
 */

import { DragType, PanelMap } from '@editor/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppDispatch, useAppSelector } from '../hook'

type StringOrNull = string | null

type dragSliceState = {
  activeId: StringOrNull
  timelineActiveId: StringOrNull
  panels: PanelMap
  thumbnailItems: string[]
  activeDuration: number | null
  isDragMove: boolean
  dragType: DragType | null
}

const initialState: dragSliceState = {
  activeId: null,
  timelineActiveId: null,
  panels: {},
  thumbnailItems: [],
  activeDuration: null,
  isDragMove: false,
  dragType: null
}

const dragSlice = createSlice({
  name: 'dragSlice',
  initialState,
  reducers: {
    setActiveId: (
      state,
      action: PayloadAction<{ value: StringOrNull; timeline?: boolean }>
    ) => {
      const { value, timeline } = action.payload

      if (timeline) {
        state.timelineActiveId = value
      } else {
        state.activeId = value
      }
    },

    setPanels: (
      state,
      action: PayloadAction<PanelMap | ((_map: PanelMap) => PanelMap)>
    ) => {
      const { payload } = action

      if (typeof payload === 'function') {
        state.panels = payload(state.panels)
      } else {
        state.panels = payload
      }
    },
    setThumbnailItems: (
      state,
      action: PayloadAction<string[] | ((_map: string[]) => string[])>
    ) => {
      const { payload } = action

      if (typeof payload === 'function') {
        state.thumbnailItems = payload(state.thumbnailItems)
      } else {
        state.thumbnailItems = payload
      }
    },
    setActiveDuration: (state, action: PayloadAction<number | null>) => {
      state.activeDuration = action.payload
    },
    setIsDragMove: (state, action: PayloadAction<boolean>) => {
      state.isDragMove = action.payload
    },
    setDragType: (state, action: PayloadAction<DragType | null>) => {
      state.dragType = action.payload
    }
  }
})

export const {
  setActiveId,
  setPanels,
  setThumbnailItems,
  setActiveDuration,
  setIsDragMove,
  setDragType
} = dragSlice.actions

export default dragSlice.reducer

export const useDragSlice = () => {
  const dragState = useAppSelector((state) => state.drag)

  const dispatch = useAppDispatch()

  const _setActiveId = (value: StringOrNull, timeline?: boolean) => {
    dispatch(setActiveId({ value, timeline }))
  }

  const setTimelineActiveId = (value: StringOrNull) => {
    _setActiveId(value, true)
  }

  const _setPanels = (...props: Parameters<typeof setPanels>) => {
    dispatch(setPanels(...props))
  }

  const _setThumbnailItems = (
    ...props: Parameters<typeof setThumbnailItems>
  ) => {
    dispatch(setThumbnailItems(...props))
  }

  const _setIsDragMove = (...props: Parameters<typeof setIsDragMove>) => {
    dispatch(setIsDragMove(...props))
  }

  const _setActiveDuration = (props: number | null) => {
    dispatch(setActiveDuration(props))
  }

  const _setDragType = (type: DragType | null) => {
    dispatch(setDragType(type))
  }

  return {
    ...dragState,
    setActiveId: _setActiveId,
    setPanels: _setPanels,
    setThumbnailItems: _setThumbnailItems,
    setActiveDuration: _setActiveDuration,
    setIsDragMove: _setIsDragMove,
    setTimelineActiveId,
    setDragType: _setDragType
  }
}
