import { useMemo } from 'react'

import { isSafari } from '@editor/utils/isSafari'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { settingStore } from '../../mobx'
import { useAppSelector } from '../index'

const initialState: {
  playhead: number
  /**
   * Used only for Safari
   */
  seekingPlayhead: number | undefined
  isPlaying: boolean
} = {
  playhead: 0,
  seekingPlayhead: undefined,
  isPlaying: false
}

const playbackSlice = createSlice({
  name: 'playback',
  initialState,
  reducers: {
    setPlayhead: (
      state,
      action: PayloadAction<{
        diff?: boolean
        value: number
        // This is not a playing state, just moving the playhead around manually
        seeking?: boolean
        rendering?: true
      }>
    ) => {
      const { diff, value, seeking, rendering } = action.payload

      let newValue = state.playhead

      if (diff) {
        const difference = value
        newValue += difference
      } else {
        if (Math.abs(value - state.playhead) > 0.01) {
          newValue = value
        }
      }

      if (seeking) {
        if (isSafari()) {
          state.seekingPlayhead = value
        }
        state.playhead = value
      } else if (state.isPlaying) {
        state.playhead = newValue
      } else if (rendering) {
        state.playhead = newValue
      }
    },
    resetSeekingPlayhead: (state, action: PayloadAction<undefined>) => {
      state.seekingPlayhead = action.payload
    },
    setPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload
    },
    togglePlaying: (state) => {
      state.isPlaying = !state.isPlaying
    }
  }
})

export const { setPlayhead, setPlaying, togglePlaying, resetSeekingPlayhead } =
  playbackSlice.actions

export default playbackSlice.reducer

export const usePlaybackSlice = () => {
  const { playhead, seekingPlayhead, ...args } = useAppSelector(
    (state) => state.playback
  )

  const playheadInSeconds = useMemo(
    () => playhead / settingStore.fps,
    [playhead]
  )

  return { playhead, seekingPlayhead, ...args, playheadInSeconds }
}
