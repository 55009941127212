import { action, makeObservable, observable } from 'mobx'

import {
  BackgroundElementInterface,
  BackgroundElementProperties,
  MediaPlaybackState,
  MediaProps,
  MediaType
} from '@editor/interfaces'
import {
  drawSvgOnCanvas,
  generateBackgroundElementSvg
} from '@editor/utils/svg'

import { multiplayerStore, videoContextStore } from '../../index'
import { getTrackFromMediaElementId, getZIndexFromContainer } from '../utils'

import { StaticMedia } from './StaticMedia'

export type GradientElementProps = Omit<MediaProps, 'type'> & {
  _id: string
  properties: BackgroundElementProperties
  svg?: string
}

export class BackgroundElement
  extends StaticMedia
  implements BackgroundElementInterface
{
  properties: BackgroundElementProperties
  elements: [string]
  svg: string = ''
  defaultPosition = null

  constructor({ properties, svg, _id, ...props }: GradientElementProps) {
    super({
      _id: _id,
      ...props,
      type: MediaType.backgroundElement,
      mediaPlaybackState: MediaPlaybackState.playable
    })
    this.elements = [this._id]
    this.properties = properties
    this.svg = svg || ''

    if (!svg) this.generateSvg()

    makeObservable(this, {
      properties: observable,
      generateSvg: action,
      updateProperties: action
    })
  }

  /**
   * Generates the svg based on the properties
   * This should only be called when properties change
   */
  generateSvg = () => {
    this.svg = generateBackgroundElementSvg({
      backgroundElement: this
    })
    console.log('[BackgroundElement.generateSvg] output', this.svg)
  }

  get source() {
    const canvas = document.createElement('canvas')

    drawSvgOnCanvas(this.svg, canvas)

    return canvas.toDataURL()
  }

  reDraw = () => {
    const { _id } = this
    const { type, startOnTimeline, duration } = this.mediaElement

    const container = getTrackFromMediaElementId(_id)
    if (container) {
      const zIndex = getZIndexFromContainer(container)
      videoContextStore.reDrawBackgroundElementOnCanvas({
        id: _id,
        backgroundElement: this,
        startTime: startOnTimeline,
        endTime: startOnTimeline + duration,
        type,
        zIndex: zIndex
      })
    }
  }

  /**
   * Returns first media element if there are multiple
   */
  get mediaElement() {
    const element = this.getMediaElement(this.elements[0])
    if (!element) throw new Error("This Media's element is not defined")
    return element
  }

  private _handleSideEffects = (
    ignore?: boolean,
    options?: { skipDraw?: boolean; skipUpdate?: boolean }
  ) => {
    const { skipDraw, skipUpdate } = options || {
      skipDraw: false,
      skipUpdate: false
    }
    if (ignore) return
    if (!skipDraw) this.reDraw()
    if (!skipUpdate) this._updateMultiplayer()
  }

  private _updateMultiplayer = () => {
    const { multiplayerInstance } = multiplayerStore
    multiplayerInstance?.setMedia({ ...this })
  }

  /**
   * Updates the properties and generates a new svg for the element
   */
  updateProperties = (
    newProperties: BackgroundElementProperties,
    ignoreSideEffects?: boolean
  ) => {
    this.properties = { ...newProperties }
    this.generateSvg()
    this._handleSideEffects(ignoreSideEffects)
  }

  /**
   * For some types (blobs, waves) passing same properties result in random solutions
   * This function just regenerates the svg with current properties for that
   */
  randomize = (ignoreSideEffects?: boolean) => {
    this.generateSvg()
    this._handleSideEffects(ignoreSideEffects)
  }
}
