/* eslint-disable simple-import-sort/imports */
import { toJS } from 'mobx'
import { Cookies } from 'react-cookie'
import { IndexeddbPersistence } from 'y-indexeddb'
import { Awareness } from 'y-protocols/awareness'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'

import {
  IgnoreTracks,
  MediaTrack,
  MultiplayerUser,
  MultiplayerUsers,
  SpliceTuple
} from '@editor/interfaces'
import {
  fileStore,
  mediaStore,
  settingStore,
  store,
  videoContextStore
} from '@editor/state/index'
import { debounce, hasArrayChanged, isProd } from '@editor/utils'

import {
  executeCut,
  removeFile,
  removeFolder,
  removeMedia,
  removeMediaElement,
  setFile,
  setFolder,
  setMedia,
  setMediaElement,
  setSelectedMedia,
  updateFolders
} from './lib/actions'
import {
  ORIGIN_FORCE,
  setupFiles,
  setupMediaElements,
  setupMedias,
  setupTracks,
  syncLocalStateToRemote,
  syncRemoteStateToLocal
} from './lib/setup'
import { setupUndo } from './lib/undo'
import { MultiplayerWorkspaceState } from './types'

const cookies = new Cookies()

const websocketURL =
  process.env.NODE_ENV === 'development'
    ? `ws://${process.env.NEXT_PUBLIC_HOST || 'localhost'}:5000/multiplayer`
    : 'wss://api.editor.modfy.video/multiplayer'

type Callback = () => void

/**
 * Props without self, excluding MultiplayerManager
 */
type Props<T extends (..._props: any[]) => any> = SpliceTuple<Parameters<T>, 0>

class MultiplayerManager implements MultiplayerWorkspaceState {
  room: string
  yDoc: Y.Doc

  indexeddbProvider: IndexeddbPersistence | null
  websocketProvider?: WebsocketProvider = undefined

  awareness?: Awareness

  // @ts-expect-error Undo setup on `setupListeners` not constructor
  undoManager: Y.UndoManager

  files: MultiplayerWorkspaceState['files']
  folders: MultiplayerWorkspaceState['folders']
  mediaState: MultiplayerWorkspaceState['mediaState']
  mediaElements: MultiplayerWorkspaceState['mediaElements']
  tracks: MultiplayerWorkspaceState['tracks']

  hasConnected: boolean = false
  firstStart: boolean = false
  onConnectCallbacks: Callback[] = []

  saveFn?: (_yDoc: Y.Doc) => Promise<void>
  /**
   * Fires when the project saved state changes
   * true means the project is saved, false means it is unsaved
   */
  onSaveStateChange?: (_saved: boolean) => void

  /**
   * TODO: IDEALLY ROOM COMES FROM SSR modfy.video/editor/roomId
   * with preload room state from redis or db or whatever on SSR
   */
  constructor({
    room = 'default',
    firstStart = false,
    connectWS = true,
    onSaveStateChange,
    enableAnnotateAutosave = false,
    disableIndexedDb = false
  }: {
    room: string
    firstStart: boolean
    connectWS?: boolean
    onSaveStateChange?: (_saved: boolean) => void
    enableAnnotateAutosave?: boolean
    disableIndexedDb?: boolean
  }) {
    this.room = room
    this.firstStart = firstStart
    this.yDoc = new Y.Doc({ guid: room })

    this.indexeddbProvider =
      isProd && !disableIndexedDb
        ? new IndexeddbPersistence(this.room, this.yDoc)
        : null

    if (connectWS) {
      const accessToken = cookies.get('token')
      if (!accessToken) throw new Error('No access token set')

      this.connectWebSocket({ accessToken })
    }

    this.mediaElements = this.yDoc.getMap('mediaElements')
    this.mediaState = this.yDoc.getMap('mediaState')
    this.tracks = {
      prime: this.yDoc.getArray('track-prime'),
      above: this.yDoc.getArray('track-above'),
      below: this.yDoc.getArray('track-below')
    }
    this.folders = this.yDoc.getMap('folders')
    this.files = this.yDoc.getMap('files')

    if (onSaveStateChange) this.onSaveStateChange = onSaveStateChange
    if (enableAnnotateAutosave) {
      const debouncedSave = debounce(async () => {
        await this.save()
      }, 1000)

      this.yDoc.on('update', async (_binary: unknown, origin: unknown) => {
        if (origin === 'remote') return
        this.onSaveStateChange?.(false)
        debouncedSave()
      })
    }
  }

  public save = async () => {
    console.log(`Save called, saveFn defined?`, !!this.saveFn)
    if (this.saveFn) {
      await this.saveFn(this.yDoc)
    }
    this.onSaveStateChange?.(true)
  }

  /**
   * Removes the saveFn so that saving doesn't work
   * Should only be used when switching to a new version of a project
   */
  public disableAnnotateAutoSave() {
    this.saveFn = undefined
  }

  /**
   * Sets the saveFn to the function passed, doesn't enable autosave
   * To enable autosave set `enableAnnotateAutosave` when creating MultiplayerManager
   */
  public setAnnotateAutosaveFn(saveFn: (_doc: Y.Doc) => Promise<void>) {
    this.saveFn = saveFn
  }

  /**
   * Used to connect to websocket
   * In annotate, the params will be { isAnno: 'true' }, which in backend will mean we are trying to access a annotate project
   * And in editor, the params will be { accessToken: '' }, which is used to authenticate the users
   */
  public connectWebSocket = (params?: Record<string, string>) => {
    this.websocketProvider = new WebsocketProvider(
      websocketURL,
      this.room,
      this.yDoc,
      { params }
    )

    this.websocketProvider.on('sync', (isSynced: boolean) => {
      this.onSaveStateChange?.(isSynced)
      if (isSynced && !this.hasConnected) {
        this.hasConnected = true
        this.onConnect()
        this.onConnectCallbacks.forEach((cb) => cb())
      }
    })

    this.awareness = this.websocketProvider.awareness
  }

  destroyWebSocket = () => {
    this.websocketProvider?.destroy()
    this.websocketProvider = undefined
  }

  /**
   * Destroy the underlying YDoc and WebSocket connection, make sure to
   * clear all references to properly garbage collect this class
   */
  destroy() {
    this.destroyWebSocket()
    this.yDoc.destroy()
  }

  registerOnConnectCallback(callback: Callback) {
    this.onConnectCallbacks.push(callback)
  }

  /**
   * Return an object with uuid as key and user object as value
   */
  get usersState() {
    if (!this.awareness) return {}

    const userStates: MultiplayerUsers = {}
    this.awareness.states.forEach((value, key) => {
      userStates[key] = value as MultiplayerUser
    })
    return userStates
  }

  undo = () => {
    this.undoManager.undo()
  }

  redo = () => this.undoManager.redo()

  /**
   * Set's the current user object
   */
  setUser(user: MultiplayerUser) {
    if (!this.awareness) return

    for (const key of Object.keys(user)) {
      this.awareness.setLocalStateField(key, user[key])
    }
  }

  /**
   * Sets the state of the current user
   */
  setUserState(newState: Partial<MultiplayerUser['state']>) {
    if (!this.awareness) return

    const clientID = this.awareness.clientID
    const currentState = this.awareness.states.get(clientID)
    this.awareness.setLocalStateField('state', {
      ...(currentState?.state || {}),
      ...newState
    })
  }

  updateTrack<T extends keyof MediaTrack>(key: T, value: MediaTrack[T]) {
    // the value might come as a mobx proxy
    // that causes the multiplayer state to have a proxy (an object) instead of an array
    // so here it is converted to a simple array with no proxy
    const track = toJS(value)
    const hasChanged = hasArrayChanged(this.tracks[key].toArray(), track)
    if (!hasChanged) return

    this.yDoc.transact(() => {
      if (this.tracks[key].length > 0) {
        this.tracks[key].delete(0, this.tracks[key].length)
      }
      // ts gives error so this is here, it's fine for now but
      // if there's more code we should probably do something better
      this.tracks[key].insert(0, track as string[][] & string[])
    })
  }

  /**
   * A simple function to clear the current document
   * and initialize it to a default state
   */
  cleanState() {
    this.yDoc.transact(() => {
      this.tracks.prime.delete(0, this.tracks.prime.length)
      this.tracks.above.delete(0, this.tracks.above.length)
      this.tracks.below.delete(0, this.tracks.below.length)

      this.tracks.above.push([])
      this.tracks.below.push([])

      for (const key of Object.keys(this.mediaElements.toJSON())) {
        this.mediaElements.delete(key)
      }

      for (const key of Object.keys(this.mediaState.toJSON())) {
        this.mediaState.delete(key)
      }

      for (const key of Object.keys(this.files.toJSON())) {
        this.files.delete(key)
      }

      for (const key of Object.keys(this.folders.toJSON())) {
        this.folders.delete(key)
      }
      this.folders.set('root', { id: 'root', label: 'root', children: [] })

      // force these updates to be applied to local state as well
    }, ORIGIN_FORCE)
  }

  /**
   * Takes a snapshot of the current yjs state in an object
   */
  snapshot = (): MultiplayerWorkspaceState => {
    return {
      mediaElements: this.mediaElements.toJSON(),
      mediaState: this.mediaState.toJSON(),
      files: this.files.toJSON(),
      folders: this.folders.toJSON(),
      tracks: {
        prime: this.tracks.prime.toJSON(),
        above: this.tracks.above.toJSON(),
        below: this.tracks.below.toJSON()
      }
    }
  }

  syncRemoteStateToLocal = (ignoreTracks?: IgnoreTracks) =>
    syncRemoteStateToLocal(this, ignoreTracks)

  onConnect = () => {
    // When multiplayer is first started we set the remote state
    // or else we get the remote state and set local state
    if (this.firstStart) {
      syncLocalStateToRemote(this)
      // Upload existing medias
      // uploadLocalMediasToCloud(Object.values(mediaStore.medias))
    } else {
      syncRemoteStateToLocal(this)
    }

    // Setup listeners on remote state and upload files
    this.setupListeners()

    // initialize the video context nodes again
    const state = store.getState()
    const {
      media: { mediaElements },
      playback: { playhead }
    } = state

    videoContextStore.initNodes(
      mediaStore.tracks,
      mediaElements,
      playhead / settingStore.fps
    )

    fileStore.checkFileIntegrity()
  }

  setupListeners = (ignoreTracks?: IgnoreTracks) => {
    setupMediaElements(this)
    setupFiles(this)
    setupMedias(this)
    setupTracks(this, ignoreTracks)

    const { tracks, mediaElements, mediaState, folders, files } = this

    this.undoManager = setupUndo({
      tracks,
      mediaElements,
      mediaState,
      folders,
      files
    })
  }

  /**
   * Used to batch multiple multiplayer state updates as one
   * @param transactionFunction A function in which changes are made to multiplayer state
   */
  transact = (transactionFunction: () => void) => {
    this.yDoc.transact(() => {
      transactionFunction()
    })
  }

  executeCut = (...props: Props<typeof executeCut>) =>
    executeCut(this, ...props)

  setMedia = (...props: Props<typeof setMedia>) => setMedia(this, ...props)

  removeMedia = (...props: Props<typeof removeMedia>) =>
    removeMedia(this, ...props)

  setMediaElement = (...props: Props<typeof setMediaElement>) =>
    setMediaElement(this, ...props)

  removeMediaElement = (...props: Props<typeof removeMediaElement>) =>
    removeMediaElement(this, ...props)

  setFile = (...props: Props<typeof setFile>) => setFile(this, ...props)

  removeFile = (...props: Props<typeof removeFile>) =>
    removeFile(this, ...props)

  setFolder = (...props: Props<typeof setFolder>) => setFolder(this, ...props)

  removeFolder = (...props: Props<typeof removeFolder>) =>
    removeFolder(this, ...props)

  updateFolders = (...props: Props<typeof updateFolders>) =>
    updateFolders(this, ...props)

  setSelectedMedia = (...props: Props<typeof setSelectedMedia>) =>
    setSelectedMedia(this, ...props)
}

export default MultiplayerManager
