import { MediaType, TrackType } from '@editor/interfaces'

type MediaSwitchFunc = {
  video?: () => void
  audio?: () => void
  image?: () => void
  text?: () => void
  default?: () => void
}

type TrackSwitchFunc = {
  prime: () => void
  above?: () => void
  below?: () => void
  default?: () => void
}

export const switchMediaType = (
  type: MediaType,
  functions: MediaSwitchFunc
) => {
  switch (type) {
    case MediaType.video: {
      if (functions.video) functions.video()
      break
    }
    case MediaType.audio: {
      if (functions.audio) functions.audio()
      break
    }
    case MediaType.image: {
      if (functions.image) functions.image()
      break
    }
    case MediaType.text: {
      if (functions.text) functions.text()
      break
    }
    default: {
      if (functions.default) functions.default()
      break
    }
  }
}

export const switchTrackType = (
  type: TrackType,
  functions: TrackSwitchFunc
) => {
  switch (type) {
    case 'prime': {
      functions.prime()
      break
    }
    case 'above': {
      if (functions.above) functions.above()
      break
    }
    case 'below': {
      if (functions.below) functions.below()
      break
    }
    default: {
      if (functions.default) functions.default()
      break
    }
  }
}
