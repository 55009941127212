/**
 * All functions related to setting up multiplayer
 */

import { Transaction } from 'yjs'

import { IgnoreTracks, MediaInterface } from '@editor/interfaces'
import {
  addMediaElement,
  fileStore,
  mediaStore,
  removeMediaElement,
  store,
  updateMediaElement
} from '@editor/state/index'
import { warnError } from '@editor/utils'

import {
  createMediaElement,
  createMediaInterface
} from '../../media/helpers/createMedia'
import MultiplayerManager from '../MultiplayerManager'

export type MultiplayerManagerAdapter = {
  files: MultiplayerManager['files']
  folders: MultiplayerManager['folders']
  mediaElements: MultiplayerManager['mediaElements']
  mediaState: MultiplayerManager['mediaState']
  tracks: MultiplayerManager['tracks']
}

// Used to force apply multiplayer updates from local
export const ORIGIN_FORCE = 'ORIGIN_FORCE'

/**
 * Determines if a transaction should be applied to the local state
 *
 * A transaction doesn't need to be applied to local state
 * If the transaction happened because of an update to yDoc from the current client
 * And it wasn't a undo or redo
 *
 * If origin of transaction is set to ORIGIN_FORCE, it should always be applied
 *
 * @returns false if it should be applied, true if it shouldn't be applied
 */
export const checkTransaction = (transaction: Transaction) => {
  if (transaction.origin === ORIGIN_FORCE) return false
  if (
    transaction.local &&
    !transaction.origin?.undoStack &&
    !transaction.origin?.redoStack
  ) {
    return true
  }
  return false
}

/**
 * Used to set the local state from remote state
 * when user joins a multiplayer session
 */
export const syncRemoteStateToLocal = (
  multiplayerInstance: MultiplayerManagerAdapter,
  ignoreTracks?: IgnoreTracks
) => {
  return new Promise<void>((resolve) => {
    /**
     * Order in which things are synced is specific
     * as each one relies on one before it
     * 1. Files and folders
     * 2. Media elements
     * 3. Medias
     * 4. Timeline
     */

    console.log('(MediaSetup) Setting local state from remote')

    // if (!isRenderMode) {
    multiplayerInstance.files.forEach((file) => {
      fileStore.handleMultiplayerFileSet(file)
    })
    multiplayerInstance.folders.forEach((folder) => {
      fileStore.handleMultiplayerFolderSet(folder)
    })
    // }

    multiplayerInstance.mediaElements.forEach((mediaElem) => {
      store.dispatch(addMediaElement(mediaElem))
    })

    multiplayerInstance.mediaState.forEach((media) => {
      const createdMedia = createMediaInterface(media as MediaInterface)
      if (createdMedia) {
        mediaStore.handleMultiplayerMediaAdd(createdMedia)
      }
    })

    if (!ignoreTracks?.prime) {
      const trackPrime = multiplayerInstance.tracks.prime.toArray()
      mediaStore.handleMultiplayerTrackUpdate(trackPrime, 'prime')
    }

    const trackAbove = multiplayerInstance.tracks.above.toArray()
    mediaStore.handleMultiplayerTrackUpdate(trackAbove, 'above')
    const trackBelow = multiplayerInstance.tracks.below.toArray()
    mediaStore.handleMultiplayerTrackUpdate(trackBelow, 'below')

    // On first syncing of a new project, the tracks could get empty, so this is a fix for that
    // Cant have a default value in multiplayer as syncing gets weird
    // Makes sure there is an empty track at the end in above/below tracks
    mediaStore.updateTracks()

    resolve()
  })
}

/**
 * Used to set the remote state from local state
 * when the multiplayer is started
 */
export const syncLocalStateToRemote = (
  multiplayerInstance: MultiplayerManager
) => {
  /**
   * Order in which things are synced is specific
   * as each one relies on one before it
   * 1. Files and folders
   * 2. Media elements
   * 3. Medias
   * 4. Timeline
   */

  for (const key of Object.keys(fileStore.files)) {
    // BANG: We are iterating over the keys of a file
    const value = fileStore.files[key]!
    console.log('files', key, value)
    multiplayerInstance.files.set(key, value)
  }

  for (const key of Object.keys(fileStore.folders)) {
    // BANG: We are iterating over the keys of a file
    const value = fileStore.folders[key]!
    console.log('folders', key, value)
    multiplayerInstance.folders.set(key, value)
  }

  const state = store.getState()
  const { mediaElements } = state.media
  for (const key of Object.keys(state.media.mediaElements)) {
    // BANG: We are iterating over the keys of a file
    const value = mediaElements[key]!
    console.log('mediaElements', key, value)
    multiplayerInstance.mediaElements.set(key, value)
  }

  for (const key of Object.keys(mediaStore.medias)) {
    // BANG: We are iterating over the keys of a file
    const value = mediaStore.medias[key]!
    console.log('mediaState', key, value)
    multiplayerInstance.mediaState.set(key, { ...value })
  }

  multiplayerInstance.updateTrack('prime', mediaStore.tracks.prime)
  multiplayerInstance.updateTrack('above', mediaStore.tracks.above)
  multiplayerInstance.updateTrack('below', mediaStore.tracks.below)
}

/**
 * Listens for changes on media elements in remotes and updates local state
 */
export const setupMediaElements = (multiplayerInstance: MultiplayerManager) => {
  multiplayerInstance.mediaElements.observeDeep((events, transaction) => {
    if (checkTransaction(transaction)) return
    events.forEach((event) => {
      event.changes.keys.forEach((item, key) => {
        if (item.action === 'add') {
          const mediaElement = multiplayerInstance?.mediaElements.get(key)

          if (mediaElement) {
            // Just passing false here as all the things will be overridden and it doesnt matter
            const mediaElementInterface = createMediaElement(
              mediaElement,
              false
            )
            store.dispatch(addMediaElement(mediaElementInterface))
          } else {
            warnError(
              "Multiplayer adding mediaElement, mediaElement doesn't exist"
            )
          }
        } else if (item.action === 'delete') {
          store.dispatch(removeMediaElement({ id: key }))
        } else if (item.action === 'update') {
          const mediaElement = multiplayerInstance.mediaElements.get(key)
          if (mediaElement) {
            store.dispatch(updateMediaElement({ id: key, mediaElement }))
          }
        }
      })
    })
  })
}

/**
 * Listens for changes on tracks in remotes and updates local state
 */
export const setupTracks = (
  multiplayerInstance: MultiplayerManager,
  ignoreTracks?: IgnoreTracks
) => {
  if (!ignoreTracks?.prime) {
    multiplayerInstance.tracks.prime.observeDeep((_events, transaction) => {
      if (checkTransaction(transaction)) return

      mediaStore.handleMultiplayerTrackUpdate(
        multiplayerInstance.tracks.prime.toArray(),
        'prime'
      )
    })
  }
  if (!ignoreTracks?.above) {
    multiplayerInstance.tracks.above.observeDeep((_events, transaction) => {
      if (checkTransaction(transaction)) return

      mediaStore.handleMultiplayerTrackUpdate(
        multiplayerInstance.tracks.above.toArray(),
        'above'
      )
    })
  }
  if (!ignoreTracks?.below) {
    multiplayerInstance.tracks.below.observeDeep((_events, transaction) => {
      if (checkTransaction(transaction)) return

      mediaStore.handleMultiplayerTrackUpdate(
        multiplayerInstance.tracks.below.toArray(),
        'below'
      )
    })
  }
}

/**
 * Listens for changes on files multiplayer state and update the local state
 */
export const setupFiles = (multiplayerInstance: MultiplayerManager) => {
  multiplayerInstance.folders.observeDeep((events, transaction) => {
    if (checkTransaction(transaction)) return
    events.forEach((event) => {
      event.changes.keys.forEach((item, key) => {
        if (item.action === 'add') {
          const value = multiplayerInstance.folders.get(key)
          if (!value) return
          fileStore.handleMultiplayerFolderSet(value)
        } else if (item.action === 'delete') {
          fileStore.handleMultiplayerFolderRemove(key)
        } else if (item.action === 'update') {
          const value = multiplayerInstance.folders.get(key)
          if (!value) return
          fileStore.handleMultiplayerFolderSet(value)
        }
      })
    })
  })

  multiplayerInstance.files.observeDeep((events, transaction) => {
    if (checkTransaction(transaction)) return
    events.forEach((event) => {
      event.changes.keys.forEach((item, key) => {
        if (item.action === 'add') {
          const value = multiplayerInstance.files.get(key)
          if (!value) return
          fileStore.handleMultiplayerFileSet(value)
        } else if (item.action === 'delete') {
          fileStore.handleMultiplayerFileRemove(key)
        } else if (item.action === 'update') {
          const value = multiplayerInstance.files.get(key)
          if (!value) return
          fileStore.handleMultiplayerFileSet(value)
        }
      })
    })
  })
}

/**
 * Listens for changes on medias multiplayer state and update the local state
 */
export const setupMedias = (multiplayerInstance: MultiplayerManager) => {
  multiplayerInstance.mediaState.observeDeep((events, transaction) => {
    if (checkTransaction(transaction)) return
    events.forEach((event) => {
      event.changes.keys.forEach(async (item, key) => {
        if (item.action === 'add') {
          const value = multiplayerInstance.mediaState.get(key)
          if (!value) return
          const media = createMediaInterface(value as MediaInterface)
          if (media) {
            mediaStore.handleMultiplayerMediaAdd(media)
          }
        } else if (item.action === 'delete') {
          mediaStore.handleMultiplayerMediaRemove(key)
        } else if (item.action === 'update') {
          const value = multiplayerInstance.mediaState.get(key)
          if (value) mediaStore.handleMultiplayerMediaUpdate(key, value)
        }
      })
    })
  })
}
