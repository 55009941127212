import { useRouter } from 'next/router'
import type posthog from 'posthog-js'
import { useEffect } from 'react'

export const usePosthog = (
  ph: typeof posthog,
  token: string,
  config?: posthog.Config,
  name?: string
) => {
  const router = useRouter()

  useEffect(() => {
    // Init PostHog
    ph.init(token, config, name)

    // Track page views
    const handleRouteChange = () => ph.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }

    // Only initialize once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
