export function getVideoProps(
  file: string | File
): Promise<{ duration: number; width: number; height: number }> {
  return new Promise((resolve, reject) => {
    if (!file) return
    // load the file to a video player
    const videoPlayer = document.createElement('video')
    const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)
    videoPlayer.setAttribute('src', fileUrl)
    videoPlayer.load()
    videoPlayer.addEventListener('error', (e) => {
      console.error(e)
      reject(new Error(e.message))
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // return the duration
      resolve({
        duration: videoPlayer.duration,
        width: videoPlayer.videoWidth,
        height: videoPlayer.videoHeight
      })
    })
  })
}

export function getImageProps(
  file: string | File
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    if (!file) return
    // load the file to a video player
    const imageTag = document.createElement('img')
    const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file)
    imageTag.setAttribute('src', fileUrl)
    imageTag.addEventListener('error', (e) => {
      reject(new Error(e.message))
    })
    // load metadata of the video to get video duration and dimensions
    imageTag.addEventListener('load', () => {
      // return the duration
      resolve({
        width: imageTag.naturalWidth,
        height: imageTag.naturalHeight
      })
    })
  })
}
