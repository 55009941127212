import { action, makeObservable } from 'mobx'

import {
  MediaPlaybackState,
  MediaProps,
  MediaType,
  ThirdPartyEmbedInterface,
  ThirdPartyEmbedType,
  Widen
} from '@editor/interfaces'

import { Media } from './Media'

export type ThirdPartyEmbedProps = Widen<
  Omit<MediaProps, 'type'> & {
    _id: string
    embedType: ThirdPartyEmbedType
    embedUrl: string
  }
>

export class ThirdPartyEmbed extends Media implements ThirdPartyEmbedInterface {
  embedType: ThirdPartyEmbedType
  embedUrl: string

  constructor({ embedType, embedUrl, ...props }: ThirdPartyEmbedProps) {
    super({
      ...props,
      type: MediaType.thirdPartyEmbed,
      mediaPlaybackState: MediaPlaybackState.playable
    })
    this.embedType = embedType
    this.embedUrl = embedUrl

    makeObservable(this, {
      updateDuration: action
    })
  }

  generateThumbnail = () => ''

  updateDuration = (duration: number) => {
    this.duration = duration
  }
}
