import { action, makeAutoObservable } from 'mobx'

import { AnnotateMode, CommentFilter } from '@editor/interfaces'
import { AnnotateProjectQuery } from '@graphql/schema/client'

import tldrawStore from './tldrawStore'

class AnnotateModeStore {
  constructor() {
    makeAutoObservable(this)
  }

  project: AnnotateProjectQuery['getAnnotateProject'] = null

  showTimeline: boolean = false

  hasAnnoLoaded: boolean = false

  annoDownloadError: boolean = false

  @action setAnnoDownloadError = (s: boolean) => (this.annoDownloadError = s)

  @action resetLoadState = () => {
    this.annoDownloadError = false
    this.hasAnnoLoaded = false
  }

  toolMode: AnnotateMode = AnnotateMode.default

  commentFilter: CommentFilter = CommentFilter.Open

  @action toggleShowTimeline() {
    this.showTimeline = !this.showTimeline
    // because the tldraw preview shifts we need to re calc its viewport
    tldrawStore.forceResize()
  }

  @action setToolMode = (mode: AnnotateMode) => {
    this.toolMode = mode

    // Switch to the select tool if mode is not draw
    if (this.toolMode !== AnnotateMode.draw) {
      tldrawStore.selectSelectTool()
    } else {
      tldrawStore.selectPenTool()
    }
  }

  @action setHasAnnoLoaded = (value: boolean) => {
    this.hasAnnoLoaded = value
  }

  @action setCommentFilter = (commentFilter: CommentFilter) =>
    (this.commentFilter = commentFilter)

  @action setProject = (
    project: AnnotateProjectQuery['getAnnotateProject']
  ) => {
    this.project = project
  }
}

export const annotateModeStore = new AnnotateModeStore()

export default annotateModeStore
