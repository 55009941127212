/* eslint-disable no-unused-vars */
import type { TDShape } from '@modfy/tldraw'

import { Newtype } from './utility'
import {
  FileNode,
  MediaElementId,
  MediaPlaybackState,
  MediaType,
  MultiplayerActionType,
  SyncedMediaPlaybackState,
  VideoContextCallback,
  Widen
} from '.'

export interface MediaInterface {
  _id: string
  display: boolean
  isStatic: boolean
  name: string
  type: MediaType
  file?: File
  duration: number
  elements: string[]
  // Not synced across multiplayer
  mediaPlaybackState: MediaPlaybackState
  uploadProgress: number
  // Subset of MediaPlaybackState which is synced across multiplayer
  syncedMediaPlaybackState: SyncedMediaPlaybackState
  defaultPosition: MediaPosition | null

  /**
   * @deprecated This property is not used anymore
   * It is now stored within mediaPlaybackState
   */
  offline: boolean

  /**
   * These properties look similar but they are slightly different
   */

  /**
   * @deprecated The use of url is essentially deprecated and now is fixed value of /api/cdn/${id}
   *
   * Use source instead
   */
  url: string

  /**
   * This is the actual source element for the media, but is not actually stored but
   * rather accessed through a getter
   */
  source: string

  addElement: (_id: string) => void
  setFile: (_file: File) => void
  setElements: (
    _elements: string[] | ((_elements: string[]) => string[]),
    noMultiplayer?: boolean
  ) => undefined | MultiplayerActionType
  getMediaElementProps: (params?: {
    startOnTimeline?: number
  }) => MediaElementParams
  upload: () => Promise<void>
  updateFile: (file: File) => Promise<void>
  updateMediaPlaybackState: (_newState: MediaPlaybackState) => Promise<void>
  updateSyncedMediaPlaybackState: (
    syncedState: SyncedMediaPlaybackState
  ) => void
  getFileStoreFile: () => FileNode | undefined
  updateMultiplayerMedia: () => void
  addVideoContextCallback: (_newCallback: VideoContextCallback) => void
  cacheUrlToBlob: () => void
}

export type MediaProps = Widen<
  Pick<MediaInterface, '_id' | 'name' | 'type' | 'duration'> &
    Partial<Pick<MediaInterface, 'mediaPlaybackState' | 'elements'>>
>

export interface MediaPosition {
  height: number
  width: number
  scale: number
  x: number
  y: number
  /**
   * Rotation about the z-axis
   */
  rotationDegrees?: number
}

declare const TransitoryDurationSymbol: unique symbol
export type TransitoryDuration = Newtype<
  number,
  typeof TransitoryDurationSymbol
>

export interface MediaElementInterface {
  /**
   * startOnTimeline is where the video starts relative to the timeline
   *
   * Previously startSeconds
   */
  startOnTimeline: number
  /**
   * start is the video start relative to itself.
   *
   * This is needed to be able to play the video correctly on the HTML5 Video Player
   *
   * This can be greater than 0 when it is trimmed
   */
  start: number
  /**
   * The current duration of the element
   */
  duration: number
  id: MediaElementId
  mediaId: string
  /**
   * Rate at which the media plays back
   */
  playbackRate?: number
  /**
   * This is the maximum duration the video can go upto
   */
  usableDuration: number
  /**
   * This is a duration used while calculating timeWarp.
   *
   * It is only updated when endpoints change, and it is not affected by playback rate
   *
   * I am sorry in advance (May 2022)
   *
   * https://www.figma.com/file/jQnqCs2QB295PYqN3pokBP/
   */
  transitoryDuration: TransitoryDuration
  /**
   * Think of this as an immutable start. It is assigned the start value when created by does not change after.
   *
   * The original start. In the case of right child cut element, it is the parent's original start + cut point.
   */
  defaultStart: number
  type: MediaType
  /**
   * Is on the prime timeline
   */
  isPrime: boolean
  /**
   * Parent Element Id
   */
  parent?: MediaElementInterface['id']

  leftChild?: MediaElementInterface['id']

  rightChild?: MediaElementInterface['id']

  /**
   * True if this element is an actual element on timeline
   * and false if its just a parent element and doesn't need to be displayed
   */
  display: boolean

  /**
   * @deprecated Not in use anymore
   */
  color: string

  /**
   * Start point of the grand parent (present if this is a cut element)
   * This is not being used anywhere and is not being update anymore
   * @deprecated
   */
  parentStart?: number

  position: MediaPosition

  volume?: number
}

export type MediaElementParams = Pick<
  MediaElementInterface,
  'startOnTimeline' | 'usableDuration' | 'mediaId' | 'type'
> &
  // Partial media element interface, but the position can also be partial
  Partial<Omit<MediaElementInterface, 'position'>> & {
    position?: Partial<MediaElementInterface['position']>
  }

export type MediaElements = {
  [name: string]: MediaElementInterface
}

/**
 * Single Track of Media
 */
export type MediaTrackObject<T extends MediaInterface> = {
  [name: string]: T
}

export type MediaTrackArray = MediaInterface[]

/**
 * All tracks of media
 */
export type MediaState = {
  [name: string]: MediaInterface
}

export type MediaTrack = {
  /** The prime timeline can have any element type and does not need a parent */
  prime: string[]
  /**
   * Above timelines are timelines which have elements parented to the prime timeline.
   *
   * They can support Video, Images, Texts and Shapes
   */
  above: string[][]
  /**
   * Below timelines are timelines which have elements parent to the prime timeline and rendered below the prime
   *
   * They can support only Audio tracks
   */
  below: string[][]
}

export type TrackType = keyof MediaTrack

export type VideoFormat = string // Can change later

export interface VideoInterface extends MediaInterface {
  format: VideoFormat
  defaultPosition: MediaPosition
  generateThumbnail: () => Promise<string>
  generateSvgPath: () => Promise<string>
}

export type ThirdPartyEmbedType = 'youtube' | 'soundcloud'
// | 'vimeo'
// | 'twitch'
// | 'dailymotion'
// | 'mixcloud'
// | 'onlyfans'

export interface ThirdPartyEmbedInterface extends MediaInterface {
  embedType: ThirdPartyEmbedType
  embedUrl: string
  updateDuration: (_duration: number) => void
}

export type AudioFormat = string // Can Change Later

export interface AudioInterface extends MediaInterface {
  defaultPosition: null
  format: AudioFormat
  linkedVideoId?: string
  generateSvgPath: () => Promise<string>
}

export type ImageFormat = string // Can Change Later

export interface ImageInterface extends MediaInterface {
  format: ImageFormat
  defaultPosition: MediaPosition
}

export type BackgroundElementType =
  | 'Solid'
  | 'Gradient'
  | 'Blurry'
  | 'Wave'
  | 'Blob'
  | 'Comment'

interface BaseBackgroundElementProperties {
  type: BackgroundElementType
}
export interface SolidElementProperties
  extends BaseBackgroundElementProperties {
  type: 'Solid'
  color: string
}
export interface GradientElementProperties
  extends BaseBackgroundElementProperties {
  type: 'Gradient'
  from: string
  to: string
  direction: 'from top' | 'from bottom' | 'from right' | 'from left'
}
export interface WaveElementProperties extends BaseBackgroundElementProperties {
  type: 'Wave'
  layers: number
  waves: number
  variance: number
  color: string
  toColor?: string
}
export interface BlobElementProperties extends BaseBackgroundElementProperties {
  type: 'Blob'
  points: number
  color: string
  growth: number
  size: number
  backgroundColor?: string
}

export interface CommentElementProperties
  extends BaseBackgroundElementProperties {
  type: 'Comment'
  content: string
  authorUsername: string
}

export type BackgroundElementProperties =
  | SolidElementProperties
  | GradientElementProperties
  | WaveElementProperties
  | BlobElementProperties
  | CommentElementProperties

export interface BackgroundElementInterface extends MediaInterface {
  properties: BackgroundElementProperties
  defaultPosition: null
  /**
   * This stores the generated svg which will be drawn on canvas
   */
  svg: string
  /**
   * Used to generate the svg that will be drawn on canvas
   */
  generateSvg: () => void
  /**
   * Updates the properties and generates the new svg
   */
  updateProperties: (
    newProperties: BackgroundElementProperties,
    ignoreSideEffects?: boolean
  ) => void
  reDraw: () => void
  randomize: (_ignoreSideEffects?: boolean) => void
}

export interface TlDrawElementInterface extends MediaInterface {
  defaultPosition: null
  tlDrawShape: TDShape
  updateShape: (newShape: TDShape, ignoreSideEffects?: boolean) => void
}

export interface TextInterface extends MediaInterface {
  text: string
  defaultPosition: null
  style: {
    fontSize: number
    textColor: string
    fontFamily: string
    backgroundColor: string
    borderRadius: number
    fontWeight: number
    lineHeight: number
    textAlign: 'left' | 'center' | 'right'
    verticalAlign: 'top' | 'middle' | 'bottom'
  }
  updateStyle: (_styles: Partial<TextInterface['style']>) => void
  updateText: (_newText: string) => void
  reDrawText: () => void
  focusElement: () => void
  elements: [string]
  mediaElement: MediaElementInterface
}

export type Container = Widen<{
  trackType: TrackType
  index: number
}>

export type SelectedMediaType = {
  type: MediaType | null
  id: string | null
}

export type PreviewSelectedMedia = {
  type: MediaType | null
  id: string | null
}

export enum ThumbnailType {
  Image,
  Audio,
  Video
}

export type ThumbnailData =
  | { type: ThumbnailType.Video; image: string; svg?: string }
  | { type: ThumbnailType.Image; image: string }
  | { type: ThumbnailType.Audio; svg: string }
