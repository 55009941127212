import { observer } from 'mobx-react'
import React from 'react'

import { multiplayerStore } from '@editor/state/index'

const MultiplayerFollowing = ({ children }: { children: React.ReactNode }) => {
  const { followingUserId: followingUser } = multiplayerStore

  if (followingUser) {
    return (
      <div className="grid">
        {/* This is fine with a high z-index as it should be the top most thing when someone is following another user */}
        <div className="stackedLayer z-50 w-full h-full px-1 rounded-md">
          <div className="ring-2 ring-pink-500 rounded-md w-full h-full">
            <div className="flex items-start justify-end w-full h-full">
              <div className="bg-pink-500 px-6 rounded-sm text-gray-50">
                Following Test
              </div>
            </div>
          </div>
        </div>
        <div className="stackedLayer">{children}</div>
      </div>
    )
  } else {
    return <>{children}</>
  }
}

export default observer(MultiplayerFollowing)
