import { FileData, FileNode, FolderNode } from '@editor/interfaces'

export const makeFileNode = (file: FileData, id: string): FileNode => {
  return {
    label: file.name,
    id,
    data: file
  }
}

export const makeFolderNode = (
  label: string,
  id: string,
  children?: FolderNode['children']
): FolderNode => {
  return {
    label,
    id,
    children: children || []
  }
}

export const getFolderChildren = (
  folderNode: FolderNode,
  files: Record<string, FileNode>,
  folders: Record<string, FolderNode>
): Array<FileNode | FolderNode> => {
  const children = folderNode.children.map((id) =>
    files[id] ? files[id] : folders[id]
  )

  return children.filter((c) => c !== undefined) as Array<FileNode | FolderNode>
}
