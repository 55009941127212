/* This example requires Tailwind CSS v2.0+ */
import copy from 'copy-to-clipboard'
import { observer } from 'mobx-react'
import React, { Fragment, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import { Dialog, Transition } from '@headlessui/react'
import { LinkIcon, XIcon } from '@heroicons/react/outline'

import { useInviteLInk } from '../hooks/useInviteLink'

const MultiplayerShare = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const cancelButtonRef = useRef(null)

  const { link, handleCreateLink, handleDeleteLink } = useInviteLInk()

  return (
    <>
      <Toaster position="top-right"></Toaster>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          //   Z-30 is so that this can be above the playhead
          className="fixed z-30 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}>
          <div className="grid min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
            <div className="stackedLayer">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="w-full h-full bg-[#070A13] bg-opacity-70 transition-opacity" />
              </Transition.Child>
            </div>

            <div className="stackedLayer flex items-center justify-center pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="pointer-events-auto inline-block align-bottom bg-blueGray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center px-5 sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-3xl leading-6 text-left font-medium text-coolGray-50">
                        Invite
                      </Dialog.Title>
                      <div className="flex flex-col w-full py-4 space-y-2">
                        <div className="flex space-x-4">
                          <input
                            // value={username}
                            // onChange={(e) => {
                            //   setUsername(e.target.value)
                            // }}
                            type="text"
                            name="username"
                            disabled={true}
                            className="opacity-20 cursor-not-allowed focus:ring-indigo-500 text-gray-50 bg-transparent focus:outline-none focus:border-indigo-500 block w-full sm:text-base border-indigo-500 border-b-2"
                            placeholder="Search by username or email"
                          />
                          <button
                            disabled={true}
                            type="button"
                            className="opacity-20 cursor-not-allowed inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Invite
                          </button>
                        </div>
                        {/* <div>
                        <p className="text-left text-gray-50 text-lg">
                          0 multiplayers
                        </p>
                      </div> */}
                        <div className="bg-blueGray-900 w-full py-16 flex justify-center items-center">
                          <img
                            className="object-contain max-w-xs"
                            alt="friends online"
                            src="/images/friends.svg"></img>
                        </div>
                        <div className="py-2">
                          {!link ? (
                            <button
                              onClick={handleCreateLink}
                              type="button"
                              className="inline-flex text-center w-full justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              Generate a join link
                            </button>
                          ) : (
                            <div className="flex flex-col items-start space-y-2">
                              <p className="text-blueGray-400">
                                Invite friends with a join link
                              </p>
                              <div className="flex w-full space-x-4">
                                <p className="focus:ring-indigo-500 text-base py-1 text-left px-5 text-gray-50 bg-transparent focus:outline-none focus:border-indigo-500 block w-full sm:text-base border-indigo-500 border-1 rounded-md select-text border-opacity-50">
                                  {link}
                                </p>
                                <button
                                  className="focus:outline-none"
                                  onClick={() => {
                                    copy(link)
                                    toast.success('Copied to clipboard')
                                  }}>
                                  <LinkIcon className="w-6 h-6 text-indigo-500"></LinkIcon>
                                </button>
                                <button onClick={handleDeleteLink}>
                                  <XIcon className="w-6 h-6 text-red-500"></XIcon>
                                </button>
                              </div>
                              <p className="text-blueGray-400">
                                <button
                                  onClick={() => {
                                    handleDeleteLink()
                                    handleCreateLink()
                                  }}
                                  className="text-indigo-400">
                                  Generate a new link
                                </button>{' '}
                                (The current one will stop working)
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export default observer(MultiplayerShare)
