/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { enableMapSet } from 'immer'

import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'

import { multiplayerMiddleware } from './middleware/multiplayerMiddleware'
import { videoContextMiddleware } from './middleware/videoContextMiddleware'
import dragSlice from './slices/dragSlice'
import mediaSlice from './slices/mediaSlice'
import playbackSlice from './slices/playbackSlice'
import timelineSlice from './slices/timelineSlice'

enableMapSet()

const allReducers = combineReducers({
  playback: playbackSlice,
  timeline: timelineSlice,
  media: mediaSlice,
  drag: dragSlice
})

const customMiddleware = getDefaultMiddleware({
  serializableCheck: false
}).concat([videoContextMiddleware, multiplayerMiddleware])

const store = configureStore({
  reducer: allReducers,
  middleware: customMiddleware
})

export { store }

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof allReducers>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
