// import { makeObservable, observable } from 'mobx'

import { action, makeObservable, observable } from 'mobx'

import {
  MediaPlaybackState,
  MediaProps,
  MediaType,
  TlDrawElementInterface
} from '@editor/interfaces'
import { warnError } from '@editor/utils/warnError'
import {
  ColorStyle,
  DashStyle,
  SizeStyle,
  TDShape,
  TDShapeType
} from '@modfy/tldraw'

import { multiplayerStore } from '../../index'

import { StaticMedia } from './StaticMedia'

export type TLDrawElementProps = Omit<MediaProps, 'type'> & {
  _id: string
  tlDrawShape?: TDShape
}

export class TlDrawElement
  extends StaticMedia
  implements TlDrawElementInterface
{
  tlDrawShape: TDShape
  defaultPosition = null

  constructor({ _id, tlDrawShape, ...props }: TLDrawElementProps) {
    super({
      _id: _id,
      ...props,
      type: MediaType.tlDrawElement,
      mediaPlaybackState: MediaPlaybackState.playable
    })
    this.elements = [_id]
    this.tlDrawShape = tlDrawShape || {
      id: _id,
      type: TDShapeType.Rectangle,
      parentId: 'page', // will always be `page` (the default page name)
      name: 'Rectangle',
      childIndex: 1,
      point: [200, 200],
      size: [100, 100],
      style: {
        dash: DashStyle.Draw,
        size: SizeStyle.Medium,
        color: ColorStyle.Blue
      }
    }

    makeObservable(this, { tlDrawShape: observable, updateShape: action })
  }

  get source() {
    return ''
  }

  /**
   * Returns first media element if there are multiple
   */
  get mediaElement() {
    const elementId = this.elements[0]
    if (!elementId) return warnError('First element ID not defined')
    const element = this.getMediaElement(elementId)
    if (!element) return warnError('First element ID not defined')
    return element
  }

  private _handleSideEffects = (
    ignore?: boolean,
    options?: { skipUpdate?: boolean }
  ) => {
    const { skipUpdate } = options || {
      skipUpdate: false
    }
    if (ignore) return
    if (!skipUpdate) this._updateMultiplayer()
  }

  private _updateMultiplayer = () => {
    const { multiplayerInstance } = multiplayerStore
    multiplayerInstance?.setMedia({ ...this })
  }

  /**
   * Updates the tldraw shape
   */
  public updateShape = (newShape: TDShape, ignoreSideEffects?: boolean) => {
    this.tlDrawShape = newShape

    this._handleSideEffects(ignoreSideEffects)
  }
}
