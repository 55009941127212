// check if string contains only numbers with regex
export const isNumeric = (value: string) => {
  return /^\d+$/.test(value)
}

/**
 * Figma style update, where you can input numbers with or without a % sign
 * Also this will ignore any text inputed, but will prevent the ugly number up-down arrows
 */
export const handleInputTextToNumber = (value: string) => {
  let numericValue: number | null = null

  if (value.includes('%')) {
    const withoutPercent = value.split('%')[0]
    if (withoutPercent && isNumeric(withoutPercent)) {
      numericValue = parseFloat(withoutPercent)
    }
  } else {
    if (isNumeric(value)) {
      numericValue = parseFloat(value)
    }
  }

  return numericValue
}
