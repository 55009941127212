let __isSafari: boolean | undefined

/**
 * Returns if browser is Safari and memoizes result
 */
export const isSafari = () => {
  if (__isSafari === undefined) {
    if (typeof window !== 'undefined') {
      // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
      // because user-agent can be changed and also Chrome browsers on iOS include Safari in the user agent??!?
      __isSafari =
        // @ts-ignore
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]'
        })(
          // @ts-ignore
          !window.safari ||
            // @ts-ignore
            (typeof safari !== 'undefined' && window.safari.pushNotification)
        )
      return __isSafari
    }
    return false
  }
  return __isSafari
}
