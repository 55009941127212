import { ChannelData } from '@editor/interfaces'

/**
 * Utility function to create audio buffer from audio file url
 * @param url Url of the audio file
 * @returns audio buffer with decoded audio data
 */
export const getAudioBufferFromUrl = async (url: string) => {
  const audioContext = new AudioContext()
  const response = await fetch(url)
  const arrayBuffer = await response.arrayBuffer()
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

  return audioBuffer
}

/**
 * Defines how many samples we want to produce per second
 */
export const DEFAULT_SAMPLE_RATE = 125

/**
 * Returns an array of samples containing min and max for each sample
 */
export const getAudioPeaks = (
  audioBuffer: AudioBuffer,
  channels: Float32Array[],
  sampleRate: number
) => {
  const peaksCount = Math.floor(audioBuffer.duration * sampleRate)

  const peaks = [...Array(peaksCount)].map(() => ({ min: 0, max: 0 }))

  const chuckSize = audioBuffer.length / peaksCount
  const chunkStep = Math.floor(chuckSize / 10) || 1

  for (const channelData of channels) {
    for (let peakIdx = 0; peakIdx < peaksCount; peakIdx++) {
      // BANG: Very very low probability that min or max are not defined as 0th element
      let min = channelData[0]!
      let max = channelData[0]!

      const start = Math.floor(peakIdx * chuckSize)
      const end = Math.floor(start + chuckSize)
      for (
        let chunkItemIdx = start;
        chunkItemIdx < end;
        chunkItemIdx += chunkStep
      ) {
        const value = channelData[chunkItemIdx]
        if (value) {
          max = Math.max(value, max)
          min = Math.min(value, min)
        }
      }

      // BANG: Cannot be index out of bounds here, based on for loop
      peaks[peakIdx]!.max = Math.max(max, peaks[peakIdx]!.max)
      peaks[peakIdx]!.min = Math.min(min, peaks[peakIdx]!.min)
    }
  }

  return peaks
}

/**
 * Converts array of peaks into "d" attribute for path element
 */
const svgPath = (peaks: ChannelData[]) => {
  const totalPeaks = peaks.length

  let d = ''

  for (let peakNumber = 0; peakNumber < totalPeaks; peakNumber++) {
    // multiply by 50 to increase the size of peaks by a little
    // to make them fit in the height and look good
    // BANG: Cannot be index out of bounds here
    d += ` M${peakNumber}, ${peaks[peakNumber]!.max}`
    d += ` L${peakNumber}, ${peaks[peakNumber]!.min}`
  }

  return d
}

/**
 * Get the path to be used in an svg for waveform of a audio buffer
 */
export const getPathFromAudioBuffer = (
  audioBuffer: AudioBuffer,
  sampleRate: number = DEFAULT_SAMPLE_RATE
) => {
  const numberOfChannels = audioBuffer.numberOfChannels
  const channels: Float32Array[] = []

  for (
    let channelNumber = 0;
    channelNumber < numberOfChannels;
    channelNumber++
  ) {
    channels.push(audioBuffer.getChannelData(channelNumber))
  }

  const peaks = getAudioPeaks(audioBuffer, channels, sampleRate)

  return svgPath(peaks)
}
