import { action, computed, makeAutoObservable, observable } from 'mobx'

import {
  BackgroundElementInterface,
  Container,
  MediaCollisionMovement,
  MediaElementId,
  MediaElements,
  MediaInterface,
  MediaPosition,
  MediaState,
  MediaTrack,
  MediaType,
  MultiplayerActions,
  MultiplayerActionType,
  SyncedMediaPlaybackState,
  TextInterface,
  TrackType
} from '@editor/interfaces'
import { getTrackFromMediaElementId } from '@editor/state/media/utils'
import { getRealEnd } from '@editor/utils'

import { createMediaElement, isPlayable } from '../media'
import { executeMultiplayerActions } from '../multiplayer'
import {
  addMediaElement,
  dispatchNoMultiplayer,
  removeMediaElement,
  store,
  unSelectPreviewMedia
} from '../redux'
import { multiplayerActions } from '../redux/middleware/multiplayerMiddleware'

import {
  applyCollisions,
  cleanUpDeadTracks,
  reComputePrimeStartSeconds
} from './helpers/mediaCollisions'
import settingStore from './settingsStore'
import { thumbnailStore } from '.'

class MediaStore {
  constructor() {
    makeAutoObservable(this)

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.mediaStore = this
    }
  }

  @observable medias: MediaState = {}

  @observable tracks: MediaTrack = {
    prime: [],
    above: [[]],
    below: [[]]
  }

  @action updateSingleTrack = (
    _newTrack: string[],
    _type: TrackType,
    _number: number
  ) => {
    // NOTE: This isn't being called anywhere right now
    // so this is a noop for now should be cleaned later
  }

  @action handleMultiplayerTrackUpdate = (
    updatedTracks: string[] | string[][],
    type: TrackType
  ) => {
    if (type === 'prime') {
      this.tracks.prime = updatedTracks as string[]
    } else {
      this.tracks[type] = updatedTracks as string[][]
    }
  }

  @action handleMultiplayerMediaAdd(media: MediaInterface) {
    this.medias[media._id] = media
    if (isPlayable(media.mediaPlaybackState)) {
      thumbnailStore.generateThumbnail(media._id)
    }
  }

  @action handleMultiplayerMediaUpdate(
    id: string,
    media: Partial<MediaInterface>
  ) {
    Object.keys(media).forEach((k) => {
      const key = k as keyof MediaInterface
      const value = media[key]

      if (key === 'syncedMediaPlaybackState') {
        if (value !== this.medias[id]?.syncedMediaPlaybackState)
          this.medias[id]?.updateSyncedMediaPlaybackState(
            value as SyncedMediaPlaybackState
          )
        // mediaPlaybackState is ignored across multiplayer
      } else if (key !== 'mediaPlaybackState' && value && this.medias[id]) {
        // BANG: Checked in if
        Object.assign(this.medias[id]!, { [key]: value })
      }
    })

    // Object.assign(this.medias[id], { ...media })
    if (media.type === MediaType.text) {
      ;(this.medias[id] as TextInterface).reDrawText()
    }
    if (media.type === MediaType.backgroundElement) {
      ;(this.medias[id] as BackgroundElementInterface).reDraw()
    }
  }

  @action handleMultiplayerMediaRemove(mediaId: string) {
    delete this.medias[mediaId]
  }

  /**
   * Static media files (image, text) will have 5s of duration by default
   */
  @action addStaticMedia = (
    newMedia: MediaInterface,
    opts?: {
      position?: Partial<MediaPosition>
      container?: Container
      startOnTimeline?: number
      duration?: number
    }
  ) => {
    const { position, container, startOnTimeline, duration } = opts || {}
    const { width, height } = settingStore.settings.dimensions

    const defaultPosition = {
      x: 0,
      y: 0,
      width: width,
      height: height,
      scale: 0
    }
    return this.addMedia(newMedia, {
      usableDuration: Infinity,
      position: { ...defaultPosition, ...position },
      container,
      startOnTimeline: startOnTimeline,
      duration: duration
    })
  }

  /**
   * Similar to removeMedia but does not delete the underlying media
   * Steps
   * 0. Unselect all media
   * 2. Delete Element from track
   * 3. Recompute prime
   * 4. Pass changes to multiplayer
   * 5. Delete MediaElement
   */
  @action removeMediaElement = async (id: string) => {
    const dummyPromise = new Promise<Boolean>((resolve) => {
      store.dispatch(unSelectPreviewMedia())
      const container = getTrackFromMediaElementId(id)

      if (container) {
        const { trackType, index } = container
        if (trackType === 'prime') {
          const indexOf = this.tracks.prime.indexOf(id)
          if (indexOf !== -1) {
            this.tracks.prime.splice(indexOf, 1)
            reComputePrimeStartSeconds(this.tracks.prime)
          }
        } else {
          const indexOf = this.tracks[trackType][index]?.indexOf(id)
          if (indexOf !== undefined && indexOf !== -1) {
            this.tracks[trackType][index]?.splice(indexOf, 1)
          }
        }
      }

      this.tracks = cleanUpDeadTracks(this.tracks)

      executeMultiplayerActions([
        {
          type: MultiplayerActions.UpdateTracks,
          payload: this.tracks
        }
      ])
      resolve(true)
    })
    /**
     * Remove Media Element has to come only once everything else has been removed otherwise will cause errors
     */
    const _deleted = await dummyPromise

    store.dispatch(removeMediaElement({ id }))
  }

  /**
   * Steps
   * 0. Unselect all media
   * 1. Delete Elements from track
   * 2. Delete Media
   * 2a. Recompute prime
   * 3. Pass changes to multiplayer
   * 4. Delete MediaElements
   * @param mediaId - The media id
   *
   *
   * TODO: Recompute duration and trigger reload of texture on videoContext
   */
  @action removeMedia = async (mediaId: string) => {
    if (!this.medias[mediaId]) return

    const elements = this.medias[mediaId]?.elements

    if (!elements) {
      console.warn('[Warn] not could find elements for mediaId')
      return
    }

    const dummyPromise = new Promise<Boolean>((resolve) => {
      store.dispatch(unSelectPreviewMedia())

      for (const element of elements) {
        const container = getTrackFromMediaElementId(element)

        if (container) {
          const { trackType, index } = container
          if (trackType === 'prime') {
            const indexOf = this.tracks.prime.indexOf(element)
            if (indexOf !== -1) {
              this.tracks.prime.splice(indexOf, 1)
              reComputePrimeStartSeconds(this.tracks.prime)
            }
          } else {
            const indexOf = this.tracks[trackType][index]?.indexOf(element)
            if (indexOf !== undefined && indexOf !== -1) {
              this.tracks[trackType][index]?.splice(indexOf, 1)
            }
          }
        }
      }

      this.tracks = cleanUpDeadTracks(this.tracks)

      // Not sure why we had toJS here but seems to be cause an error
      // Should make sure this works in the editor when merged
      const newMedias = this.medias
      delete newMedias[mediaId]
      this.medias = newMedias

      executeMultiplayerActions([
        {
          type: MultiplayerActions.UpdateTracks,
          payload: this.tracks
        },
        { type: MultiplayerActions.DeleteMedia, payload: mediaId }
      ])
      resolve(true)
    })
    /**
     * Remove Media Element has to come only once everything else has been removed otherwise will cause errors
     */
    const _deleted = await dummyPromise

    for (const element of elements) {
      store.dispatch(removeMediaElement({ id: element }))
    }
  }

  @action addMedia = (
    newMedia: MediaInterface,
    opts?: {
      usableDuration?: number
      position?: MediaPosition
      container?: Container
      startOnTimeline?: number
      duration?: number
    }
  ) => {
    const {
      usableDuration,
      position,
      container,
      startOnTimeline: overwriteStartOnTimeline,
      duration: overwriteDuration
    } = opts || {}

    // Reset multiplayer actions
    multiplayerActions.length = 0

    // ALL on prime track for now
    const mediaTrack = this.tracks.prime

    const mediaElements = store.getState().media.mediaElements

    // Might change when later with Magnetic timeline
    const startOnTimeline =
      overwriteStartOnTimeline ??
      mediaTrack
        .map((key) => getRealEnd(mediaElements[key]))
        .reduce((acc, curr) => Math.max(acc, curr), 0)

    // if container is passed get isPrime from it or else set true
    const isPrime = container ? container.trackType === 'prime' : true

    const mediaElemProps = newMedia.getMediaElementProps()

    // use the media element passed in props or create a new one
    const newMediaElement = createMediaElement(
      {
        ...mediaElemProps,
        usableDuration: usableDuration || mediaElemProps.usableDuration,
        startOnTimeline: startOnTimeline,
        isPrime,
        position: position || mediaElemProps.position
      },
      newMedia.isStatic
    )

    // When a static media is added
    // it's media element has the same id as media id
    // and the duration by default is to be set to 5 sec
    if (newMedia.isStatic) {
      newMediaElement.id = newMedia._id as MediaElementId
      newMediaElement.duration = 5
    }

    if (overwriteDuration) {
      newMediaElement.duration = overwriteDuration
    }

    newMedia.addElement(newMediaElement.id)

    // Dispatch to redux store
    dispatchNoMultiplayer(addMediaElement(newMediaElement))

    this.medias[newMedia._id] = newMedia

    /**
     * Assign element to a different track
     */
    if (container) {
      const { trackType, index } = container
      if (trackType !== 'prime') {
        if (index < this.tracks[trackType].length) {
          this.tracks[trackType][index]?.push(newMediaElement.id)
        } else {
          this.tracks[trackType].push([newMediaElement.id])
        }
      } else {
        this.tracks.prime.push(newMediaElement.id)
      }
    } else {
      this.tracks.prime.push(newMediaElement.id)
    }

    if (isPlayable(newMedia.mediaPlaybackState)) {
      thumbnailStore.generateThumbnail(newMedia._id)
    }

    executeMultiplayerActions([
      {
        type: MultiplayerActions.AddMedia,
        payload: newMedia
      },
      ...multiplayerActions,
      {
        type: MultiplayerActions.UpdateTracks,
        payload: this.tracks
      }
    ])

    return newMediaElement
  }

  @computed mediaObject = <T extends MediaInterface, K extends boolean = false>(
    id: string,
    returnUndefined?: K
  ): K extends true ? T | undefined : T => {
    if (!returnUndefined && !this.medias[id])
      throw new Error('Could not find media id')
    return this.medias[id] as T
  }

  @action applyCollisionsToTrack = (
    mediaElements: MediaElements,
    currentMediaId: string,
    currentContainer: Container,
    collidingMediaMovement: MediaCollisionMovement = 'default'
  ) => {
    const mediaElement = mediaElements[currentMediaId]

    if (!mediaElement) return

    this.tracks = applyCollisions(
      mediaElement,
      mediaElements,
      this.tracks,
      currentContainer,
      collidingMediaMovement
    )
    executeMultiplayerActions([
      {
        type: MultiplayerActions.UpdateTracks,
        payload: this.tracks
      }
    ])
  }

  /**
   * This will update tracks and apply cleanUpDeadTracks, reComputePrimeStartSeconds
   * If mutatedTracks is not passed in, the current tracks will be used
   * NOTE: This should be the only way of updating tracks
   */
  @action updateTracks = (
    mutatedTracks?: MediaTrack,
    noMultiplayer?: boolean
  ): MultiplayerActionType | undefined => {
    const tracksToProcess = mutatedTracks || this.tracks
    this.tracks = cleanUpDeadTracks(tracksToProcess)
    reComputePrimeStartSeconds(this.tracks.prime)

    if (noMultiplayer) {
      return {
        type: MultiplayerActions.UpdateTracks,
        payload: this.tracks
      }
    } else {
      executeMultiplayerActions([
        {
          type: MultiplayerActions.UpdateTracks,
          payload: this.tracks
        }
      ])
    }
  }

  @action sortTrack = (arr: string[], overContainer: Container) => {
    const { trackType, index } = overContainer

    if (trackType === 'prime') {
      this.tracks.prime = arr
      reComputePrimeStartSeconds(this.tracks.prime)
    } else {
      this.tracks[trackType][index] = arr
    }
    executeMultiplayerActions([
      {
        type: MultiplayerActions.UpdateTracks,
        payload: this.tracks
      }
    ])
  }

  @computed trackIdList = () => {
    const { tracks } = this

    return `prime-${tracks.prime.toString()}above-${tracks.above.toString()}below-${tracks.below.toString()}`
  }
}

const mediaStore = new MediaStore()

export default mediaStore
