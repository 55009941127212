import { useState } from 'react'

import { notNull } from '@editor/graphql/util'
import {
  useCreateLinkMutation,
  useDeleteLinksMutation,
  useGetLinkQuery
} from '@graphql/schema/client'

import { multiplayerStore, projectStore } from '../../mobx'

/**
 * Note:
 *
 * The projectId is said in the graphQL headers which is how
 * we are able to have all the mutations and queries here without any variables
 */
export const useInviteLInk = () => {
  const [link, setLink] = useState('')

  useGetLinkQuery({
    skip: !projectStore.project,
    onCompleted: (data) => {
      if (!data.getLink) return
      const { slug } = data.getLink
      const newLink = `${window.location.origin}/join/${slug}`
      console.log('link loaded', newLink)
      setLink(newLink)
    }
  })
  const [createLinkMutation] = useCreateLinkMutation()
  const [deleteLinkMutation] = useDeleteLinksMutation()

  const handleCreateLink = async () => {
    const { data } = await createLinkMutation()
    const { slug } = notNull(data?.createLink)

    const link = `${window.location.origin}/join/${slug}`
    setLink(link)
    multiplayerStore.switchProjectToMultiplayer()

    return link
  }

  const handleDeleteLink = async () => {
    setLink('')

    await deleteLinkMutation()
  }

  return { link, handleCreateLink, handleDeleteLink }
}
