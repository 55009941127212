import { action, makeAutoObservable, observable } from 'mobx'

import { TimelineMode } from '@editor/interfaces'
import { isProd } from '@editor/utils'

import { store, unSelectPreviewMedia } from '../redux'

// eslint-disable-next-line no-unused-vars
const timelineCursors: { [key in TimelineMode]: string } = {
  default: 'unset',
  cut: `url('/CutIcon.svg') 18 36, auto`,
  text: 'unset',
  backgroundElement: 'unset',
  timeWarp: 'unset'
}

class ModeStore {
  constructor() {
    makeAutoObservable(this)
  }

  @observable isAnno: boolean = false

  @observable showFilePicker: boolean = false

  @observable timelineMode: TimelineMode = TimelineMode.default

  @observable cursor: string = timelineCursors.default

  @observable showAudioLevels = false

  @observable beforeUnload = true

  @observable reUploadMediaModal = false

  @observable mute = false

  @observable volume = 100

  @observable devConsole = !isProd

  @observable toggleMute = () => (this.mute = !this.mute)

  @observable setVolume = (newVolume: number) => (this.volume = newVolume)

  @observable isDefaultTimelineMode: boolean =
    this.timelineMode === TimelineMode.default

  @action setIsAnno = (value: boolean) => {
    this.isAnno = value
  }

  @action setTimelineMode = (newMode: TimelineMode) => {
    this.timelineMode = newMode
    this.cursor = timelineCursors[this.timelineMode]
  }

  @action setBeforeUnload = (state: boolean) => {
    this.beforeUnload = state
  }

  @action toggleMode = (
    desiredMode: TimelineMode,
    defaultMode = TimelineMode.default
  ) => {
    this.timelineMode =
      this.timelineMode === desiredMode ? defaultMode : desiredMode
    // alert(`Toggling modes to ${this.timelineMode}`)
    this.cursor = timelineCursors[this.timelineMode]

    this.isDefaultTimelineMode = this.timelineMode === TimelineMode.default
  }

  @action toggleFilePicker = () => {
    const state = !this.showFilePicker
    if (state) {
      store.dispatch(unSelectPreviewMedia())
    }
    this.showFilePicker = state
  }

  @action setFilePicker = (state: boolean) => {
    if (state) {
      store.dispatch(unSelectPreviewMedia())
    }
    this.showFilePicker = state
  }

  @action setAudioLevels = (value: boolean) => {
    this.showAudioLevels = value
  }

  @action setReUploadMediaModal = (value: boolean) => {
    this.reUploadMediaModal = value
  }

  @action setDevConsole = (value: boolean) => {
    this.devConsole = value
  }

  @action toggleDevConsole = () => {
    this.devConsole = !this.devConsole
  }
}

export default new ModeStore()
