import {
  FileNode,
  FolderNode,
  MediaElementInterface,
  MediaInterface,
  MediaTrack,
  PanelMap,
  SelectedMediaType
} from '@editor/interfaces'
import { hasUpdatedKeys, warnError } from '@editor/utils'

import { fileStore } from '../../mobx'
import MultiplayerManager from '../MultiplayerManager'

/**
 * Wrapper function to execute cut across multiplayer
 */
export const executeCut = (
  multiplayerInstance: MultiplayerManager,
  medias: MediaInterface[],
  tracks: MediaTrack
) => {
  multiplayerInstance.yDoc.transact(() => {
    medias.forEach((media) => {
      multiplayerInstance.mediaState.set(media._id, { ...media })
    })

    multiplayerInstance.updateTrack('prime', tracks.prime)
    multiplayerInstance.updateTrack('above', tracks.above)
    multiplayerInstance.updateTrack('below', tracks.below)
  })
}

/**
 * Update media across multiplayer
 *
 * It check if there is any old media and updates the state accordingly
 */
export const setMedia = (
  multiplayerInstance: MultiplayerManager,
  media: MediaInterface
) => {
  const oldMedia = multiplayerInstance.mediaState.get(media._id)
  if (!oldMedia) {
    multiplayerInstance.mediaState.set(media._id, { ...media })
  } else {
    multiplayerInstance.mediaState.set(media._id, {
      ...oldMedia,
      ...media
    })
  }
}

/**
 * Checks if media exists and deletes it if it exists
 */
export const removeMedia = (
  multiplayerInstance: MultiplayerManager,
  id: string
) => {
  const exists = multiplayerInstance.mediaState.get(id)
  if (exists) {
    multiplayerInstance.mediaState.delete(id)
  }
}

/**
 * Update mediaElement across multiplayer
 *
 * It check if there is any old mediaElement and updates the state accordingly
 */
export const setMediaElement = (
  multiplayerInstance: MultiplayerManager,
  mediaElement: MediaElementInterface
) => {
  const oldMediaElement = multiplayerInstance.mediaElements.get(mediaElement.id)
  if (!oldMediaElement) {
    multiplayerInstance.mediaElements.set(mediaElement.id, mediaElement)
  } else {
    const hasChanged = hasUpdatedKeys(oldMediaElement, mediaElement)
    if (hasChanged) {
      multiplayerInstance.mediaElements.set(mediaElement.id, {
        ...oldMediaElement,
        ...mediaElement
      })
    }
  }
}

export const removeMediaElement = (
  multiplayerInstance: MultiplayerManager,
  id: string
) => {
  const exists = multiplayerInstance.mediaElements.get(id)
  if (exists) {
    multiplayerInstance.mediaElements.delete(id)
  }
}

export const setFile = (
  multiplayerInstance: MultiplayerManager,
  file: FileNode
) => {
  const oldFile = multiplayerInstance.files.get(file.id)
  if (!oldFile) {
    multiplayerInstance.files.set(file.id, file)
  } else {
    const hasChanged = hasUpdatedKeys(oldFile, file)
    if (hasChanged) {
      multiplayerInstance.files.set(file.id, {
        ...oldFile,
        ...file
      })
    }
  }
}

export const removeFile = (
  multiplayerInstance: MultiplayerManager,
  fileId: string
) => {
  const exists = multiplayerInstance.files.get(fileId)
  if (exists) {
    multiplayerInstance.files.delete(fileId)
  }

  // If the user has the file as a file selection we
  // need to clear it and cascade all subsequent selections
  const idx = fileStore.fileSelections.indexOf(fileId)
  if (idx !== -1) fileStore.setFileSelection(idx, undefined)
}

export const setFolder = (
  multiplayerInstance: MultiplayerManager,
  folder: FolderNode
) => {
  const oldFolder = multiplayerInstance.folders.get(folder.id)
  if (!oldFolder) {
    multiplayerInstance.folders.set(folder.id, folder)
  } else {
    const hasChanged = hasUpdatedKeys(oldFolder, folder)
    if (hasChanged) {
      multiplayerInstance.folders.set(folder.id, {
        ...oldFolder,
        ...folder
      })
    }
  }
}
export const removeFolder = (
  multiplayerInstance: MultiplayerManager,
  folderId: string
) => {
  const exists = multiplayerInstance.folders.get(folderId)
  if (exists) {
    multiplayerInstance.folders.delete(folderId)
  }

  // If the user has the folder as a panel selection we
  // need to clear it and cascade all subsequent selections
  const idx = fileStore.panelSelections.indexOf(folderId)
  if (idx !== -1) fileStore.setPanelSelection(idx, undefined)
}

export const updateFolders = (
  multiplayerInstance: MultiplayerManager,
  panels: PanelMap
) => {
  Object.keys(panels).forEach((k) => {
    const key = k.slice(10)
    const folder = fileStore.folders[key]

    if (!folder) {
      warnError('Unable to updateFolders as folder is undefined')
      return
    }

    setFolder(multiplayerInstance, {
      ...folder,
      children: [...folder.children]
    })
  })
}

export const setSelectedMedia = (
  multiplayerInstance: MultiplayerManager,
  selectedMedia: SelectedMediaType
) => {
  multiplayerInstance.setUserState({ selectedMedia })
}
