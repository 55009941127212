import {
  Container,
  MediaCollisionMovement,
  MediaElementInterface,
  MediaElements,
  MediaTrack
} from '@editor/interfaces'
import { getTrackFromContainer } from '@editor/state/media/utils'
import {
  checkMediaElementExists,
  collidesPure,
  getRealStartAndEnd
} from '@editor/utils'

import { move, store } from '../../index'

/***
 * Removes all empty tracks from the tracks object
 */
export const cleanUpDeadTracks = (tracks: MediaTrack) => {
  const mutatedTracks = { ...tracks }

  mutatedTracks.above =
    mutatedTracks.above.length > 0
      ? mutatedTracks.above.filter((arr) => arr.length > 0)
      : mutatedTracks.above
  mutatedTracks.below =
    mutatedTracks.below.length > 0
      ? mutatedTracks.below.filter((arr) => arr.length > 0)
      : mutatedTracks.below

  mutatedTracks.above.push([])
  mutatedTracks.below.push([])

  return mutatedTracks
}

/**
 * Return sorted current track based on real start
 */
const sortCurrentTrack = (
  tracks: MediaTrack,
  mediaElements: MediaElements,
  container: Container
) => {
  const currentTrack = getTrackFromContainer(container)

  return currentTrack.sort(
    (a, b) =>
      getRealStartAndEnd(checkMediaElementExists(mediaElements, a)).realStart -
      getRealStartAndEnd(checkMediaElementExists(mediaElements, b)).realStart
  )
}

/**
 * Three types of tracks
 *
 *  prime - sortable. No spaces in between. (100% sorted array)
 *  above - [a1,a2,a3,a4] - These sub array are not sortable. There can be space between. (Not sure if these are sorted)
 *  below - [b1,b2,b3,b4]
 *
 *  prime - simple flex box, simple row. So the sorting matters to both UI and to Dnd Sortable
 *  a1 - Using grid as substitute for absolutes, so elements inside a1 are all absolute and the left position based on start time.
 */

/**
 * Detects the collisions in a track, and moves out the colliding media to a new track
 * Returns the new current track and the new track that should be made
 */
const detectCollisionInTrack = (
  current: MediaElementInterface,
  currentTrackMedias: MediaElementInterface[],
  tracks: MediaTrack,
  container: Container
) => {
  const { trackType } = container

  if (trackType === 'prime') {
    return { newCurrentTrack: tracks.prime, newTrack: [] }
  }

  const { realStart: curStart, realEnd: curEnd } = getRealStartAndEnd(current)

  let newTrack: string[] = []
  const newCurrentTrack: string[] = []
  for (let i = 0; i < currentTrackMedias.length; i++) {
    const element = currentTrackMedias[i]
    if (!element || !element.display) continue

    const { realStart, realEnd } = getRealStartAndEnd(element)

    if (collidesPure(realStart, realEnd, curStart, curEnd)) {
      if (element.id === current.id) {
        newCurrentTrack.push(element.id)
        continue
      }

      // remove m from the current Track
      // push m onto the new track
      newTrack = [...newTrack, element.id]
    } else {
      newCurrentTrack.push(element.id)
    }
  }

  return { newCurrentTrack, newTrack }
}

/**
 * Detect when one video is overlapping another video and then push (array push here, will create a new empty track with that) the other video to a new track
 * No recursive collisions, if one video collides then it just pushes the video to a whole new track
 * No gravity, videos don't fall down automatically. Only  empty tracks are removed using cleanUpDeadTracks
 *
 * On upward push, there has to be some force threshold before moving up. (I am not sure this should be tackled within this function)
 */
const detectCollisionAndMove = (
  current: MediaElementInterface,
  currentTrackMedias: MediaElementInterface[],
  tracks: MediaTrack,
  container: Container
): MediaTrack => {
  const { trackType, index } = container

  if (trackType === 'prime') return tracks

  let newAboveOrBelowTracks: string[][] = tracks[trackType]

  const { newCurrentTrack, newTrack } = detectCollisionInTrack(
    current,
    currentTrackMedias,
    tracks,
    container
  )

  if (newTrack.length > 0) {
    newAboveOrBelowTracks = [
      ...newAboveOrBelowTracks.slice(0, index), // all before the current track
      newCurrentTrack, // Current track
      newTrack, // inserted track
      ...newAboveOrBelowTracks.slice(index + 1) // after current track
    ]
  }

  return {
    ...tracks,
    ...(trackType === 'above'
      ? { above: newAboveOrBelowTracks }
      : { below: newAboveOrBelowTracks })
  }
}

/**
 * Detects when medias are colliding and moves the colliding medias downwards
 * A new track is created below the current track and colliding medias are added in it
 * Related {@link detectCollisionAndMove}
 */
const detectCollisionAndMoveDown = (
  current: MediaElementInterface,
  currentTrackMedias: MediaElementInterface[],
  tracks: MediaTrack,
  container: Container
): MediaTrack => {
  const { trackType, index } = container

  if (trackType === 'prime') return tracks

  let newAboveOrBelowTracks: string[][] = tracks[trackType]

  const { newCurrentTrack, newTrack } = detectCollisionInTrack(
    current,
    currentTrackMedias,
    tracks,
    container
  )

  if (newTrack.length > 0) {
    // In above track the new track is created before the current track
    // (so in reality the new track is actually below the current track)
    if (trackType === 'above') {
      newAboveOrBelowTracks = [
        ...newAboveOrBelowTracks.slice(0, index), // all before the current track
        newTrack, // inserted track
        newCurrentTrack, // Current track
        ...newAboveOrBelowTracks.slice(index + 1) // after current track
      ]
    }
    // In below tracks new track is created after the current track
    else if (trackType === 'below') {
      newAboveOrBelowTracks = [
        ...newAboveOrBelowTracks.slice(0, index), // all before the current track
        newCurrentTrack, // Current track
        newTrack, // inserted track
        ...newAboveOrBelowTracks.slice(index + 1) // after current track
      ]
    }
  }

  return {
    ...tracks,
    ...(trackType === 'above'
      ? { above: newAboveOrBelowTracks }
      : { below: newAboveOrBelowTracks })
  }
}

/**
 * Returns a new track object with all collisions applied
 */
export const applyCollisions = (
  currentMediaElem: MediaElementInterface,
  mediaElements: MediaElements,
  tracks: MediaTrack,
  container: Container,
  collidingMediaMovement: MediaCollisionMovement
) => {
  const sortedTrack = sortCurrentTrack(tracks, mediaElements, container)

  // All the media elements on a single track
  const currentTrackMedias = sortedTrack.map((id) =>
    checkMediaElementExists(mediaElements, id)
  )

  let collided: MediaTrack

  if (collidingMediaMovement === 'force-down') {
    collided = detectCollisionAndMoveDown(
      currentMediaElem,
      currentTrackMedias,
      tracks,
      container
    )
  } else {
    collided = detectCollisionAndMove(
      currentMediaElem,
      currentTrackMedias,
      tracks,
      container
    )
  }

  const cleanedTracks = cleanUpDeadTracks(collided)

  return cleanedTracks
}

/**
 * Recomputes prime track's start seconds after sort
 */
export const reComputePrimeStartSeconds = (track: string[]) => {
  let startOnTimeline = 0

  const mediaElements = store.getState().media.mediaElements

  for (const elm of track) {
    const mediaElement = mediaElements[elm]
    if (!mediaElement || !mediaElement.display) continue

    // using move to just quickly update start seconds
    store.dispatch(move({ id: elm, startOnTimeline: startOnTimeline }))

    startOnTimeline += mediaElement.duration
  }
}
