import { nanoid } from 'packages/state/node_modules/nanoid'

import { MediaPlaybackState, MediaProps, MediaType } from '@editor/interfaces'

import { StaticMedia } from './StaticMedia'

/**
 * Dummy Class just to keep the editor consistent
 */
export class CommentClass extends StaticMedia {
  text: string = ''

  constructor(
    props: Omit<MediaProps, 'type' | '_id'> & { _id?: string; text: string }
  ) {
    super({ ...props, type: MediaType.comments, _id: props._id || nanoid() })
    this.text = props.text
    this.mediaPlaybackState = MediaPlaybackState.playable
  }

  /**
   * A comment doesn't have any source so override this
   */
  get source() {
    return ''
  }
}
