import axios from 'axios'

export const DISCORD_BUG_WEBHOOK_URL =
  'https://discord.com/api/webhooks/991409457173119017/ihmM9jvKnoZxv_GwhIBWr7OmqcFe5p8bVYN4eonnE9vx9vy2TkvQG_rXcWNvb-rUuAsp'

/**
 * Will ping the discord channel #bug with a message
 *
 * Should use this for critical bugs that team should resolve instantly
 * @param message
 */
export const bugHook = async (message: string) => {
  await axios.post(`${DISCORD_BUG_WEBHOOK_URL}`, {
    content: message
  })
}
