import React from "react";
const ErrorPage = ({
  referrer,
  errorMsg,
  detailedError,
  resetBoundary
}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "bg-blueGray-900 min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "mx-auto w-full max-w-6xl"
  }, /* @__PURE__ */ React.createElement("main", {
    className: "flex flex-col md:flex-row items-center"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "h-72 mb-12",
    src: "/images/cat_error.svg",
    alt: "Cat throwing a pot off the table"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "sm:ml-12 w-3/4"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "text-4xl font-extrabold text-gray-50 tracking-tight sm:text-5xl"
  }, "An Error has occurred"), /* @__PURE__ */ React.createElement("p", {
    className: "mt-1 text-base text-gray-400"
  }, errorMsg || ""), !!detailedError && /* @__PURE__ */ React.createElement("details", null, /* @__PURE__ */ React.createElement("summary", {
    className: "cursor-pointer text-gray-400"
  }, "Show detailed error message"), /* @__PURE__ */ React.createElement("div", {
    className: "p-2 text-white bg-gray-800 max-w-full max-h-[50vh] overflow-auto scrollbar rounded-md"
  }, /* @__PURE__ */ React.createElement("code", null, detailedError)))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-10 flex space-x-3 sm:border-l sm:border-transparent"
  }, /* @__PURE__ */ React.createElement("a", {
    href: referrer || "/home",
    className: "inline-flex items-center px-4 py-2 border border-transparent text-center text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
    onClick: () => resetBoundary && resetBoundary()
  }, referrer ? "Go back" : "Go back home"), /* @__PURE__ */ React.createElement("a", {
    href: "mailto:support@modfy.video",
    className: "inline-flex items-center px-4 py-2 border border-transparent text-sm text-center font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  }, "Contact support"))))));
};
export default ErrorPage;
