import {
  MediaElementId,
  MediaElementInterface,
  MediaElements,
  MediaPosition,
  moveType,
  PreviewSelectedMedia,
  setEndpointsType,
  setPlaybackRateType,
  TransitoryDuration,
  updatePositionType,
  updatePositionXY
} from '@editor/interfaces'
import { getContainer } from '@editor/state/media/utils'
import { warnError } from '@editor/utils'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { mediaStore } from '../../mobx/'
import { getTransformedValue } from '../helpers/getTransformValue'
import { useAppDispatch, useAppSelector } from '../hook'

export type MediaSliceState = {
  previewSelectedMedia: PreviewSelectedMedia
  mediaElements: MediaElements
  timelineSelectedElements: Set<MediaElementId>
}

const handleEndpointsChange = (
  state: MediaSliceState,
  payload: setEndpointsType
) => {
  const { id, value, point } = payload

  // BANG: Checked at the top of the function
  const { start } = state.mediaElements[id]!
  const transformedValue = getTransformedValue(
    id,
    state.mediaElements,
    point,
    value
  )

  const delta = transformedValue - start

  return { transformedValue, delta }
}

const initialState: MediaSliceState = {
  mediaElements: {},
  previewSelectedMedia: {
    type: null,
    id: null
  },
  timelineSelectedElements: new Set<MediaElementId>()
}

const mediaSlice = createSlice({
  name: 'mediaElements',
  initialState,
  reducers: {
    addMediaElement: (state, action: PayloadAction<MediaElementInterface>) => {
      const { payload } = action
      state.mediaElements[payload.id] = payload
    },
    removeMediaElement: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      delete state.mediaElements[id]
    },
    updateMediaElement: (
      state,
      action: PayloadAction<{
        id: string
        mediaElement: Partial<MediaElementInterface>
      }>
    ) => {
      const { id, mediaElement } = action.payload

      const currentState = state.mediaElements[id]
      if (!currentState) {
        warnError('cannot updateMediaElement as it is undefined')
        return
      }

      state.mediaElements[id] = {
        ...currentState,
        ...mediaElement
      }
    },
    /**
     * Action to handle updating the endPoints
     *
     * Latest ERD: https://www.notion.so/modfy/End-Time-of-Videos-10d7836415b745cfb5a07b942883c75b
     */
    setEndpoints: (state, action: PayloadAction<setEndpointsType>) => {
      const { point, value, id } = action.payload

      const currentState = state.mediaElements[id]
      if (!currentState) {
        warnError('cannot setEndpoints as mediaElement is undefined')
        return
      }

      if (point === 'usableDuration') {
        // BANG: Checked at the top of the function
        state.mediaElements[id]!.usableDuration = value
      }

      // BANG: Checked at the top of the function
      const { isPrime } = state.mediaElements[id]!

      const { transformedValue, delta } = handleEndpointsChange(
        state,
        action.payload
      )

      if (point === 'start') {
        if (!isPrime) {
          // Move the start seconds when the start changes
          // BANG: Checked at the top of the function
          state.mediaElements[id]!.startOnTimeline += delta
        }

        // Change the duration with the inverse of delta
        // if start increases, duration decreases and vice versa
        // More in ERD attached above
        // BANG: Checked at the top of the function
        state.mediaElements[id]!.duration -= delta

        // BANG: Checked at the top of the function
        state.mediaElements[id]!.start = transformedValue
      } else {
        // set duration
        // BANG: Checked at the top of the function
        state.mediaElements[id]!.duration = transformedValue
      }

      state.mediaElements[id]!.transitoryDuration = state.mediaElements[id]!
        .duration as TransitoryDuration
    },
    setPlaybackRate: (state, action: PayloadAction<setPlaybackRateType>) => {
      // The value here is the exact same as passed to setEndpoints
      const { id, value, point } = action.payload

      console.log('setPlaybackRate', {
        id,
        value,
        point
      })

      const dummyMediaElement = state.mediaElements[id]

      if (!dummyMediaElement) {
        warnError('cannot setPlaybackState as mediaElement is undefined')
        return
      }

      // BANG: Checked at the top of the function
      const { isPrime, duration, transitoryDuration } = state.mediaElements[id]!

      const { transformedValue, delta } = handleEndpointsChange(
        state,
        action.payload
      )

      // const transformedValue = value

      // const delta = transformedValue - start

      let dummyDuration = duration

      if (point === 'start') {
        if (!isPrime) {
          // Move the start seconds when the start changes
          // BANG: Checked at the top of the function
          state.mediaElements[id]!.startOnTimeline += delta
        }

        // Change the duration with the inverse of delta
        // if start increases, duration decreases and vice versa
        // More in ERD attached above
        // BANG: Checked at the top of the function
        dummyDuration -= delta

        state.mediaElements[id]!.duration -= delta
      } else {
        // set duration
        // BANG: Checked at the top of the function
        dummyDuration = transformedValue
        state.mediaElements[id]!.duration = transformedValue
      }

      const rate = transitoryDuration / dummyDuration

      console.log('warping', {
        delta,
        transformedValue,
        dummyDuration,
        transitoryDuration,
        rate
      })

      state.mediaElements[id]!.playbackRate! = rate
    },
    move: (state, action: PayloadAction<moveType>) => {
      const { id, startOnTimeline } = action.payload

      const currentState = state.mediaElements[id]
      if (!currentState) {
        warnError('cannot move as mediaElement is undefined')
        return
      }

      const newValue = startOnTimeline > 0 ? startOnTimeline : 0
      // BANG: Checked at the top of the function
      state.mediaElements[id]!.startOnTimeline = newValue
    },
    previewSelectMedia: (
      state,
      action: PayloadAction<PreviewSelectedMedia>
    ) => {
      const { type, id } = action.payload
      state.previewSelectedMedia = { type, id }
    },
    unSelectPreviewMedia: (state) => {
      state.previewSelectedMedia = { type: null, id: null }
    },
    updatePosition: (state, action: PayloadAction<updatePositionType>) => {
      const { id, position } = action.payload

      const currentState = state.mediaElements[id]
      if (!currentState || !currentState.position) {
        warnError(
          `cannot updatePosition as mediaElement or it's position is undefined`
        )
        return
      }

      // BANG: Checked at the top of the function
      state.mediaElements[id]!.position = {
        ...currentState.position,
        ...position
      }
    },
    updateXYPosition: (state, action: PayloadAction<updatePositionXY>) => {
      const { id, deltaX, deltaY } = action.payload

      const currentState = state.mediaElements[id]
      if (!currentState || !currentState.position) {
        warnError(
          `cannot updateXYPosition as mediaElement or it's position is undefined`
        )
        return
      }

      // BANG: Checked at the top of the function
      state.mediaElements[id]!.position.x += deltaX
      state.mediaElements[id]!.position.y += deltaY
    },
    updateIsPrime: (
      state,
      action: PayloadAction<{ id: string; isPrime: boolean }>
    ) => {
      const { id, isPrime } = action.payload
      const currentState = state.mediaElements[id]
      if (!currentState || !currentState.position) {
        warnError(
          `cannot updateIsPrime as mediaElement or it's position is undefined`
        )
        return
      }

      // BANG: Checked at the top of the function
      state.mediaElements[id]!.isPrime = isPrime
    },
    selectTimelineElement: (state, action: PayloadAction<MediaElementId>) => {
      state.timelineSelectedElements.add(action.payload)
    },
    unselectTimelineElement: (state, action: PayloadAction<MediaElementId>) => {
      state.timelineSelectedElements.delete(action.payload)
    },
    unselectTimelineElements: (state) => {
      state.timelineSelectedElements.clear()
    }
  }
})

export const {
  addMediaElement,
  removeMediaElement,
  updateMediaElement,
  setEndpoints,
  move,
  previewSelectMedia,
  unSelectPreviewMedia,
  updatePosition,
  updateXYPosition,
  updateIsPrime,
  setPlaybackRate,
  selectTimelineElement,
  unselectTimelineElement,
  unselectTimelineElements
} = mediaSlice.actions

export default mediaSlice.reducer

type OmitCommon<T extends any> = Omit<T, 'container' | 'playhead'>

const useMediaSlice = (id?: string) => {
  const { mediaElements } = useAppSelector((state) => state.media)

  const dispatch = useAppDispatch()

  const container = id ? getContainer(id) : null

  const _setEndpoints = (props: OmitCommon<setEndpointsType>) => {
    if (container && id) {
      dispatch(setEndpoints({ ...props }))
      mediaStore.applyCollisionsToTrack(mediaElements, id, container)
    }
  }

  const _setPlaybackRate = (props: OmitCommon<setPlaybackRateType>) => {
    if (container && id) {
      dispatch(setPlaybackRate({ ...props }))
      mediaStore.applyCollisionsToTrack(mediaElements, id, container)
    }
  }

  const _move = (props: OmitCommon<moveType> & { id?: string }) => {
    if (container) {
      dispatch(move({ ...props }))
    }

    if (props.id) {
      const container = getContainer(props.id)

      if (!container) throw new Error('Invalid container to move')

      dispatch(move({ ...props }))
    }
  }

  return {
    setEndpoints: _setEndpoints,
    move: _move,
    setPlaybackRate: _setPlaybackRate
  }
}

export const useTimelineSelectedElements = () => {
  const { timelineSelectedElements } = useAppSelector((state) => state.media)
  return Array.from(timelineSelectedElements) as MediaElementId[]
}

export const usePreviewSelectedMedia = () => {
  const selectedMedia = useAppSelector(
    (state) => state.media.previewSelectedMedia
  )

  return { ...selectedMedia }
}

type useMediaPositionType = <T extends true | undefined>(
  _id: string | undefined | null,
  _assertValidId?: T
) => {
  position: T extends true ? MediaPosition : MediaPosition | undefined
  updatePosition: T extends true
    ? (_props: updatePositionType) => void
    : (_props: updatePositionType) => void | undefined
  updateXYPosition: T extends true
    ? (_props: updatePositionXY) => void
    : (_props: updatePositionXY) => void | undefined
}

const useMediaPosition: useMediaPositionType = (id, assertValidId) => {
  const position = useAppSelector((state) =>
    typeof id === 'string' ? state.media.mediaElements[id]?.position : undefined
  )
  const dispatch = useAppDispatch()

  const _updatePosition = (props: updatePositionType) => {
    dispatch(updatePosition(props))
  }

  const _updateXY = (props: updatePositionXY) => {
    dispatch(updateXYPosition(props))
  }

  if (assertValidId) {
    if (!id) throw new Error('Asserted valid id but id is not defined')

    return {
      position: position!,
      updatePosition: _updatePosition,
      updateXYPosition: _updateXY
    }
  }

  return {
    position: position,
    updatePosition: _updatePosition,
    updateXYPosition: _updateXY
  }
}

export { useMediaPosition, useMediaSlice }
