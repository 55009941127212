import * as Apollo from '@apollo/client'
import { FetchResult, MutationOptions } from '@apollo/client'

import { client } from './apollo/ApolloProvider'

type GraphqlQuerySplitter<T> = T extends Apollo.QueryResult<
  infer Query,
  infer Vars
>
  ? (
      // eslint-disable-next-line no-unused-vars
      options: Apollo.QueryOptions<Vars, Query>
    ) => Promise<T>
  : undefined

type GraphqlMutationSplitter<T> = T extends Apollo.MutationFunction<
  infer Mutation,
  infer Vars
>
  ? // eslint-disable-next-line no-unused-vars
    (options: MutationOptions<Mutation, Vars>) => Promise<FetchResult<Mutation>>
  : undefined

/**
 * Allows you to use a typed query that validates variables with only passing in one type to the generic
 *
 * Use QueryResults for this
 */
export const typedQuery = <QueryResult>(token?: string) => {
  // @ts-expect-error Not perfectly typed
  const newQuery: GraphqlQuerySplitter<QueryResult> = (options) => {
    const data = client.query({
      ...options,
      context: token
        ? {
            ...options.context,
            headers: { ...options.context?.headers, token }
          }
        : options.context
    })

    // @ts-expect-error Not perfectly typed
    return data as ReturnType<GraphqlQuerySplitter<QueryResult>>
  }
  return { query: newQuery }
}

/**
 * Allows you to use a typed mutation that validates variables with only passing in one type to the generic
 *
 * Use MutationFunction for this
 */
export const typedMutation = <MutationFunction>(token?: string) => {
  // @ts-expect-error Not perfectly typed
  const newMutation: GraphqlMutationSplitter<MutationFunction> = (options) => {
    const data = client.mutate({
      ...options,
      context: token
        ? {
            ...options.context,
            headers: { ...options.context?.headers, token }
          }
        : options.context
    })

    // @ts-expect-error Not perfectly typed
    return data as ReturnType<GraphqlMutationSplitter<MutationFunction>>
  }

  return { mutate: newMutation }
}
