import { MutableRefObject, useEffect, useRef, useState } from 'react'

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
// hook returns tuple(array) with type [any, boolean]
type useHoverProps = { overDelay?: number; outDelay?: number } | undefined

export function useHover<T>(
  props?: useHoverProps
): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false)

  const ref: any = useRef<T | null>(null)

  const handleMouseOver = (): void => {
    if (props?.overDelay) {
      setTimeout(() => setValue(true), props.overDelay)
    } else {
      setValue(true)
    }
  }
  const handleMouseOut = (): void => setValue(false)

  useEffect(
    () => {
      const node: any = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [ref, ref.current] // Recall only if ref changes
  )

  return [ref, value]
}
