// https://www.freecodecamp.org/news/javascript-debounce-example/

export const debounceLeading = <T extends Function>(func: T, timeout = 300) => {
  let timer
  return (...args: any[]) => {
    if (!timer) {
      func(...args)
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = undefined
    }, timeout)
  }
}

export function debounce<T extends (..._args: any[]) => any>(
  func: T,
  timeout = 300
) {
  let timer
  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
