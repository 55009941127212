import { detect } from 'detect-browser'

export const isMac = () => {
  const os = detect()?.os

  return os === 'Mac OS'
}

export const shortCutMap = () => {
  return {
    ctrl: isMac() ? '⌘' : 'Ctrl',
    shift: '⇧',
    alt: isMac() ? '⌥' : 'Alt',
    macCtrl: '^'
  }
}
