import cx from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'

import { multiplayerStore } from '../../mobx'
import useCurrentUserSync from '../hooks/useCurrentUserSync'

import MultiplayerUserCursor from './MultiplayerUserCursor'

const MultiplayerUsers = ({
  cursors = true,
  allowFollow = true
}: {
  cursors?: boolean
  allowFollow?: boolean
}) => {
  const {
    users,
    currentUserId,
    followingUserId: followingUser,
    isMultiplayer
  } = multiplayerStore

  useCurrentUserSync()

  if (!isMultiplayer) return null

  return (
    <div className="mx-3 flex items-center justify-end">
      {Object.keys(users).map((id) => {
        const user = users[id]

        // state is null for a new user and is set after a brief interval, so we can
        // just show new user if they have state set
        if (!user || !user.state) return null

        const isFollowing = user.id === followingUser
        const isCurrentUser = user.id === currentUserId
        const canFollow = !isCurrentUser && allowFollow

        return (
          <div
            key={id}
            className={cx(
              'py-2 px-1 mx-1 border-t-2 select-none',
              canFollow && 'cursor-pointer'
            )}
            style={{ borderColor: isFollowing ? user.color : 'transparent' }}
            onClick={() => {
              if (canFollow) {
                multiplayerStore.followUser(isFollowing ? null : user.id)
              }
            }}
            title={user.name}>
            <div
              className={cx(
                'rounded-full w-6 h-6 text-sm grid place-content-center',
                isFollowing &&
                  'ring-1 ring-offset-2 ring-offset-transparent ring-gray-50'
              )}
              style={{ backgroundColor: user.color }}>
              <span className="text-sm capitalize font-bold text-gray-300">
                {user.name && user.name[0]}
              </span>
            </div>

            <div className="w-full h-full pointer-events-none fixed top-0 left-0 max-w-screen max-h-screen overflow-hidden">
              {!isCurrentUser && cursors && (
                <MultiplayerUserCursor user={user} />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default observer(MultiplayerUsers)
