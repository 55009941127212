import { action, makeAutoObservable, observable } from 'mobx'
import * as Y from 'yjs'

import { client } from '@editor/graphql/apollo/ApolloProvider'
import { typedQuery } from '@editor/graphql/client'
import { notNull } from '@editor/graphql/util'
import {
  MultiplayerAwarenessUserInput,
  MultiplayerUsers,
  TimelineMode
} from '@editor/interfaces'
import {
  AnnotateProjectStateDocument,
  AnnotateProjectStateQueryResult,
  EditorHomeDocument,
  EditorHomeQuery
} from '@graphql/schema/client'
import { User } from '@prisma/client'

import { MultiplayerManager } from '../multiplayer'
import { previewSelectMedia, setPlayhead, setPlaying, store } from '../redux'

import projectStore from './projectStore'

export type AwarenessChange = {
  added: number[]
  updated: number[]
  removed: number[]
}

const DEFAULT_USER = {
  name: 'John Doe',
  color: 'blue',
  state: {
    cursorX: 0,
    cursorY: 0,
    playhead: 0,
    isPlaying: false,
    timelineMode: TimelineMode.default,
    selectedMedia: { id: null, type: null }
  }
}

class MultiplayerStore {
  /**
   * The multiplayerInstance is only loaded client side
   * it will be null always server side and
   * always have a value client side
   */
  @observable multiplayerInstance: MultiplayerManager | null = null
  @observable users: MultiplayerUsers = {}
  @observable followingUserId: string | null = null
  /**
   * Contains current user's id
   */
  @observable currentUserId: string | null = null

  @observable isMultiplayer: boolean = false

  /**
   * If the multiplayer state has been saved or not
   * Currently works only in annotate project
   */
  @observable multiplayerSaved: boolean = false

  constructor() {
    makeAutoObservable(this)

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.multiplayerStore = this
    }
  }

  /**
   * Join a multiplayer room start the multiplayer session
   * @param firstStart True if we should sync the remote state from local state
   * e.g. when the user starts a share.
   * or false if we should set local state from the remote state
   * e.g. when the user joins a share link
   * @param isMultiplayer If the project is in multiplayer mode
   */
  @action async startYjs({
    firstStart,
    isMultiplayer
  }: {
    firstStart: boolean
    isMultiplayer: boolean
  }) {
    this.isMultiplayer = isMultiplayer

    const projectId = projectStore.project?.id
    console.log('Room Project id', projectId)

    if (!projectId) throw new Error('No project id in project store')

    const multiplayerInstance = new MultiplayerManager({
      room: projectId,
      firstStart
    })
    this.multiplayerInstance = multiplayerInstance

    const user = await this.getEditorCurrentUser()
    this.setupAwareness(multiplayerInstance, user)
  }

  @action handleSaveStateChange = (saved: boolean) => {
    this.multiplayerSaved = saved
  }

  /**
   * Starts multiplayer manager for annotate project
   *
   * - If this is firstStart, meaning a new project
   * then the websockets are connected without first loading the state
   * - If this is not firstStart, meaning opening an existing project
   * then state is first loaded from the db and applied in yjs
   * after that the websockets are connected
   *
   * The user & saveFn may be set here or they may be set after the user
   * is loaded/created by using {@link updateAnnotateUser}
   */
  @action startAnnotateYjs = async ({
    projectId,
    version,
    saveFn,
    user,
    firstStart = false
  }: {
    projectId: string
    version: number
    saveFn?: (_yDoc: Y.Doc) => Promise<void>
    user?: MultiplayerAwarenessUserInput
    firstStart?: boolean
  }) => {
    const multiplayerInstance = new MultiplayerManager({
      room: `${projectId}-v${version}`,
      firstStart,
      connectWS: false,
      onSaveStateChange: this.handleSaveStateChange,
      enableAnnotateAutosave: true,
      disableIndexedDb: true
    })
    this.multiplayerInstance = multiplayerInstance
    console.log('Started multiplayer instance')

    this.multiplayerInstance.setupListeners()

    console.log({ projectId, version })

    if (!firstStart) {
      const { data } =
        await typedQuery<AnnotateProjectStateQueryResult>().query({
          query: AnnotateProjectStateDocument,
          variables: { input: { id: projectId, version } },
          // force refetch
          fetchPolicy: 'network-only'
        })

      const projectState = data?.getAnnotateProjectState

      if (projectState) {
        Y.applyUpdate(
          this.multiplayerInstance.yDoc,
          Uint8Array.from(atob(projectState), (c) => c.charCodeAt(0)),
          'remote'
        )

        await this.multiplayerInstance.syncRemoteStateToLocal()

        console.log('State applied', this.multiplayerInstance.snapshot())
      }
    } else {
      console.log(`First start, not applying state`)
    }

    multiplayerInstance.connectWebSocket({ isAnno: 'true' })

    if (user) {
      this.isMultiplayer = true
      this.setupAwareness(multiplayerInstance, user)
    }
    if (saveFn) this.multiplayerInstance.setAnnotateAutosaveFn(saveFn)
    this.multiplayerInstance.save()
  }

  /**
   * This is used to set the awareness & update the save function
   * when user is updated
   */
  updateAnnotateUser = ({
    user,
    saveFn
  }: {
    user: MultiplayerAwarenessUserInput
    saveFn: (_yDoc: Y.Doc) => Promise<void>
  }) => {
    if (!this.multiplayerInstance)
      throw new Error('Multiplayer has not yet been started')

    this.multiplayerInstance.setAnnotateAutosaveFn(saveFn)
    this.setupAwareness(this.multiplayerInstance, user)
    this.isMultiplayer = true
  }

  /**
   * Switch to another version of the project currently loaded
   * Destroys the current multiplayerManager and creates a new one
   * then loads the state for the new version
   */
  @action switchAnnotateProjectVersion = async ({
    projectId,
    version,
    saveFn,
    user
  }: {
    projectId: string
    version: number
    saveFn: (_yDoc: Y.Doc) => Promise<void>
    user: MultiplayerAwarenessUserInput
  }) => {
    if (!this.multiplayerInstance) {
      throw new Error('Multiplayer instance not initialized')
    }

    // save the current state if its not saved
    if (!this.multiplayerSaved) {
      await this.multiplayerInstance.save()
    }
    // disable saving so that cleaning the state doesn't save the empty state
    this.multiplayerInstance.disableAnnotateAutoSave()
    this.multiplayerInstance.destroyWebSocket()
    // clean the old state (cleans the yDoc which cleans the redux/mobx state)
    this.multiplayerInstance.cleanState()
    // destroy the yDoc
    this.multiplayerInstance.destroy()
    // clear the reference
    this.multiplayerInstance = null
    console.log('Removed multiplayer instance')

    await this.startAnnotateYjs({ projectId, version, saveFn, user })
  }

  /**
   * Called when the user switches project to multiplayer as we dont want to
   * start a new instance of multiplayer just update the one we already have
   *
   * Only need to do 2 things
   * 1. Set isMultiplayer on this store and sw
   * 2. Upload existing medias
   */
  @action async switchProjectToMultiplayer() {
    this.isMultiplayer = true
    window.navigator.serviceWorker.ready.then((state) => {
      state.active?.postMessage(`isMultiplayer=true`)

      // Upload existing medias
      // uploadLocalMediasToCloud(Object.values(mediaStore.medias))
    })
  }

  /**
   * Returns the current user in the editor
   */
  getEditorCurrentUser = async () => {
    const { data } = await client.query<EditorHomeQuery>({
      query: EditorHomeDocument
    })

    const user = notNull(data.me.user)

    return { ...user, createdAt: new Date(user.createdAt) } as User
  }

  /**
   * Media Uploading State
   *  'started'
   *  'completed'
   *  'error'
   *
   * 1. Add file - use /api/asset/ url from the beginning (permanent-signed-urls branch)
   * 2a. Cache file service worker (permanent-signed-urls)
   * 2b. Upload file to gcp (TODO)
   * 3. File locally accessible instantly (permanent-signed-urls)
   * (FUTURE) 4. We can have a spinny circle thing on the top right or something to show upload percentage
   * (In the future, we can sync the upload percentage for other users and they can see the percent completed)
   * 5. If upload succeed, then post message from SW -> update local state -> update multiplayer
   * If upload failed, then post message from SW -> update local state -> tell multiplayer upload is done and it failed -> run file offline check -> results in file detected as offlined
   * Delete cached file and set local state as offlined file (we want to throw a more specific upload failed error for the user uploading so the interaction is not super awkward)
   * We want the uploading state to be synced for files across devices, so if one user is uploading a file then others can't upload the same file (not super important)
   * We want the uploaded boolean to also be synced to know if the files are ready for use or not. The offlined should not be synced
   *
   */

  /**
   * Listen for updates in state of users in room
   */
  @action setupAwareness = async (
    multiplayerInstance: MultiplayerManager,
    user: MultiplayerAwarenessUserInput
  ) => {
    this.currentUserId = user.id
    multiplayerInstance?.setUser({
      id: user.id,
      ...DEFAULT_USER,
      name: user.username || DEFAULT_USER.name
    })
    this.users = multiplayerInstance.usersState
    multiplayerInstance.awareness?.on('change', (change: AwarenessChange) => {
      this.handleAwarenessChange(multiplayerInstance, change)
    })
  }

  /**
   * Called when awareness state changes, updates the users state and if following someone calls followingUserStateChange
   * @param multiplayerInstance
   * @param change The change event from yjs
   */
  @action handleAwarenessChange(
    multiplayerInstance: MultiplayerManager,
    change: AwarenessChange
  ) {
    const newUsersState = multiplayerInstance.usersState
    this.users = newUsersState

    if (
      this.followingUserId &&
      change.updated.includes(parseInt(this.followingUserId))
    ) {
      this.followingUserStateChange(newUsersState)
    }
  }

  /**
   * When users state is updated we call this to follow
   * actions of the user that current user is following
   */
  @action followingUserStateChange(newUsersState: MultiplayerUsers) {
    if (!this.followingUserId) return
    const followingUserState = newUsersState[this.followingUserId]?.state

    if (!followingUserState) {
      console.warn('[WARN] followingUserState is undefined')
      return
    }

    const state = store.getState()

    // sync isPlaying
    store.dispatch(setPlaying(followingUserState.isPlaying))

    // sync playhead
    const playheadDiff = followingUserState.playhead - state.playback.playhead
    store.dispatch(
      setPlayhead({ value: playheadDiff, diff: true, seeking: true })
    )

    //  sync selected media
    store.dispatch(previewSelectMedia(followingUserState.selectedMedia))
  }

  @action followUser(userID: null | number | string) {
    // in some places eg. when using `Object.keys({}).map(key => ...)`
    // we get the key as a string so we just convert to int if that's the case
    this.followingUserId = userID?.toString() || null
  }
}

export default new MultiplayerStore()
