import { MediaElementInterface, MediaElements } from '@editor/interfaces'

export const mediaElementObject = <K extends boolean = false>(
  mediaElements: MediaElements,
  id: string,
  returnUndefined?: K
): K extends true
  ? MediaElementInterface | undefined
  : MediaElementInterface => {
  if (!returnUndefined && !mediaElements[id])
    throw new Error('Could not find media id')
  // @ts-expect-error Typescript is doing something weird here, this is correct
  return mediaElements[id]
}
