import { toJS } from 'mobx'
import { nanoid } from 'nanoid'

import { Container, MediaPlaybackState } from '@editor/interfaces'
import { getContainer } from '@editor/mediautils'
import { mediaStore } from '@editor/state/mobx'
import { store, updateMediaElement } from '@editor/state/redux'

import { Audio, Video } from '..'

/**
 * Detach audio from a element of this video
 *
 * 1. Set volume of the video media element to 0
 * 2. Create a new audio from the video
 * 3. Get a track which is empty at current start of the video media element
 * 4. Add the new audio media
 */
export const detachAudio = (video: Video, elementId: string) => {
  const videoMediaElement = video.getMediaElement(elementId)

  if (!videoMediaElement)
    throw new Error('Could not find element id' + elementId)

  const { usableDuration, startOnTimeline, duration } = videoMediaElement

  // Set volume og this element to 0
  store.dispatch(
    updateMediaElement({
      id: elementId,
      mediaElement: { volume: 0 }
    })
  )

  // Create a new audio
  const audio = new Audio({
    linkedVideoId: video._id,
    format: video.format,
    duration: video.duration,
    _id: nanoid(),
    name: video.name,
    mediaPlaybackState: MediaPlaybackState.playable
  })

  const currentContainer = getContainer(elementId)
  if (!currentContainer)
    throw new Error('Media element does not exist on tracks')
  const { trackType, index } = currentContainer

  /**
   * Position of new audio element:
   * When in above-0 track (above prime), a new track is created between and the new audio is added to it
   * In other above and below tracks the audio media is added to the track below the current track
   */

  // get the below container in which to add the audio
  let audioContainer: Container = { trackType: 'above', index: index - 1 }
  if (trackType === 'below') {
    audioContainer = { trackType: 'below', index: index + 1 }
  } else if (trackType === 'prime') {
    audioContainer = { trackType: 'below', index: 0 }
  } else if (trackType === 'above' && index === 0) {
    audioContainer = { trackType: 'above', index: 0 }
  }

  console.log('adding audio to track', audioContainer)

  // Add the audio
  const mediaElement = mediaStore.addMedia(audio, {
    usableDuration,
    container: audioContainer,
    startOnTimeline,
    duration
  })

  const {
    media: { mediaElements }
  } = store.getState()

  // If video is in above-0, create a track b/w prime and it and add to that
  if (currentContainer.trackType === 'above' && currentContainer.index === 0) {
    const newTracks = toJS(mediaStore.tracks)
    newTracks.above = [
      [mediaElement.id], // new track with audio
      newTracks.above[0]?.filter((id) => id !== mediaElement.id) || [], // the above-0 track with audio removed
      ...newTracks.above.slice(1) // rest of above tracks
    ]
    mediaStore.updateTracks(newTracks)
  }
  // Otherwise apply collision relative to the audio element, moving colliding medias downwards
  else {
    mediaStore.applyCollisionsToTrack(
      mediaElements,
      mediaElement.id,
      audioContainer,
      'force-down'
    )
  }
}
