import * as Y from 'yjs'

import { MultiplayerWorkspaceState } from '../types'

export const setupUndo = (state: MultiplayerWorkspaceState) => {
  const undoManager = new Y.UndoManager([
    state.files,
    state.folders,
    state.mediaState,
    state.mediaElements,
    state.tracks.above,
    state.tracks.below,
    state.tracks.prime
  ])

  // undoManager.on('stack-item-added', (args) => {
  //   console.log('Added item to undo/redo stack', args)
  // })

  // undoManager.on('stack-item-popped', () => {
  //   console.log('Remove item from undo/redo stack')
  // })

  return undoManager
}
