import { GetServerSidePropsResult, NextPageContext, Redirect } from 'next'
import Router from 'next/router'

export type NextRedirect = Extract<
  GetServerSidePropsResult<{}>,
  { redirect: Redirect }
>

/**
 * This is to be used to either redirect on client side or to get right redirect for nextjs response
 * To redirect using the request use {@link redirectReq}
 */
export const redirect = (url: string, referrer?: string): NextRedirect => {
  const destination = referrer
    ? url.includes('?')
      ? `${url}&referrer=${referrer}`
      : `${url}?referrer=${referrer}`
    : url
  console.log('Redirecting to', url)

  if (typeof window !== 'undefined') {
    Router.push(destination)
  }

  return { redirect: { destination, permanent: false } }
}

/**
 * Used to redirect a request using res
 * To redirect on client side or from nextjs ssr functions use {@link redirect}
 */
export const redirectReq = (
  url: string,
  res: NextPageContext['res'],
  referrer?: string
) => {
  if (!res) return

  res.statusCode = 302
  const destination = referrer
    ? url.includes('?')
      ? `${url}&referrer=${referrer}`
      : `${url}?referrer=${referrer}`
    : url
  res.setHeader('location', destination)
  res.end()
}
