import { MediaPlaybackState, MediaProps } from '@editor/interfaces'

import { Media } from './Media'

/**
 * Static medias are the ones which are still and can expand infinitely
 * eg. Comment, TLDrawElement, etc.
 */
export class StaticMedia extends Media {
  constructor(props: MediaProps) {
    super({ ...props })
    this.isStatic = true
    this.mediaPlaybackState = MediaPlaybackState.playable
  }

  /**
   * Static medias don't have any source so override this
   */
  get source() {
    return ''
  }

  /**
   * This returns the Media.source
   * for use in Image class
   */
  superSource() {
    return super.source
  }
}
