/* eslint-disable */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T extends PromiseLike<infer U>
  ? Promise<U | null>
  : T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
  Byte: any
  DateTime: string
}

export type AnnoLoginInput = {
  didToken?: Maybe<Scalars['String']>
  fingerprint?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type AnnoLoginNeedAuth = {
  __typename?: 'AnnoLoginNeedAuth'
  needAuth: Scalars['Boolean']
}

export type AnnoLoginSuccess = {
  __typename?: 'AnnoLoginSuccess'
  token: Scalars['String']
  user: AnnotateMiniUser
}

export type AnnoUserOutput = {
  __typename?: 'AnnoUserOutput'
  token?: Maybe<Scalars['String']>
  user: AnnotateMiniUser
}

export type AnnotateComments = {
  __typename?: 'AnnotateComments'
  author?: Maybe<AnnotateUser>
  autoId: Scalars['Int']
  children?: Maybe<Array<AnnotateComments>>
  createdAt: Scalars['DateTime']
  edited: Scalars['Boolean']
  id: Scalars['String']
  mediaElement: Scalars['String']
  mediaEnd: Scalars['Float']
  mediaStart: Scalars['Float']
  parent?: Maybe<AnnotateComments>
  parentCommentId?: Maybe<Scalars['Int']>
  project?: Maybe<AnnotateProject>
  projectId: Scalars['Int']
  projectVersion: AnnotateProjectVersion
  resolvedBy?: Maybe<AnnotateUser>
  text: Scalars['String']
  userId: Scalars['Int']
}

export type AnnotateLoginOutput = AnnoLoginNeedAuth | AnnoLoginSuccess

export type AnnotateMiniUser = {
  __typename?: 'AnnotateMiniUser'
  email?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export type AnnotateProject = {
  __typename?: 'AnnotateProject'
  collaborators?: Maybe<Array<AnnotateUser>>
  comments?: Maybe<Array<AnnotateComments>>
  createdAt: Scalars['DateTime']
  currentVersion: Scalars['Float']
  id: Scalars['String']
  mediaId?: Maybe<Scalars['String']>
  name: Scalars['String']
  owner: AnnotateUser
  ownerId: Scalars['Int']
  state?: Maybe<Array<AnnotateProjectState>>
  updatedAt?: Maybe<Scalars['DateTime']>
  versions?: Maybe<Array<AnnotateProjectVersion>>
}

export type AnnotateProjectState = {
  __typename?: 'AnnotateProjectState'
  annotateProjectVersionAutoId: Scalars['Int']
  autoId: Scalars['Int']
  clock: Scalars['BigInt']
  encodedUpdate: Scalars['Byte']
  id: Scalars['String']
  project: AnnotateProject
  projectId: Scalars['Int']
}

export type AnnotateProjectVersion = {
  __typename?: 'AnnotateProjectVersion'
  id: Scalars['String']
  version: Scalars['Float']
}

export type AnnotateProjectVideoChangeOutput = {
  __typename?: 'AnnotateProjectVideoChangeOutput'
  mediaId?: Maybe<Scalars['String']>
  videoUrl: Scalars['String']
}

export type AnnotateUser = {
  __typename?: 'AnnotateUser'
  autoId: Scalars['Int']
  comments?: Maybe<Array<AnnotateComments>>
  email?: Maybe<Scalars['String']>
  fingerprint: Scalars['String']
  id: Scalars['String']
  invitedProjects?: Maybe<Array<AnnotateProject>>
  isAuthed: Scalars['Boolean']
  name: Scalars['String']
  projects?: Maybe<Array<AnnotateProject>>
}

export type CommentInput = {
  children?: Maybe<Array<Maybe<Scalars['String']>>>
  mediaElement: Scalars['String']
  mediaEnd: Scalars['Float']
  mediaStart: Scalars['Float']
  parent?: Maybe<Scalars['String']>
  projectId: Scalars['String']
  text: Scalars['String']
  userId: Scalars['String']
  version: Scalars['Float']
}

export type CreateAnnotateProjectInput = {
  mediaId?: Maybe<Scalars['String']>
  name: Scalars['String']
  ownerId: Scalars['String']
  videoUrl?: Maybe<Scalars['String']>
}

export type CreateAnnotateProjectOutput = {
  __typename?: 'CreateAnnotateProjectOutput'
  id: Scalars['String']
  version: Scalars['Float']
}

export type CreateAnnotateProjectVersion = {
  projectId: Scalars['String']
  version: Scalars['Float']
}

export type CreateAnnotateUserInput = {
  email?: Maybe<Scalars['String']>
  fingerprint: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type CreateAnnotateUserOutput = AnnoLoginNeedAuth | AnnoUserOutput

/** OUTPUTS */
export type CreateFromSaveFileOutput = {
  __typename?: 'CreateFromSaveFileOutput'
  owner?: Maybe<User>
  projectName: Scalars['String']
}

export type CreateLinkOutput = {
  __typename?: 'CreateLinkOutput'
  slug: Scalars['String']
}

export type CreateProjectInput = {
  collaborators: Array<Maybe<Scalars['String']>>
  dimensions: Array<Scalars['Int']>
  frameRate: Scalars['Int']
  projectName: Scalars['String']
  thumbnail?: Maybe<Scalars['String']>
}

export type CreateProjectOutput = {
  __typename?: 'CreateProjectOutput'
  created: Scalars['Boolean']
  name: ProjectName
  project: CreateProjectOutputProject
}

export type CreateProjectOutputProject = ProjectInterface & {
  __typename?: 'CreateProjectOutputProject'
  autoId: Scalars['Int']
  /**
   * These relations don't exist on the Project prisma type, we should give them arguments to distinguish
   * them
   */
  collaborators?: Maybe<Array<User>>
  createdAt: Scalars['DateTime']
  dimensions?: Maybe<Array<Scalars['Int']>>
  duration?: Maybe<Scalars['Int']>
  fileSize?: Maybe<Array<Scalars['Int']>>
  frameRate?: Maybe<Scalars['Int']>
  id: Scalars['String']
  links?: Maybe<Array<InviteLinks>>
  multiplayer?: Maybe<Scalars['Boolean']>
  owner: User
  ownerId?: Maybe<Scalars['Int']>
  projectName: Scalars['String']
  state?: Maybe<Array<ProjectState>>
  team?: Maybe<Team>
  teamId?: Maybe<Scalars['Int']>
  thumbnail?: Maybe<Scalars['String']>
}

export type CreateSaveStateOutput = {
  __typename?: 'CreateSaveStateOutput'
  id: Scalars['String']
  name: Scalars['String']
}

export type GetAnnotateProjectStateInput = {
  id: Scalars['String']
  version: Scalars['Float']
}

export type GetAnnotateProjectVersionInput = {
  projectId: Scalars['String']
  version: Scalars['Float']
}

export type GetComment = {
  projectId: Scalars['String']
  resolved?: Maybe<Scalars['Boolean']>
  userId?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['Float']>
}

export type InviteLinks = {
  __typename?: 'InviteLinks'
  autoId: Scalars['Int']
  id: Scalars['String']
  /**
   * These relations don't exist on the InviteLinks prisma type, we should give them arguments to distinguish
   * them
   */
  project?: Maybe<Project>
  projectId: Scalars['Int']
  slug: Scalars['String']
  type: InviteType
}

export type InviteType = 'comment' | 'edit' | 'view'

export type JoinOutput = {
  __typename?: 'JoinOutput'
  joined: Scalars['Boolean']
  url: Scalars['String']
}

export type MakeRenderAuthTokenOutput = {
  __typename?: 'MakeRenderAuthTokenOutput'
  token: Scalars['String']
  userId: Scalars['String']
}

export type MeOutput = {
  __typename?: 'MeOutput'
  user: User
  userPersona?: Maybe<UserPersona>
}

export type Mutation = {
  __typename?: 'Mutation'
  annoLogin: AnnotateLoginOutput
  /** Annotations */
  createAnnotateProject: CreateAnnotateProjectOutput
  createAnnotateProjectVersion: AnnotateProjectVersion
  createAnnotateUser: CreateAnnotateUserOutput
  /** Comments */
  createComment: Scalars['Boolean']
  createFromSaveFile: CreateFromSaveFileOutput
  /** Invite */
  createLink: CreateLinkOutput
  /** Project */
  createProject: CreateProjectOutput
  createSaveState: CreateSaveStateOutput
  /** User */
  createUserPersona: Scalars['Boolean']
  deleteAnnotateProject: Scalars['Boolean']
  deleteComment: Scalars['Boolean']
  deleteLinks: SuccessOutput
  deleteProject: SuccessOutput
  fork: SuccessOutput
  forkAnno: SuccessOutput
  joinProject: JoinOutput
  logout: SuccessOutput
  magic: TokenOutput
  /** Render auth */
  makeRenderAuthToken?: Maybe<MakeRenderAuthTokenOutput>
  onboard: OnboardOutput
  promoteSaveState: SuccessOutput
  updateAnnotateProject?: Maybe<Scalars['Boolean']>
  updateAnnotateProjectState?: Maybe<Scalars['Boolean']>
  updateAnnotateUser: CreateAnnotateUserOutput
  updateComment: Scalars['Boolean']
  updateProject: SuccessOutput
  /** Auth */
  verify: VerifyOutput
}

export type MutationAnnoLoginArgs = {
  input: AnnoLoginInput
}

export type MutationCreateAnnotateProjectArgs = {
  input: CreateAnnotateProjectInput
}

export type MutationCreateAnnotateProjectVersionArgs = {
  input: CreateAnnotateProjectVersion
}

export type MutationCreateAnnotateUserArgs = {
  input: CreateAnnotateUserInput
}

export type MutationCreateCommentArgs = {
  input: CommentInput
}

export type MutationCreateFromSaveFileArgs = {
  saveFile: Scalars['String']
}

export type MutationCreateProjectArgs = {
  input: CreateProjectInput
}

export type MutationCreateSaveStateArgs = {
  projectId: Scalars['String']
}

export type MutationCreateUserPersonaArgs = {
  persona: UserPersonaInput
}

export type MutationDeleteAnnotateProjectArgs = {
  id: Scalars['String']
}

export type MutationDeleteCommentArgs = {
  id: Scalars['String']
}

export type MutationDeleteProjectArgs = {
  id: Scalars['String']
}

export type MutationForkArgs = {
  projectName: Scalars['String']
  username: Scalars['String']
}

export type MutationForkAnnoArgs = {
  projectId: Scalars['String']
  userId: Scalars['String']
}

export type MutationJoinProjectArgs = {
  slug: Scalars['String']
}

export type MutationMagicArgs = {
  didToken: Scalars['String']
}

export type MutationOnboardArgs = {
  name: Scalars['String']
}

export type MutationPromoteSaveStateArgs = {
  projectId: Scalars['String']
}

export type MutationUpdateAnnotateProjectArgs = {
  input: UpdateAnnotateProjectInput
}

export type MutationUpdateAnnotateProjectStateArgs = {
  input: UpdateAnnotateProjectStateInput
}

export type MutationUpdateAnnotateUserArgs = {
  input: UpdateAnnotateUserInput
}

export type MutationUpdateCommentArgs = {
  input: PartialCommentInput
}

export type MutationUpdateProjectArgs = {
  id: Scalars['String']
  project: UpdateProjectInput
}

export type OnboardOutput = {
  __typename?: 'OnboardOutput'
  token: Scalars['String']
  userId: Scalars['String']
}

export type PartialCommentInput = {
  children?: Maybe<Array<Maybe<Scalars['String']>>>
  commentId: Scalars['String']
  mediaElement?: Maybe<Scalars['String']>
  mediaEnd?: Maybe<Scalars['Float']>
  mediaStart?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['String']>
  projectId?: Maybe<Scalars['String']>
  resolvedBy?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type Project = ProjectInterface & {
  __typename?: 'Project'
  autoId: Scalars['Int']
  /**
   * These relations don't exist on the Project prisma type, we should give them arguments to distinguish
   * them
   */
  collaborators?: Maybe<Array<User>>
  createdAt: Scalars['DateTime']
  dimensions?: Maybe<Array<Scalars['Int']>>
  duration?: Maybe<Scalars['Int']>
  fileSize?: Maybe<Array<Scalars['Int']>>
  frameRate?: Maybe<Scalars['Int']>
  id: Scalars['String']
  lastUpdatedAt: Scalars['DateTime']
  links?: Maybe<Array<InviteLinks>>
  multiplayer?: Maybe<Scalars['Boolean']>
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['Int']>
  projectName: Scalars['String']
  saveStateChildren?: Maybe<Array<Project>>
  saveStateOriginalProject?: Maybe<Project>
  state?: Maybe<Array<ProjectState>>
  team?: Maybe<Team>
  teamId?: Maybe<Scalars['Int']>
  thumbnail?: Maybe<Scalars['String']>
}

export type ProjectInterface = {
  autoId: Scalars['Int']
  /**
   * These relations don't exist on the Project prisma type, we should give them arguments to distinguish
   * them
   */
  collaborators?: Maybe<Array<User>>
  createdAt: Scalars['DateTime']
  dimensions?: Maybe<Array<Scalars['Int']>>
  duration?: Maybe<Scalars['Int']>
  fileSize?: Maybe<Array<Scalars['Int']>>
  frameRate?: Maybe<Scalars['Int']>
  id: Scalars['String']
  links?: Maybe<Array<InviteLinks>>
  multiplayer?: Maybe<Scalars['Boolean']>
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['Int']>
  projectName: Scalars['String']
  state?: Maybe<Array<ProjectState>>
  team?: Maybe<Team>
  teamId?: Maybe<Scalars['Int']>
  thumbnail?: Maybe<Scalars['String']>
}

export type ProjectName = {
  __typename?: 'ProjectName'
  org: Scalars['String']
  projectName: Scalars['String']
}

export type ProjectState = {
  __typename?: 'ProjectState'
  autoId: Scalars['Int']
  clock: Scalars['BigInt']
  encodedUpdate: Scalars['Byte']
  id: Scalars['String']
  /**
   * These relations don't exist on the ProjectState prisma type, we should give them arguments to distinguish
   * them
   */
  project?: Maybe<Project>
  projectId: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  dummy: Scalars['String']
  /** Annotate */
  getAnnotateProject?: Maybe<AnnotateProject>
  getAnnotateProjectState?: Maybe<Scalars['String']>
  getAnnotateProjectVersion?: Maybe<AnnotateProjectVersion>
  getAnnotateUser?: Maybe<AnnotateUser>
  /** Comments */
  getComment?: Maybe<AnnotateComments>
  getComments?: Maybe<Array<AnnotateComments>>
  getKnockUserToken?: Maybe<Scalars['String']>
  /** Invite */
  getLink?: Maybe<InviteLinks>
  /** Project */
  getProjectByName?: Maybe<Project>
  getSaveFileData?: Maybe<Scalars['String']>
  /** User */
  getUserById?: Maybe<User>
  getUsersProjects: Array<Project>
  /** Auth */
  me: MeOutput
  projectState?: Maybe<Scalars['String']>
}

export type QueryDummyArgs = {
  input: Scalars['String']
}

export type QueryGetAnnotateProjectArgs = {
  id: Scalars['String']
}

export type QueryGetAnnotateProjectStateArgs = {
  input: GetAnnotateProjectStateInput
}

export type QueryGetAnnotateProjectVersionArgs = {
  input: GetAnnotateProjectVersionInput
}

export type QueryGetCommentArgs = {
  id: Scalars['String']
}

export type QueryGetCommentsArgs = {
  input: GetComment
}

export type QueryGetKnockUserTokenArgs = {
  id: Scalars['String']
}

export type QueryGetProjectByNameArgs = {
  orgName: Scalars['String']
  projectName: Scalars['String']
}

export type QueryGetSaveFileDataArgs = {
  projectId: Scalars['String']
}

export type QueryGetUserByIdArgs = {
  id: Scalars['String']
}

export type QueryProjectStateArgs = {
  projectId: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  commentAdded: AnnotateComments
  commentRemoved: AnnotateComments
  commentUpdated: AnnotateComments
}

export type SubscriptionCommentAddedArgs = {
  input: GetComment
}

export type SubscriptionCommentRemovedArgs = {
  input: GetComment
}

export type SubscriptionCommentUpdatedArgs = {
  input: GetComment
}

export type SuccessOutput = {
  __typename?: 'SuccessOutput'
  success: Scalars['Boolean']
}

export type Team = {
  __typename?: 'Team'
  autoId: Scalars['Int']
  collaborators?: Maybe<Array<User>>
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  /**
   * These relations don't exist on the Team prisma type, we should give them arguments to distinguish
   * them
   */
  owner?: Maybe<User>
  ownerId: Scalars['Int']
  projects?: Maybe<Array<Project>>
  teamName?: Maybe<Scalars['String']>
  type: TeamType
}

export type TeamType = 'TeamEnterprise' | 'TeamPro' | 'TeamTrial'

export type TokenOutput = {
  __typename?: 'TokenOutput'
  token: Scalars['String']
  user?: Maybe<User>
  whitelisted?: Maybe<Scalars['Boolean']>
}

/** INPUTS */
export type UpdateAnnotateProjectInput = {
  name?: Maybe<Scalars['String']>
  projectId: Scalars['String']
}

export type UpdateAnnotateProjectStateInput = {
  projectId: Scalars['String']
  updates: Scalars['String']
  userId: Scalars['String']
  version: Scalars['Float']
}

export type UpdateAnnotateUserInput = {
  didToken?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  projectId?: Maybe<Scalars['String']>
}

export type UpdateProjectInput = {
  collaborators?: Maybe<Array<Scalars['String']>>
  dimensions?: Maybe<Array<Scalars['Int']>>
  duration?: Maybe<Scalars['Int']>
  fileSize?: Maybe<Array<Scalars['Int']>>
  frameRate?: Maybe<Scalars['Int']>
  projectName?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  autoId: Scalars['Int']
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  id: Scalars['String']
  invitedProjects?: Maybe<Array<Project>>
  invitedTeams?: Maybe<Array<Team>>
  /**
   * These relations don't exist on the User prisma type, we should give them arguments to distinguish
   * them
   */
  myProjects?: Maybe<Array<Project>>
  myTeams?: Maybe<Array<Team>>
  type: UserType
  username?: Maybe<Scalars['String']>
}

export type UserPersona = {
  __typename?: 'UserPersona'
  autoId: Scalars['Int']
  experience: Scalars['String']
  id: Scalars['String']
  multiplayer: Scalars['String']
  userId: Scalars['String']
}

export type UserPersonaInput = {
  experience: Scalars['String']
  multiplayer: Scalars['String']
  userId: Scalars['String']
}

export type UserProjectsInput = {
  orgName: Scalars['String']
  projectName: Scalars['String']
}

export type UserType = 'Free' | 'PersonalPro'

export type VerifyOutput = {
  __typename?: 'VerifyOutput'
  onboarded: Scalars['Boolean']
  valid: Scalars['Boolean']
}

export type AnnotateCommentFragmentFragment = {
  __typename?: 'AnnotateComments'
  id: string
  createdAt: string
  text: string
  edited: boolean
  mediaElement: string
  mediaStart: number
  mediaEnd: number
  author?:
    | { __typename?: 'AnnotateUser'; id: string; name: string }
    | null
    | undefined
  resolvedBy?:
    | { __typename?: 'AnnotateUser'; id: string; name: string }
    | null
    | undefined
  projectVersion: { __typename?: 'AnnotateProjectVersion'; version: number }
  parent?: { __typename?: 'AnnotateComments'; id: string } | null | undefined
  children?:
    | Array<{
        __typename?: 'AnnotateComments'
        id: string
        createdAt: string
        text: string
        edited: boolean
        mediaElement: string
        mediaStart: number
        mediaEnd: number
        author?:
          | { __typename?: 'AnnotateUser'; id: string; name: string }
          | null
          | undefined
        projectVersion: {
          __typename?: 'AnnotateProjectVersion'
          version: number
        }
        parent?:
          | { __typename?: 'AnnotateComments'; id: string }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type CreateCommentMutationVariables = Exact<{
  input: CommentInput
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment: boolean
}

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteCommentMutation = {
  __typename?: 'Mutation'
  deleteComment: boolean
}

export type UpdateCommentMutationVariables = Exact<{
  input: PartialCommentInput
}>

export type UpdateCommentMutation = {
  __typename?: 'Mutation'
  updateComment: boolean
}

export type CreateAnnotateProjectMutationVariables = Exact<{
  projectProps: CreateAnnotateProjectInput
}>

export type CreateAnnotateProjectMutation = {
  __typename?: 'Mutation'
  createAnnotateProject: {
    __typename?: 'CreateAnnotateProjectOutput'
    id: string
    version: number
  }
}

export type CreateAnnotateUserMutationVariables = Exact<{
  userProps: CreateAnnotateUserInput
}>

export type CreateAnnotateUserMutation = {
  __typename?: 'Mutation'
  createAnnotateUser:
    | { __typename?: 'AnnoLoginNeedAuth'; needAuth: boolean }
    | {
        __typename?: 'AnnoUserOutput'
        token?: string | null | undefined
        user: {
          __typename?: 'AnnotateMiniUser'
          id: string
          name: string
          email?: string | null | undefined
        }
      }
}

export type UpdateAnnotateProjectStateMutationVariables = Exact<{
  updateProjectState: UpdateAnnotateProjectStateInput
}>

export type UpdateAnnotateProjectStateMutation = {
  __typename?: 'Mutation'
  updateAnnotateProjectState?: boolean | null | undefined
}

export type UpdateAnnotateUserMutationVariables = Exact<{
  updateUser: UpdateAnnotateUserInput
}>

export type UpdateAnnotateUserMutation = {
  __typename?: 'Mutation'
  updateAnnotateUser:
    | { __typename?: 'AnnoLoginNeedAuth'; needAuth: boolean }
    | {
        __typename?: 'AnnoUserOutput'
        token?: string | null | undefined
        user: {
          __typename?: 'AnnotateMiniUser'
          id: string
          name: string
          email?: string | null | undefined
        }
      }
}

export type UpdateAnnotateProjectMutationVariables = Exact<{
  input: UpdateAnnotateProjectInput
}>

export type UpdateAnnotateProjectMutation = {
  __typename?: 'Mutation'
  updateAnnotateProject?: boolean | null | undefined
}

export type DeleteAnnotateProjectMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteAnnotateProjectMutation = {
  __typename?: 'Mutation'
  deleteAnnotateProject: boolean
}

export type CreateAnnotateProjectVersionMutationVariables = Exact<{
  input: CreateAnnotateProjectVersion
}>

export type CreateAnnotateProjectVersionMutation = {
  __typename?: 'Mutation'
  createAnnotateProjectVersion: {
    __typename?: 'AnnotateProjectVersion'
    id: string
    version: number
  }
}

export type AnnoLoginMutationVariables = Exact<{
  input: AnnoLoginInput
}>

export type AnnoLoginMutation = {
  __typename?: 'Mutation'
  annoLogin:
    | { __typename?: 'AnnoLoginNeedAuth'; needAuth: boolean }
    | {
        __typename?: 'AnnoLoginSuccess'
        token: string
        user: {
          __typename?: 'AnnotateMiniUser'
          id: string
          name: string
          email?: string | null | undefined
        }
      }
}

export type GetCommentsQueryVariables = Exact<{
  input: GetComment
}>

export type GetCommentsQuery = {
  __typename?: 'Query'
  getComments?:
    | Array<{
        __typename?: 'AnnotateComments'
        id: string
        createdAt: string
        text: string
        edited: boolean
        mediaElement: string
        mediaStart: number
        mediaEnd: number
        author?:
          | { __typename?: 'AnnotateUser'; id: string; name: string }
          | null
          | undefined
        resolvedBy?:
          | { __typename?: 'AnnotateUser'; id: string; name: string }
          | null
          | undefined
        projectVersion: {
          __typename?: 'AnnotateProjectVersion'
          version: number
        }
        parent?:
          | { __typename?: 'AnnotateComments'; id: string }
          | null
          | undefined
        children?:
          | Array<{
              __typename?: 'AnnotateComments'
              id: string
              createdAt: string
              text: string
              edited: boolean
              mediaElement: string
              mediaStart: number
              mediaEnd: number
              author?:
                | { __typename?: 'AnnotateUser'; id: string; name: string }
                | null
                | undefined
              projectVersion: {
                __typename?: 'AnnotateProjectVersion'
                version: number
              }
              parent?:
                | { __typename?: 'AnnotateComments'; id: string }
                | null
                | undefined
            }>
          | null
          | undefined
      }>
    | null
    | undefined
}

export type AnnotateProjectQueryVariables = Exact<{
  projectId: Scalars['String']
}>

export type AnnotateProjectQuery = {
  __typename?: 'Query'
  getAnnotateProject?:
    | {
        __typename?: 'AnnotateProject'
        id: string
        name: string
        mediaId?: string | null | undefined
        currentVersion: number
        versions?:
          | Array<{
              __typename?: 'AnnotateProjectVersion'
              id: string
              version: number
            }>
          | null
          | undefined
        owner: { __typename?: 'AnnotateUser'; id: string }
        collaborators?:
          | Array<{
              __typename?: 'AnnotateUser'
              id: string
              name: string
              email?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type AnnotateProjectStateQueryVariables = Exact<{
  input: GetAnnotateProjectStateInput
}>

export type AnnotateProjectStateQuery = {
  __typename?: 'Query'
  getAnnotateProjectState?: string | null | undefined
}

export type GetAnnotateProjectVersionQueryVariables = Exact<{
  input: GetAnnotateProjectVersionInput
}>

export type GetAnnotateProjectVersionQuery = {
  __typename?: 'Query'
  getAnnotateProjectVersion?:
    | { __typename?: 'AnnotateProjectVersion'; id: string; version: number }
    | null
    | undefined
}

export type GetKnockUserTokenQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetKnockUserTokenQuery = {
  __typename?: 'Query'
  getKnockUserToken?: string | null | undefined
}

export type GetAnnotateUserQueryVariables = Exact<{ [key: string]: never }>

export type GetAnnotateUserQuery = {
  __typename?: 'Query'
  getAnnotateUser?:
    | {
        __typename?: 'AnnotateUser'
        id: string
        name: string
        email?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAnnoProjectsFromUserQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAnnoProjectsFromUserQuery = {
  __typename?: 'Query'
  getAnnotateUser?:
    | {
        __typename?: 'AnnotateUser'
        projects?:
          | Array<{
              __typename?: 'AnnotateProject'
              id: string
              name: string
              createdAt: string
              updatedAt?: string | null | undefined
              owner: {
                __typename?: 'AnnotateUser'
                id: string
                name: string
                email?: string | null | undefined
              }
            }>
          | null
          | undefined
        invitedProjects?:
          | Array<{
              __typename?: 'AnnotateProject'
              id: string
              name: string
              createdAt: string
              updatedAt?: string | null | undefined
              owner: {
                __typename?: 'AnnotateUser'
                id: string
                name: string
                email?: string | null | undefined
              }
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type CommentAddedSubscriptionVariables = Exact<{
  input: GetComment
}>

export type CommentAddedSubscription = {
  __typename?: 'Subscription'
  commentAdded: {
    __typename?: 'AnnotateComments'
    id: string
    createdAt: string
    text: string
    edited: boolean
    mediaElement: string
    mediaStart: number
    mediaEnd: number
    author?:
      | { __typename?: 'AnnotateUser'; id: string; name: string }
      | null
      | undefined
    resolvedBy?:
      | { __typename?: 'AnnotateUser'; id: string; name: string }
      | null
      | undefined
    projectVersion: { __typename?: 'AnnotateProjectVersion'; version: number }
    parent?: { __typename?: 'AnnotateComments'; id: string } | null | undefined
    children?:
      | Array<{
          __typename?: 'AnnotateComments'
          id: string
          createdAt: string
          text: string
          edited: boolean
          mediaElement: string
          mediaStart: number
          mediaEnd: number
          author?:
            | { __typename?: 'AnnotateUser'; id: string; name: string }
            | null
            | undefined
          projectVersion: {
            __typename?: 'AnnotateProjectVersion'
            version: number
          }
          parent?:
            | { __typename?: 'AnnotateComments'; id: string }
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type CommentUpdatedSubscriptionVariables = Exact<{
  input: GetComment
}>

export type CommentUpdatedSubscription = {
  __typename?: 'Subscription'
  commentUpdated: {
    __typename?: 'AnnotateComments'
    id: string
    createdAt: string
    text: string
    edited: boolean
    mediaElement: string
    mediaStart: number
    mediaEnd: number
    author?:
      | { __typename?: 'AnnotateUser'; id: string; name: string }
      | null
      | undefined
    resolvedBy?:
      | { __typename?: 'AnnotateUser'; id: string; name: string }
      | null
      | undefined
    projectVersion: { __typename?: 'AnnotateProjectVersion'; version: number }
    parent?: { __typename?: 'AnnotateComments'; id: string } | null | undefined
    children?:
      | Array<{
          __typename?: 'AnnotateComments'
          id: string
          createdAt: string
          text: string
          edited: boolean
          mediaElement: string
          mediaStart: number
          mediaEnd: number
          author?:
            | { __typename?: 'AnnotateUser'; id: string; name: string }
            | null
            | undefined
          projectVersion: {
            __typename?: 'AnnotateProjectVersion'
            version: number
          }
          parent?:
            | { __typename?: 'AnnotateComments'; id: string }
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type CommentRemovedSubscriptionVariables = Exact<{
  input: GetComment
}>

export type CommentRemovedSubscription = {
  __typename?: 'Subscription'
  commentRemoved: {
    __typename?: 'AnnotateComments'
    id: string
    createdAt: string
    text: string
    edited: boolean
    mediaElement: string
    mediaStart: number
    mediaEnd: number
    author?:
      | { __typename?: 'AnnotateUser'; id: string; name: string }
      | null
      | undefined
    resolvedBy?:
      | { __typename?: 'AnnotateUser'; id: string; name: string }
      | null
      | undefined
    projectVersion: { __typename?: 'AnnotateProjectVersion'; version: number }
    parent?: { __typename?: 'AnnotateComments'; id: string } | null | undefined
    children?:
      | Array<{
          __typename?: 'AnnotateComments'
          id: string
          createdAt: string
          text: string
          edited: boolean
          mediaElement: string
          mediaStart: number
          mediaEnd: number
          author?:
            | { __typename?: 'AnnotateUser'; id: string; name: string }
            | null
            | undefined
          projectVersion: {
            __typename?: 'AnnotateProjectVersion'
            version: number
          }
          parent?:
            | { __typename?: 'AnnotateComments'; id: string }
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

type ProjectInfo_CreateProjectOutputProject_Fragment = {
  __typename?: 'CreateProjectOutputProject'
  autoId: number
  id: string
  createdAt: string
  projectName: string
  teamId?: number | null | undefined
  ownerId?: number | null | undefined
  thumbnail?: string | null | undefined
  dimensions?: Array<number> | null | undefined
  frameRate?: number | null | undefined
  fileSize?: Array<number> | null | undefined
  duration?: number | null | undefined
  multiplayer?: boolean | null | undefined
}

type ProjectInfo_Project_Fragment = {
  __typename?: 'Project'
  autoId: number
  id: string
  createdAt: string
  projectName: string
  teamId?: number | null | undefined
  ownerId?: number | null | undefined
  thumbnail?: string | null | undefined
  dimensions?: Array<number> | null | undefined
  frameRate?: number | null | undefined
  fileSize?: Array<number> | null | undefined
  duration?: number | null | undefined
  multiplayer?: boolean | null | undefined
}

export type ProjectInfoFragment =
  | ProjectInfo_CreateProjectOutputProject_Fragment
  | ProjectInfo_Project_Fragment

export type UserInfoFragment = {
  __typename?: 'User'
  autoId: number
  id: string
  createdAt: string
  email: string
  username?: string | null | undefined
  type: UserType
}

export type VerifyMutationVariables = Exact<{ [key: string]: never }>

export type VerifyMutation = {
  __typename?: 'Mutation'
  verify: { __typename?: 'VerifyOutput'; valid: boolean; onboarded: boolean }
}

export type MagicMutationVariables = Exact<{
  didToken: Scalars['String']
}>

export type MagicMutation = {
  __typename?: 'Mutation'
  magic: {
    __typename?: 'TokenOutput'
    token: string
    whitelisted?: boolean | null | undefined
    user?:
      | {
          __typename?: 'User'
          autoId: number
          id: string
          createdAt: string
          email: string
          username?: string | null | undefined
          type: UserType
        }
      | null
      | undefined
  }
}

export type ForkMutationVariables = Exact<{
  projectName: Scalars['String']
  username: Scalars['String']
}>

export type ForkMutation = {
  __typename?: 'Mutation'
  fork: { __typename?: 'SuccessOutput'; success: boolean }
}

export type ProjectMutationVariables = Exact<{
  createProjectProps: CreateProjectInput
}>

export type ProjectMutation = {
  __typename?: 'Mutation'
  createProject: {
    __typename?: 'CreateProjectOutput'
    created: boolean
    name: { __typename?: 'ProjectName'; org: string; projectName: string }
  }
}

export type OnboardMutationVariables = Exact<{
  onboardName: Scalars['String']
}>

export type OnboardMutation = {
  __typename?: 'Mutation'
  onboard: { __typename?: 'OnboardOutput'; token: string; userId: string }
}

export type CreateUserPersonaMutationVariables = Exact<{
  persona: UserPersonaInput
}>

export type CreateUserPersonaMutation = {
  __typename?: 'Mutation'
  createUserPersona: boolean
}

export type JoinProjectMutationVariables = Exact<{
  slug: Scalars['String']
}>

export type JoinProjectMutation = {
  __typename?: 'Mutation'
  joinProject: { __typename?: 'JoinOutput'; joined: boolean; url: string }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout: { __typename?: 'SuccessOutput'; success: boolean }
}

export type CreateLinkMutationVariables = Exact<{ [key: string]: never }>

export type CreateLinkMutation = {
  __typename?: 'Mutation'
  createLink: { __typename?: 'CreateLinkOutput'; slug: string }
}

export type DeleteLinksMutationVariables = Exact<{ [key: string]: never }>

export type DeleteLinksMutation = {
  __typename?: 'Mutation'
  deleteLinks: { __typename?: 'SuccessOutput'; success: boolean }
}

export type UpdateProjectThumbnailMutationVariables = Exact<{
  project: UpdateProjectInput
  updateProjectId: Scalars['String']
}>

export type UpdateProjectThumbnailMutation = {
  __typename?: 'Mutation'
  updateProject: { __typename?: 'SuccessOutput'; success: boolean }
}

export type DeleteProjectMutationVariables = Exact<{
  deleteProjectId: Scalars['String']
}>

export type DeleteProjectMutation = {
  __typename?: 'Mutation'
  deleteProject: { __typename?: 'SuccessOutput'; success: boolean }
}

export type CreateSaveStateMutationVariables = Exact<{
  projectId: Scalars['String']
}>

export type CreateSaveStateMutation = {
  __typename?: 'Mutation'
  createSaveState: {
    __typename?: 'CreateSaveStateOutput'
    id: string
    name: string
  }
}

export type PromoteSaveStateMutationVariables = Exact<{
  projectId: Scalars['String']
}>

export type PromoteSaveStateMutation = {
  __typename?: 'Mutation'
  promoteSaveState: { __typename?: 'SuccessOutput'; success: boolean }
}

export type CreateFromSaveFileMutationVariables = Exact<{
  saveFile: Scalars['String']
}>

export type CreateFromSaveFileMutation = {
  __typename?: 'Mutation'
  createFromSaveFile: {
    __typename?: 'CreateFromSaveFileOutput'
    projectName: string
    owner?:
      | { __typename?: 'User'; username?: string | null | undefined }
      | null
      | undefined
  }
}

export type CreateRenderAuthTokenMutationVariables = Exact<{
  [key: string]: never
}>

export type CreateRenderAuthTokenMutation = {
  __typename?: 'Mutation'
  makeRenderAuthToken?:
    | {
        __typename?: 'MakeRenderAuthTokenOutput'
        token: string
        userId: string
      }
    | null
    | undefined
}

export type EditorHomeQueryVariables = Exact<{ [key: string]: never }>

export type EditorHomeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'MeOutput'
    user: {
      __typename?: 'User'
      autoId: number
      id: string
      createdAt: string
      email: string
      username?: string | null | undefined
      type: UserType
    }
    userPersona?:
      | {
          __typename?: 'UserPersona'
          autoId: number
          userId: string
          id: string
          experience: string
          multiplayer: string
        }
      | null
      | undefined
  }
  getUsersProjects: Array<{
    __typename?: 'Project'
    autoId: number
    id: string
    createdAt: string
    lastUpdatedAt: string
    projectName: string
    teamId?: number | null | undefined
    ownerId?: number | null | undefined
    thumbnail?: string | null | undefined
    dimensions?: Array<number> | null | undefined
    frameRate?: number | null | undefined
    fileSize?: Array<number> | null | undefined
    duration?: number | null | undefined
    multiplayer?: boolean | null | undefined
    owner?:
      | {
          __typename?: 'User'
          autoId: number
          id: string
          createdAt: string
          email: string
          username?: string | null | undefined
          type: UserType
        }
      | null
      | undefined
    collaborators?:
      | Array<{
          __typename?: 'User'
          autoId: number
          id: string
          createdAt: string
          email: string
          username?: string | null | undefined
          type: UserType
        }>
      | null
      | undefined
    saveStateChildren?:
      | Array<{
          __typename?: 'Project'
          id: string
          projectName: string
          lastUpdatedAt: string
          thumbnail?: string | null | undefined
          owner?:
            | { __typename?: 'User'; username?: string | null | undefined }
            | null
            | undefined
        }>
      | null
      | undefined
  }>
}

export type GetProjectByNameQueryVariables = Exact<{
  getProjectByNameProjectName2: Scalars['String']
  orgName: Scalars['String']
}>

export type GetProjectByNameQuery = {
  __typename?: 'Query'
  getProjectByName?:
    | {
        __typename?: 'Project'
        autoId: number
        id: string
        createdAt: string
        projectName: string
        teamId?: number | null | undefined
        ownerId?: number | null | undefined
        thumbnail?: string | null | undefined
        dimensions?: Array<number> | null | undefined
        frameRate?: number | null | undefined
        fileSize?: Array<number> | null | undefined
        duration?: number | null | undefined
        multiplayer?: boolean | null | undefined
      }
    | null
    | undefined
}

export type GetLinkQueryVariables = Exact<{ [key: string]: never }>

export type GetLinkQuery = {
  __typename?: 'Query'
  getLink?: { __typename?: 'InviteLinks'; slug: string } | null | undefined
}

export type GetProjectStateQueryVariables = Exact<{
  projectId: Scalars['String']
}>

export type GetProjectStateQuery = {
  __typename?: 'Query'
  projectState?: string | null | undefined
}

export type GetSaveFileDataQueryVariables = Exact<{
  projectId: Scalars['String']
}>

export type GetSaveFileDataQuery = {
  __typename?: 'Query'
  getSaveFileData?: string | null | undefined
}

export const AnnotateCommentFragmentFragmentDoc = gql`
  fragment annotateCommentFragment on AnnotateComments {
    id
    createdAt
    text
    edited
    mediaElement
    mediaStart
    mediaEnd
    author {
      id
      name
    }
    resolvedBy {
      id
      name
    }
    projectVersion {
      version
    }
    parent {
      id
    }
    children {
      id
      createdAt
      text
      edited
      mediaElement
      mediaStart
      mediaEnd
      author {
        id
        name
      }
      projectVersion {
        version
      }
      parent {
        id
      }
    }
  }
`
export const ProjectInfoFragmentDoc = gql`
  fragment projectInfo on ProjectInterface {
    autoId
    id
    createdAt
    projectName
    teamId
    ownerId
    thumbnail
    dimensions
    frameRate
    fileSize
    duration
    multiplayer
  }
`
export const UserInfoFragmentDoc = gql`
  fragment userInfo on User {
    autoId
    id
    createdAt
    email
    username
    type
  }
`
export const CreateCommentDocument = gql`
  mutation CreateComment($input: CommentInput!) {
    createComment(input: $input)
  }
`
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, options)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationResult =
  Apollo.MutationResult<CreateCommentMutation>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>
export const DeleteCommentDocument = gql`
  mutation DeleteComment($id: String!) {
    deleteComment(id: $id)
  }
`
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, options)
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>
export type DeleteCommentMutationResult =
  Apollo.MutationResult<DeleteCommentMutation>
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>
export const UpdateCommentDocument = gql`
  mutation UpdateComment($input: PartialCommentInput!) {
    updateComment(input: $input)
  }
`
export type UpdateCommentMutationFn = Apollo.MutationFunction<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >(UpdateCommentDocument, options)
}
export type UpdateCommentMutationHookResult = ReturnType<
  typeof useUpdateCommentMutation
>
export type UpdateCommentMutationResult =
  Apollo.MutationResult<UpdateCommentMutation>
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>
export const CreateAnnotateProjectDocument = gql`
  mutation CreateAnnotateProject($projectProps: CreateAnnotateProjectInput!) {
    createAnnotateProject(input: $projectProps) {
      id
      version
    }
  }
`
export type CreateAnnotateProjectMutationFn = Apollo.MutationFunction<
  CreateAnnotateProjectMutation,
  CreateAnnotateProjectMutationVariables
>

/**
 * __useCreateAnnotateProjectMutation__
 *
 * To run a mutation, you first call `useCreateAnnotateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnotateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnotateProjectMutation, { data, loading, error }] = useCreateAnnotateProjectMutation({
 *   variables: {
 *      projectProps: // value for 'projectProps'
 *   },
 * });
 */
export function useCreateAnnotateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnnotateProjectMutation,
    CreateAnnotateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAnnotateProjectMutation,
    CreateAnnotateProjectMutationVariables
  >(CreateAnnotateProjectDocument, options)
}
export type CreateAnnotateProjectMutationHookResult = ReturnType<
  typeof useCreateAnnotateProjectMutation
>
export type CreateAnnotateProjectMutationResult =
  Apollo.MutationResult<CreateAnnotateProjectMutation>
export type CreateAnnotateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateAnnotateProjectMutation,
  CreateAnnotateProjectMutationVariables
>
export const CreateAnnotateUserDocument = gql`
  mutation CreateAnnotateUser($userProps: CreateAnnotateUserInput!) {
    createAnnotateUser(input: $userProps) {
      ... on AnnoUserOutput {
        user {
          id
          name
          email
        }
        token
      }
      ... on AnnoLoginNeedAuth {
        needAuth
      }
    }
  }
`
export type CreateAnnotateUserMutationFn = Apollo.MutationFunction<
  CreateAnnotateUserMutation,
  CreateAnnotateUserMutationVariables
>

/**
 * __useCreateAnnotateUserMutation__
 *
 * To run a mutation, you first call `useCreateAnnotateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnotateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnotateUserMutation, { data, loading, error }] = useCreateAnnotateUserMutation({
 *   variables: {
 *      userProps: // value for 'userProps'
 *   },
 * });
 */
export function useCreateAnnotateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnnotateUserMutation,
    CreateAnnotateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAnnotateUserMutation,
    CreateAnnotateUserMutationVariables
  >(CreateAnnotateUserDocument, options)
}
export type CreateAnnotateUserMutationHookResult = ReturnType<
  typeof useCreateAnnotateUserMutation
>
export type CreateAnnotateUserMutationResult =
  Apollo.MutationResult<CreateAnnotateUserMutation>
export type CreateAnnotateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateAnnotateUserMutation,
  CreateAnnotateUserMutationVariables
>
export const UpdateAnnotateProjectStateDocument = gql`
  mutation UpdateAnnotateProjectState(
    $updateProjectState: UpdateAnnotateProjectStateInput!
  ) {
    updateAnnotateProjectState(input: $updateProjectState)
  }
`
export type UpdateAnnotateProjectStateMutationFn = Apollo.MutationFunction<
  UpdateAnnotateProjectStateMutation,
  UpdateAnnotateProjectStateMutationVariables
>

/**
 * __useUpdateAnnotateProjectStateMutation__
 *
 * To run a mutation, you first call `useUpdateAnnotateProjectStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnotateProjectStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnotateProjectStateMutation, { data, loading, error }] = useUpdateAnnotateProjectStateMutation({
 *   variables: {
 *      updateProjectState: // value for 'updateProjectState'
 *   },
 * });
 */
export function useUpdateAnnotateProjectStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnotateProjectStateMutation,
    UpdateAnnotateProjectStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAnnotateProjectStateMutation,
    UpdateAnnotateProjectStateMutationVariables
  >(UpdateAnnotateProjectStateDocument, options)
}
export type UpdateAnnotateProjectStateMutationHookResult = ReturnType<
  typeof useUpdateAnnotateProjectStateMutation
>
export type UpdateAnnotateProjectStateMutationResult =
  Apollo.MutationResult<UpdateAnnotateProjectStateMutation>
export type UpdateAnnotateProjectStateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAnnotateProjectStateMutation,
    UpdateAnnotateProjectStateMutationVariables
  >
export const UpdateAnnotateUserDocument = gql`
  mutation UpdateAnnotateUser($updateUser: UpdateAnnotateUserInput!) {
    updateAnnotateUser(input: $updateUser) {
      ... on AnnoUserOutput {
        user {
          id
          name
          email
        }
        token
      }
      ... on AnnoLoginNeedAuth {
        needAuth
      }
    }
  }
`
export type UpdateAnnotateUserMutationFn = Apollo.MutationFunction<
  UpdateAnnotateUserMutation,
  UpdateAnnotateUserMutationVariables
>

/**
 * __useUpdateAnnotateUserMutation__
 *
 * To run a mutation, you first call `useUpdateAnnotateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnotateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnotateUserMutation, { data, loading, error }] = useUpdateAnnotateUserMutation({
 *   variables: {
 *      updateUser: // value for 'updateUser'
 *   },
 * });
 */
export function useUpdateAnnotateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnotateUserMutation,
    UpdateAnnotateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAnnotateUserMutation,
    UpdateAnnotateUserMutationVariables
  >(UpdateAnnotateUserDocument, options)
}
export type UpdateAnnotateUserMutationHookResult = ReturnType<
  typeof useUpdateAnnotateUserMutation
>
export type UpdateAnnotateUserMutationResult =
  Apollo.MutationResult<UpdateAnnotateUserMutation>
export type UpdateAnnotateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnnotateUserMutation,
  UpdateAnnotateUserMutationVariables
>
export const UpdateAnnotateProjectDocument = gql`
  mutation UpdateAnnotateProject($input: UpdateAnnotateProjectInput!) {
    updateAnnotateProject(input: $input)
  }
`
export type UpdateAnnotateProjectMutationFn = Apollo.MutationFunction<
  UpdateAnnotateProjectMutation,
  UpdateAnnotateProjectMutationVariables
>

/**
 * __useUpdateAnnotateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateAnnotateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnotateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnotateProjectMutation, { data, loading, error }] = useUpdateAnnotateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnnotateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnotateProjectMutation,
    UpdateAnnotateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAnnotateProjectMutation,
    UpdateAnnotateProjectMutationVariables
  >(UpdateAnnotateProjectDocument, options)
}
export type UpdateAnnotateProjectMutationHookResult = ReturnType<
  typeof useUpdateAnnotateProjectMutation
>
export type UpdateAnnotateProjectMutationResult =
  Apollo.MutationResult<UpdateAnnotateProjectMutation>
export type UpdateAnnotateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnnotateProjectMutation,
  UpdateAnnotateProjectMutationVariables
>
export const DeleteAnnotateProjectDocument = gql`
  mutation DeleteAnnotateProject($id: String!) {
    deleteAnnotateProject(id: $id)
  }
`
export type DeleteAnnotateProjectMutationFn = Apollo.MutationFunction<
  DeleteAnnotateProjectMutation,
  DeleteAnnotateProjectMutationVariables
>

/**
 * __useDeleteAnnotateProjectMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotateProjectMutation, { data, loading, error }] = useDeleteAnnotateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnotateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnnotateProjectMutation,
    DeleteAnnotateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAnnotateProjectMutation,
    DeleteAnnotateProjectMutationVariables
  >(DeleteAnnotateProjectDocument, options)
}
export type DeleteAnnotateProjectMutationHookResult = ReturnType<
  typeof useDeleteAnnotateProjectMutation
>
export type DeleteAnnotateProjectMutationResult =
  Apollo.MutationResult<DeleteAnnotateProjectMutation>
export type DeleteAnnotateProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnnotateProjectMutation,
  DeleteAnnotateProjectMutationVariables
>
export const CreateAnnotateProjectVersionDocument = gql`
  mutation CreateAnnotateProjectVersion($input: CreateAnnotateProjectVersion!) {
    createAnnotateProjectVersion(input: $input) {
      id
      version
    }
  }
`
export type CreateAnnotateProjectVersionMutationFn = Apollo.MutationFunction<
  CreateAnnotateProjectVersionMutation,
  CreateAnnotateProjectVersionMutationVariables
>

/**
 * __useCreateAnnotateProjectVersionMutation__
 *
 * To run a mutation, you first call `useCreateAnnotateProjectVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnotateProjectVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnotateProjectVersionMutation, { data, loading, error }] = useCreateAnnotateProjectVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnnotateProjectVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnnotateProjectVersionMutation,
    CreateAnnotateProjectVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAnnotateProjectVersionMutation,
    CreateAnnotateProjectVersionMutationVariables
  >(CreateAnnotateProjectVersionDocument, options)
}
export type CreateAnnotateProjectVersionMutationHookResult = ReturnType<
  typeof useCreateAnnotateProjectVersionMutation
>
export type CreateAnnotateProjectVersionMutationResult =
  Apollo.MutationResult<CreateAnnotateProjectVersionMutation>
export type CreateAnnotateProjectVersionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAnnotateProjectVersionMutation,
    CreateAnnotateProjectVersionMutationVariables
  >
export const AnnoLoginDocument = gql`
  mutation AnnoLogin($input: AnnoLoginInput!) {
    annoLogin(input: $input) {
      ... on AnnoLoginSuccess {
        user {
          id
          name
          email
        }
        token
      }
      ... on AnnoLoginNeedAuth {
        needAuth
      }
    }
  }
`
export type AnnoLoginMutationFn = Apollo.MutationFunction<
  AnnoLoginMutation,
  AnnoLoginMutationVariables
>

/**
 * __useAnnoLoginMutation__
 *
 * To run a mutation, you first call `useAnnoLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnoLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [annoLoginMutation, { data, loading, error }] = useAnnoLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnoLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnnoLoginMutation,
    AnnoLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AnnoLoginMutation, AnnoLoginMutationVariables>(
    AnnoLoginDocument,
    options
  )
}
export type AnnoLoginMutationHookResult = ReturnType<
  typeof useAnnoLoginMutation
>
export type AnnoLoginMutationResult = Apollo.MutationResult<AnnoLoginMutation>
export type AnnoLoginMutationOptions = Apollo.BaseMutationOptions<
  AnnoLoginMutation,
  AnnoLoginMutationVariables
>
export const GetCommentsDocument = gql`
  query GetComments($input: GetComment!) {
    getComments(input: $input) {
      ...annotateCommentFragment
    }
  }
  ${AnnotateCommentFragmentFragmentDoc}
`

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentsQuery,
    GetCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(
    GetCommentsDocument,
    options
  )
}
export function useGetCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsQuery,
    GetCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(
    GetCommentsDocument,
    options
  )
}
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>
export type GetCommentsLazyQueryHookResult = ReturnType<
  typeof useGetCommentsLazyQuery
>
export type GetCommentsQueryResult = Apollo.QueryResult<
  GetCommentsQuery,
  GetCommentsQueryVariables
>
export const AnnotateProjectDocument = gql`
  query AnnotateProject($projectId: String!) {
    getAnnotateProject(id: $projectId) {
      id
      name
      mediaId
      currentVersion
      versions {
        id
        version
      }
      owner {
        id
      }
      collaborators {
        id
        name
        email
      }
    }
  }
`

/**
 * __useAnnotateProjectQuery__
 *
 * To run a query within a React component, call `useAnnotateProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotateProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotateProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAnnotateProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnnotateProjectQuery,
    AnnotateProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AnnotateProjectQuery, AnnotateProjectQueryVariables>(
    AnnotateProjectDocument,
    options
  )
}
export function useAnnotateProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnotateProjectQuery,
    AnnotateProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AnnotateProjectQuery,
    AnnotateProjectQueryVariables
  >(AnnotateProjectDocument, options)
}
export type AnnotateProjectQueryHookResult = ReturnType<
  typeof useAnnotateProjectQuery
>
export type AnnotateProjectLazyQueryHookResult = ReturnType<
  typeof useAnnotateProjectLazyQuery
>
export type AnnotateProjectQueryResult = Apollo.QueryResult<
  AnnotateProjectQuery,
  AnnotateProjectQueryVariables
>
export const AnnotateProjectStateDocument = gql`
  query AnnotateProjectState($input: GetAnnotateProjectStateInput!) {
    getAnnotateProjectState(input: $input)
  }
`

/**
 * __useAnnotateProjectStateQuery__
 *
 * To run a query within a React component, call `useAnnotateProjectStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotateProjectStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotateProjectStateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnotateProjectStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnnotateProjectStateQuery,
    AnnotateProjectStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AnnotateProjectStateQuery,
    AnnotateProjectStateQueryVariables
  >(AnnotateProjectStateDocument, options)
}
export function useAnnotateProjectStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnotateProjectStateQuery,
    AnnotateProjectStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AnnotateProjectStateQuery,
    AnnotateProjectStateQueryVariables
  >(AnnotateProjectStateDocument, options)
}
export type AnnotateProjectStateQueryHookResult = ReturnType<
  typeof useAnnotateProjectStateQuery
>
export type AnnotateProjectStateLazyQueryHookResult = ReturnType<
  typeof useAnnotateProjectStateLazyQuery
>
export type AnnotateProjectStateQueryResult = Apollo.QueryResult<
  AnnotateProjectStateQuery,
  AnnotateProjectStateQueryVariables
>
export const GetAnnotateProjectVersionDocument = gql`
  query GetAnnotateProjectVersion($input: GetAnnotateProjectVersionInput!) {
    getAnnotateProjectVersion(input: $input) {
      id
      version
    }
  }
`

/**
 * __useGetAnnotateProjectVersionQuery__
 *
 * To run a query within a React component, call `useGetAnnotateProjectVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnotateProjectVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnotateProjectVersionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAnnotateProjectVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnnotateProjectVersionQuery,
    GetAnnotateProjectVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAnnotateProjectVersionQuery,
    GetAnnotateProjectVersionQueryVariables
  >(GetAnnotateProjectVersionDocument, options)
}
export function useGetAnnotateProjectVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnnotateProjectVersionQuery,
    GetAnnotateProjectVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAnnotateProjectVersionQuery,
    GetAnnotateProjectVersionQueryVariables
  >(GetAnnotateProjectVersionDocument, options)
}
export type GetAnnotateProjectVersionQueryHookResult = ReturnType<
  typeof useGetAnnotateProjectVersionQuery
>
export type GetAnnotateProjectVersionLazyQueryHookResult = ReturnType<
  typeof useGetAnnotateProjectVersionLazyQuery
>
export type GetAnnotateProjectVersionQueryResult = Apollo.QueryResult<
  GetAnnotateProjectVersionQuery,
  GetAnnotateProjectVersionQueryVariables
>
export const GetKnockUserTokenDocument = gql`
  query GetKnockUserToken($userId: String!) {
    getKnockUserToken(id: $userId)
  }
`

/**
 * __useGetKnockUserTokenQuery__
 *
 * To run a query within a React component, call `useGetKnockUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnockUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnockUserTokenQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetKnockUserTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKnockUserTokenQuery,
    GetKnockUserTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetKnockUserTokenQuery,
    GetKnockUserTokenQueryVariables
  >(GetKnockUserTokenDocument, options)
}
export function useGetKnockUserTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKnockUserTokenQuery,
    GetKnockUserTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetKnockUserTokenQuery,
    GetKnockUserTokenQueryVariables
  >(GetKnockUserTokenDocument, options)
}
export type GetKnockUserTokenQueryHookResult = ReturnType<
  typeof useGetKnockUserTokenQuery
>
export type GetKnockUserTokenLazyQueryHookResult = ReturnType<
  typeof useGetKnockUserTokenLazyQuery
>
export type GetKnockUserTokenQueryResult = Apollo.QueryResult<
  GetKnockUserTokenQuery,
  GetKnockUserTokenQueryVariables
>
export const GetAnnotateUserDocument = gql`
  query GetAnnotateUser {
    getAnnotateUser {
      id
      name
      email
    }
  }
`

/**
 * __useGetAnnotateUserQuery__
 *
 * To run a query within a React component, call `useGetAnnotateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnotateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnotateUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnotateUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnnotateUserQuery,
    GetAnnotateUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAnnotateUserQuery, GetAnnotateUserQueryVariables>(
    GetAnnotateUserDocument,
    options
  )
}
export function useGetAnnotateUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnnotateUserQuery,
    GetAnnotateUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAnnotateUserQuery,
    GetAnnotateUserQueryVariables
  >(GetAnnotateUserDocument, options)
}
export type GetAnnotateUserQueryHookResult = ReturnType<
  typeof useGetAnnotateUserQuery
>
export type GetAnnotateUserLazyQueryHookResult = ReturnType<
  typeof useGetAnnotateUserLazyQuery
>
export type GetAnnotateUserQueryResult = Apollo.QueryResult<
  GetAnnotateUserQuery,
  GetAnnotateUserQueryVariables
>
export const GetAnnoProjectsFromUserDocument = gql`
  query GetAnnoProjectsFromUser {
    getAnnotateUser {
      projects {
        id
        name
        createdAt
        updatedAt
        owner {
          id
          name
          email
        }
      }
      invitedProjects {
        id
        name
        createdAt
        updatedAt
        owner {
          id
          name
          email
        }
      }
    }
  }
`

/**
 * __useGetAnnoProjectsFromUserQuery__
 *
 * To run a query within a React component, call `useGetAnnoProjectsFromUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnoProjectsFromUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnoProjectsFromUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnoProjectsFromUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnnoProjectsFromUserQuery,
    GetAnnoProjectsFromUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAnnoProjectsFromUserQuery,
    GetAnnoProjectsFromUserQueryVariables
  >(GetAnnoProjectsFromUserDocument, options)
}
export function useGetAnnoProjectsFromUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnnoProjectsFromUserQuery,
    GetAnnoProjectsFromUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAnnoProjectsFromUserQuery,
    GetAnnoProjectsFromUserQueryVariables
  >(GetAnnoProjectsFromUserDocument, options)
}
export type GetAnnoProjectsFromUserQueryHookResult = ReturnType<
  typeof useGetAnnoProjectsFromUserQuery
>
export type GetAnnoProjectsFromUserLazyQueryHookResult = ReturnType<
  typeof useGetAnnoProjectsFromUserLazyQuery
>
export type GetAnnoProjectsFromUserQueryResult = Apollo.QueryResult<
  GetAnnoProjectsFromUserQuery,
  GetAnnoProjectsFromUserQueryVariables
>
export const CommentAddedDocument = gql`
  subscription CommentAdded($input: GetComment!) {
    commentAdded(input: $input) {
      ...annotateCommentFragment
    }
  }
  ${AnnotateCommentFragmentFragmentDoc}
`

/**
 * __useCommentAddedSubscription__
 *
 * To run a query within a React component, call `useCommentAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentAddedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentAddedSubscription,
    CommentAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CommentAddedSubscription,
    CommentAddedSubscriptionVariables
  >(CommentAddedDocument, options)
}
export type CommentAddedSubscriptionHookResult = ReturnType<
  typeof useCommentAddedSubscription
>
export type CommentAddedSubscriptionResult =
  Apollo.SubscriptionResult<CommentAddedSubscription>
export const CommentUpdatedDocument = gql`
  subscription CommentUpdated($input: GetComment!) {
    commentUpdated(input: $input) {
      ...annotateCommentFragment
    }
  }
  ${AnnotateCommentFragmentFragmentDoc}
`

/**
 * __useCommentUpdatedSubscription__
 *
 * To run a query within a React component, call `useCommentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentUpdatedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentUpdatedSubscription,
    CommentUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CommentUpdatedSubscription,
    CommentUpdatedSubscriptionVariables
  >(CommentUpdatedDocument, options)
}
export type CommentUpdatedSubscriptionHookResult = ReturnType<
  typeof useCommentUpdatedSubscription
>
export type CommentUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<CommentUpdatedSubscription>
export const CommentRemovedDocument = gql`
  subscription CommentRemoved($input: GetComment!) {
    commentRemoved(input: $input) {
      ...annotateCommentFragment
    }
  }
  ${AnnotateCommentFragmentFragmentDoc}
`

/**
 * __useCommentRemovedSubscription__
 *
 * To run a query within a React component, call `useCommentRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentRemovedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentRemovedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentRemovedSubscription,
    CommentRemovedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CommentRemovedSubscription,
    CommentRemovedSubscriptionVariables
  >(CommentRemovedDocument, options)
}
export type CommentRemovedSubscriptionHookResult = ReturnType<
  typeof useCommentRemovedSubscription
>
export type CommentRemovedSubscriptionResult =
  Apollo.SubscriptionResult<CommentRemovedSubscription>
export const VerifyDocument = gql`
  mutation Verify {
    verify {
      valid
      onboarded
    }
  }
`
export type VerifyMutationFn = Apollo.MutationFunction<
  VerifyMutation,
  VerifyMutationVariables
>

/**
 * __useVerifyMutation__
 *
 * To run a mutation, you first call `useVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMutation, { data, loading, error }] = useVerifyMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMutation,
    VerifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyMutation, VerifyMutationVariables>(
    VerifyDocument,
    options
  )
}
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>
export type VerifyMutationResult = Apollo.MutationResult<VerifyMutation>
export type VerifyMutationOptions = Apollo.BaseMutationOptions<
  VerifyMutation,
  VerifyMutationVariables
>
export const MagicDocument = gql`
  mutation Magic($didToken: String!) {
    magic(didToken: $didToken) {
      token
      whitelisted
      user {
        ...userInfo
      }
    }
  }
  ${UserInfoFragmentDoc}
`
export type MagicMutationFn = Apollo.MutationFunction<
  MagicMutation,
  MagicMutationVariables
>

/**
 * __useMagicMutation__
 *
 * To run a mutation, you first call `useMagicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magicMutation, { data, loading, error }] = useMagicMutation({
 *   variables: {
 *      didToken: // value for 'didToken'
 *   },
 * });
 */
export function useMagicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MagicMutation,
    MagicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MagicMutation, MagicMutationVariables>(
    MagicDocument,
    options
  )
}
export type MagicMutationHookResult = ReturnType<typeof useMagicMutation>
export type MagicMutationResult = Apollo.MutationResult<MagicMutation>
export type MagicMutationOptions = Apollo.BaseMutationOptions<
  MagicMutation,
  MagicMutationVariables
>
export const ForkDocument = gql`
  mutation Fork($projectName: String!, $username: String!) {
    fork(projectName: $projectName, username: $username) {
      success
    }
  }
`
export type ForkMutationFn = Apollo.MutationFunction<
  ForkMutation,
  ForkMutationVariables
>

/**
 * __useForkMutation__
 *
 * To run a mutation, you first call `useForkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forkMutation, { data, loading, error }] = useForkMutation({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useForkMutation(
  baseOptions?: Apollo.MutationHookOptions<ForkMutation, ForkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ForkMutation, ForkMutationVariables>(
    ForkDocument,
    options
  )
}
export type ForkMutationHookResult = ReturnType<typeof useForkMutation>
export type ForkMutationResult = Apollo.MutationResult<ForkMutation>
export type ForkMutationOptions = Apollo.BaseMutationOptions<
  ForkMutation,
  ForkMutationVariables
>
export const ProjectDocument = gql`
  mutation Project($createProjectProps: CreateProjectInput!) {
    createProject(input: $createProjectProps) {
      created
      name {
        org
        projectName
      }
    }
  }
`
export type ProjectMutationFn = Apollo.MutationFunction<
  ProjectMutation,
  ProjectMutationVariables
>

/**
 * __useProjectMutation__
 *
 * To run a mutation, you first call `useProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectMutation, { data, loading, error }] = useProjectMutation({
 *   variables: {
 *      createProjectProps: // value for 'createProjectProps'
 *   },
 * });
 */
export function useProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProjectMutation,
    ProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ProjectMutation, ProjectMutationVariables>(
    ProjectDocument,
    options
  )
}
export type ProjectMutationHookResult = ReturnType<typeof useProjectMutation>
export type ProjectMutationResult = Apollo.MutationResult<ProjectMutation>
export type ProjectMutationOptions = Apollo.BaseMutationOptions<
  ProjectMutation,
  ProjectMutationVariables
>
export const OnboardDocument = gql`
  mutation Onboard($onboardName: String!) {
    onboard(name: $onboardName) {
      token
      userId
    }
  }
`
export type OnboardMutationFn = Apollo.MutationFunction<
  OnboardMutation,
  OnboardMutationVariables
>

/**
 * __useOnboardMutation__
 *
 * To run a mutation, you first call `useOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardMutation, { data, loading, error }] = useOnboardMutation({
 *   variables: {
 *      onboardName: // value for 'onboardName'
 *   },
 * });
 */
export function useOnboardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardMutation,
    OnboardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<OnboardMutation, OnboardMutationVariables>(
    OnboardDocument,
    options
  )
}
export type OnboardMutationHookResult = ReturnType<typeof useOnboardMutation>
export type OnboardMutationResult = Apollo.MutationResult<OnboardMutation>
export type OnboardMutationOptions = Apollo.BaseMutationOptions<
  OnboardMutation,
  OnboardMutationVariables
>
export const CreateUserPersonaDocument = gql`
  mutation CreateUserPersona($persona: UserPersonaInput!) {
    createUserPersona(persona: $persona)
  }
`
export type CreateUserPersonaMutationFn = Apollo.MutationFunction<
  CreateUserPersonaMutation,
  CreateUserPersonaMutationVariables
>

/**
 * __useCreateUserPersonaMutation__
 *
 * To run a mutation, you first call `useCreateUserPersonaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPersonaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPersonaMutation, { data, loading, error }] = useCreateUserPersonaMutation({
 *   variables: {
 *      persona: // value for 'persona'
 *   },
 * });
 */
export function useCreateUserPersonaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserPersonaMutation,
    CreateUserPersonaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserPersonaMutation,
    CreateUserPersonaMutationVariables
  >(CreateUserPersonaDocument, options)
}
export type CreateUserPersonaMutationHookResult = ReturnType<
  typeof useCreateUserPersonaMutation
>
export type CreateUserPersonaMutationResult =
  Apollo.MutationResult<CreateUserPersonaMutation>
export type CreateUserPersonaMutationOptions = Apollo.BaseMutationOptions<
  CreateUserPersonaMutation,
  CreateUserPersonaMutationVariables
>
export const JoinProjectDocument = gql`
  mutation JoinProject($slug: String!) {
    joinProject(slug: $slug) {
      joined
      url
    }
  }
`
export type JoinProjectMutationFn = Apollo.MutationFunction<
  JoinProjectMutation,
  JoinProjectMutationVariables
>

/**
 * __useJoinProjectMutation__
 *
 * To run a mutation, you first call `useJoinProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinProjectMutation, { data, loading, error }] = useJoinProjectMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useJoinProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinProjectMutation,
    JoinProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<JoinProjectMutation, JoinProjectMutationVariables>(
    JoinProjectDocument,
    options
  )
}
export type JoinProjectMutationHookResult = ReturnType<
  typeof useJoinProjectMutation
>
export type JoinProjectMutationResult =
  Apollo.MutationResult<JoinProjectMutation>
export type JoinProjectMutationOptions = Apollo.BaseMutationOptions<
  JoinProjectMutation,
  JoinProjectMutationVariables
>
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      success
    }
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  )
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>
export const CreateLinkDocument = gql`
  mutation CreateLink {
    createLink {
      slug
    }
  }
`
export type CreateLinkMutationFn = Apollo.MutationFunction<
  CreateLinkMutation,
  CreateLinkMutationVariables
>

/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLinkMutation,
    CreateLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLinkMutation, CreateLinkMutationVariables>(
    CreateLinkDocument,
    options
  )
}
export type CreateLinkMutationHookResult = ReturnType<
  typeof useCreateLinkMutation
>
export type CreateLinkMutationResult = Apollo.MutationResult<CreateLinkMutation>
export type CreateLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkMutation,
  CreateLinkMutationVariables
>
export const DeleteLinksDocument = gql`
  mutation DeleteLinks {
    deleteLinks {
      success
    }
  }
`
export type DeleteLinksMutationFn = Apollo.MutationFunction<
  DeleteLinksMutation,
  DeleteLinksMutationVariables
>

/**
 * __useDeleteLinksMutation__
 *
 * To run a mutation, you first call `useDeleteLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinksMutation, { data, loading, error }] = useDeleteLinksMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteLinksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLinksMutation,
    DeleteLinksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteLinksMutation, DeleteLinksMutationVariables>(
    DeleteLinksDocument,
    options
  )
}
export type DeleteLinksMutationHookResult = ReturnType<
  typeof useDeleteLinksMutation
>
export type DeleteLinksMutationResult =
  Apollo.MutationResult<DeleteLinksMutation>
export type DeleteLinksMutationOptions = Apollo.BaseMutationOptions<
  DeleteLinksMutation,
  DeleteLinksMutationVariables
>
export const UpdateProjectThumbnailDocument = gql`
  mutation UpdateProjectThumbnail(
    $project: UpdateProjectInput!
    $updateProjectId: String!
  ) {
    updateProject(project: $project, id: $updateProjectId) {
      success
    }
  }
`
export type UpdateProjectThumbnailMutationFn = Apollo.MutationFunction<
  UpdateProjectThumbnailMutation,
  UpdateProjectThumbnailMutationVariables
>

/**
 * __useUpdateProjectThumbnailMutation__
 *
 * To run a mutation, you first call `useUpdateProjectThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectThumbnailMutation, { data, loading, error }] = useUpdateProjectThumbnailMutation({
 *   variables: {
 *      project: // value for 'project'
 *      updateProjectId: // value for 'updateProjectId'
 *   },
 * });
 */
export function useUpdateProjectThumbnailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectThumbnailMutation,
    UpdateProjectThumbnailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProjectThumbnailMutation,
    UpdateProjectThumbnailMutationVariables
  >(UpdateProjectThumbnailDocument, options)
}
export type UpdateProjectThumbnailMutationHookResult = ReturnType<
  typeof useUpdateProjectThumbnailMutation
>
export type UpdateProjectThumbnailMutationResult =
  Apollo.MutationResult<UpdateProjectThumbnailMutation>
export type UpdateProjectThumbnailMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectThumbnailMutation,
  UpdateProjectThumbnailMutationVariables
>
export const DeleteProjectDocument = gql`
  mutation DeleteProject($deleteProjectId: String!) {
    deleteProject(id: $deleteProjectId) {
      success
    }
  }
`
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      deleteProjectId: // value for 'deleteProjectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options)
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>
export const CreateSaveStateDocument = gql`
  mutation CreateSaveState($projectId: String!) {
    createSaveState(projectId: $projectId) {
      id
      name
    }
  }
`
export type CreateSaveStateMutationFn = Apollo.MutationFunction<
  CreateSaveStateMutation,
  CreateSaveStateMutationVariables
>

/**
 * __useCreateSaveStateMutation__
 *
 * To run a mutation, you first call `useCreateSaveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaveStateMutation, { data, loading, error }] = useCreateSaveStateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateSaveStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSaveStateMutation,
    CreateSaveStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSaveStateMutation,
    CreateSaveStateMutationVariables
  >(CreateSaveStateDocument, options)
}
export type CreateSaveStateMutationHookResult = ReturnType<
  typeof useCreateSaveStateMutation
>
export type CreateSaveStateMutationResult =
  Apollo.MutationResult<CreateSaveStateMutation>
export type CreateSaveStateMutationOptions = Apollo.BaseMutationOptions<
  CreateSaveStateMutation,
  CreateSaveStateMutationVariables
>
export const PromoteSaveStateDocument = gql`
  mutation PromoteSaveState($projectId: String!) {
    promoteSaveState(projectId: $projectId) {
      success
    }
  }
`
export type PromoteSaveStateMutationFn = Apollo.MutationFunction<
  PromoteSaveStateMutation,
  PromoteSaveStateMutationVariables
>

/**
 * __usePromoteSaveStateMutation__
 *
 * To run a mutation, you first call `usePromoteSaveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteSaveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteSaveStateMutation, { data, loading, error }] = usePromoteSaveStateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePromoteSaveStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteSaveStateMutation,
    PromoteSaveStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PromoteSaveStateMutation,
    PromoteSaveStateMutationVariables
  >(PromoteSaveStateDocument, options)
}
export type PromoteSaveStateMutationHookResult = ReturnType<
  typeof usePromoteSaveStateMutation
>
export type PromoteSaveStateMutationResult =
  Apollo.MutationResult<PromoteSaveStateMutation>
export type PromoteSaveStateMutationOptions = Apollo.BaseMutationOptions<
  PromoteSaveStateMutation,
  PromoteSaveStateMutationVariables
>
export const CreateFromSaveFileDocument = gql`
  mutation CreateFromSaveFile($saveFile: String!) {
    createFromSaveFile(saveFile: $saveFile) {
      projectName
      owner {
        username
      }
    }
  }
`
export type CreateFromSaveFileMutationFn = Apollo.MutationFunction<
  CreateFromSaveFileMutation,
  CreateFromSaveFileMutationVariables
>

/**
 * __useCreateFromSaveFileMutation__
 *
 * To run a mutation, you first call `useCreateFromSaveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFromSaveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFromSaveFileMutation, { data, loading, error }] = useCreateFromSaveFileMutation({
 *   variables: {
 *      saveFile: // value for 'saveFile'
 *   },
 * });
 */
export function useCreateFromSaveFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFromSaveFileMutation,
    CreateFromSaveFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFromSaveFileMutation,
    CreateFromSaveFileMutationVariables
  >(CreateFromSaveFileDocument, options)
}
export type CreateFromSaveFileMutationHookResult = ReturnType<
  typeof useCreateFromSaveFileMutation
>
export type CreateFromSaveFileMutationResult =
  Apollo.MutationResult<CreateFromSaveFileMutation>
export type CreateFromSaveFileMutationOptions = Apollo.BaseMutationOptions<
  CreateFromSaveFileMutation,
  CreateFromSaveFileMutationVariables
>
export const CreateRenderAuthTokenDocument = gql`
  mutation CreateRenderAuthToken {
    makeRenderAuthToken {
      token
      userId
    }
  }
`
export type CreateRenderAuthTokenMutationFn = Apollo.MutationFunction<
  CreateRenderAuthTokenMutation,
  CreateRenderAuthTokenMutationVariables
>

/**
 * __useCreateRenderAuthTokenMutation__
 *
 * To run a mutation, you first call `useCreateRenderAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRenderAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRenderAuthTokenMutation, { data, loading, error }] = useCreateRenderAuthTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateRenderAuthTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRenderAuthTokenMutation,
    CreateRenderAuthTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRenderAuthTokenMutation,
    CreateRenderAuthTokenMutationVariables
  >(CreateRenderAuthTokenDocument, options)
}
export type CreateRenderAuthTokenMutationHookResult = ReturnType<
  typeof useCreateRenderAuthTokenMutation
>
export type CreateRenderAuthTokenMutationResult =
  Apollo.MutationResult<CreateRenderAuthTokenMutation>
export type CreateRenderAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateRenderAuthTokenMutation,
  CreateRenderAuthTokenMutationVariables
>
export const EditorHomeDocument = gql`
  query EditorHome {
    me {
      user {
        autoId
        id
        createdAt
        email
        username
        type
      }
      userPersona {
        autoId
        userId
        id
        experience
        multiplayer
      }
    }
    getUsersProjects {
      autoId
      id
      createdAt
      lastUpdatedAt
      projectName
      teamId
      ownerId
      thumbnail
      dimensions
      frameRate
      fileSize
      duration
      multiplayer
      owner {
        ...userInfo
      }
      collaborators {
        ...userInfo
      }
      saveStateChildren {
        id
        projectName
        lastUpdatedAt
        owner {
          username
        }
        thumbnail
      }
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useEditorHomeQuery__
 *
 * To run a query within a React component, call `useEditorHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorHomeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditorHomeQuery,
    EditorHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EditorHomeQuery, EditorHomeQueryVariables>(
    EditorHomeDocument,
    options
  )
}
export function useEditorHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditorHomeQuery,
    EditorHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EditorHomeQuery, EditorHomeQueryVariables>(
    EditorHomeDocument,
    options
  )
}
export type EditorHomeQueryHookResult = ReturnType<typeof useEditorHomeQuery>
export type EditorHomeLazyQueryHookResult = ReturnType<
  typeof useEditorHomeLazyQuery
>
export type EditorHomeQueryResult = Apollo.QueryResult<
  EditorHomeQuery,
  EditorHomeQueryVariables
>
export const GetProjectByNameDocument = gql`
  query GetProjectByName(
    $getProjectByNameProjectName2: String!
    $orgName: String!
  ) {
    getProjectByName(
      projectName: $getProjectByNameProjectName2
      orgName: $orgName
    ) {
      autoId
      id
      createdAt
      projectName
      teamId
      ownerId
      thumbnail
      dimensions
      frameRate
      fileSize
      duration
      multiplayer
    }
  }
`

/**
 * __useGetProjectByNameQuery__
 *
 * To run a query within a React component, call `useGetProjectByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByNameQuery({
 *   variables: {
 *      getProjectByNameProjectName2: // value for 'getProjectByNameProjectName2'
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useGetProjectByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectByNameQuery,
    GetProjectByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectByNameQuery, GetProjectByNameQueryVariables>(
    GetProjectByNameDocument,
    options
  )
}
export function useGetProjectByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectByNameQuery,
    GetProjectByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProjectByNameQuery,
    GetProjectByNameQueryVariables
  >(GetProjectByNameDocument, options)
}
export type GetProjectByNameQueryHookResult = ReturnType<
  typeof useGetProjectByNameQuery
>
export type GetProjectByNameLazyQueryHookResult = ReturnType<
  typeof useGetProjectByNameLazyQuery
>
export type GetProjectByNameQueryResult = Apollo.QueryResult<
  GetProjectByNameQuery,
  GetProjectByNameQueryVariables
>
export const GetLinkDocument = gql`
  query GetLink {
    getLink {
      slug
    }
  }
`

/**
 * __useGetLinkQuery__
 *
 * To run a query within a React component, call `useGetLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLinkQuery, GetLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLinkQuery, GetLinkQueryVariables>(
    GetLinkDocument,
    options
  )
}
export function useGetLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLinkQuery, GetLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLinkQuery, GetLinkQueryVariables>(
    GetLinkDocument,
    options
  )
}
export type GetLinkQueryHookResult = ReturnType<typeof useGetLinkQuery>
export type GetLinkLazyQueryHookResult = ReturnType<typeof useGetLinkLazyQuery>
export type GetLinkQueryResult = Apollo.QueryResult<
  GetLinkQuery,
  GetLinkQueryVariables
>
export const GetProjectStateDocument = gql`
  query GetProjectState($projectId: String!) {
    projectState(projectId: $projectId)
  }
`

/**
 * __useGetProjectStateQuery__
 *
 * To run a query within a React component, call `useGetProjectStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStateQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectStateQuery,
    GetProjectStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectStateQuery, GetProjectStateQueryVariables>(
    GetProjectStateDocument,
    options
  )
}
export function useGetProjectStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectStateQuery,
    GetProjectStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProjectStateQuery,
    GetProjectStateQueryVariables
  >(GetProjectStateDocument, options)
}
export type GetProjectStateQueryHookResult = ReturnType<
  typeof useGetProjectStateQuery
>
export type GetProjectStateLazyQueryHookResult = ReturnType<
  typeof useGetProjectStateLazyQuery
>
export type GetProjectStateQueryResult = Apollo.QueryResult<
  GetProjectStateQuery,
  GetProjectStateQueryVariables
>
export const GetSaveFileDataDocument = gql`
  query GetSaveFileData($projectId: String!) {
    getSaveFileData(projectId: $projectId)
  }
`

/**
 * __useGetSaveFileDataQuery__
 *
 * To run a query within a React component, call `useGetSaveFileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaveFileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaveFileDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSaveFileDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSaveFileDataQuery,
    GetSaveFileDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSaveFileDataQuery, GetSaveFileDataQueryVariables>(
    GetSaveFileDataDocument,
    options
  )
}
export function useGetSaveFileDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSaveFileDataQuery,
    GetSaveFileDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSaveFileDataQuery,
    GetSaveFileDataQueryVariables
  >(GetSaveFileDataDocument, options)
}
export type GetSaveFileDataQueryHookResult = ReturnType<
  typeof useGetSaveFileDataQuery
>
export type GetSaveFileDataLazyQueryHookResult = ReturnType<
  typeof useGetSaveFileDataLazyQuery
>
export type GetSaveFileDataQueryResult = Apollo.QueryResult<
  GetSaveFileDataQuery,
  GetSaveFileDataQueryVariables
>
