import toast from 'react-hot-toast'

import { MediaElements } from '@editor/interfaces'

export function warnError(...args: any[]) {
  console.warn(...args)
}

export const toastError = (msg: string) => {
  toast.error(msg)
}

export const log = (...args: any[]) => {
  console.log(...args)

  return JSON.stringify([...args])
}

/**
 * This is a common problem in the UI so making it into a function so it's easily searchable
 *
 * Ideally we should not throw an error and instead have the UI show something when the media element
 * is nto defined
 *
 * Example, media element timeline element should have a greyed out element.
 */
export const mediaElementNotDefinedError = (id: string) =>
  new Error(`Media element not defined ${id}`)

export const checkMediaElementExists = (
  mediaElements: MediaElements,
  key: string
) => {
  const mediaElement = mediaElements[key]

  if (!mediaElement) throw new Error('Could not find mediaElement')

  return mediaElement
}

export const useThrowError = <Return>(
  useHook: Return,
  msg: string
): NonNullable<Return> => {
  const value = useHook

  if (!value) throw new Error(msg)

  return value!
}
