export const base64ToUint8Array = (base64: string) => {
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}
export function uint8ArrayToBase64(bytes: Uint8Array) {
  let binary = ''
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    // @ts-expect-error noUncheckedIndexedAccess arrayIndexAccess
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}
