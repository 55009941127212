import fetch from 'cross-fetch'
import { createClient } from 'graphql-ws'
import React from 'react'
import Cookies from 'universal-cookie'

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'

const baseURL =
  process.env.NODE_ENV === 'development'
    ? `${process.env.NEXT_PUBLIC_HOST || 'localhost'}:5000/graphql`
    : 'api.editor.modfy.video/graphql'

const httpLink = new HttpLink({
  uri: `${
    process.env.NODE_ENV === 'development' ? 'http' : 'https'
  }://${baseURL}`,
  fetch: (uri: RequestInfo | URL, options?: RequestInit | undefined) => {
    const cookies = new Cookies()
    // uri gives a bs error on tsc, so just type it as this
    return fetch(uri as RequestInfo, {
      ...options,
      headers: {
        ...(options?.headers || {}),
        token: cookies.get('token') || ''
      }
    })
  },
  credentials: 'include'
})

const wssUri = `${
  process.env.NODE_ENV === 'development' ? 'ws' : 'wss'
}://${baseURL}`

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value

const generateSplit = () => {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wssUri
    })
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  return splitLink
}

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       ...generateGraphqlHeaders()
//     }
//   }
// })

export const client = new ApolloClient({
  link: typeof window === 'undefined' ? httpLink : generateSplit(),
  cache: new InMemoryCache()
  // ssrMode: typeof window === 'undefined'
})

export const Apollo = ({ children }: { children: React.ReactNode }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default Apollo
