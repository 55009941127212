import { Cookies } from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'

/**
 * Set cookie on the correct domain
 */
export const setCookie = (
  name: string,
  value: string,
  options?: Omit<CookieSetOptions, 'domain'>
) => {
  const cookies = new Cookies()

  const isModfyVideoDomain = window.location.host.includes('modfy.video')

  cookies.set(name, value, {
    ...options,
    // set it on the modfy domain everywhere
    domain: isModfyVideoDomain ? '.modfy.video' : undefined
  })
}

/**
 * Remove the cookie from correct domain(s)
 */
export const removeCookie = (
  name: string,
  options?: Omit<CookieSetOptions, 'domain'>
) => {
  const cookies = new Cookies()

  const isModfyVideoDomain = window.location.host.includes('modfy.video')

  cookies.remove(name, { ...options })
  if (isModfyVideoDomain)
    cookies.remove(name, { ...options, domain: '.modfy.video' })
}

/**
 * Sets the token cookie with 30 days expiry and correct domain
 */
export const setTokenCookie = (token: string) => {
  const expires = Date.now() + 1000 * 60 * 60 * 24 * 30 // expires in 30 days (in ms)

  setCookie('token', token, { expires: new Date(expires) })
}

/**
 * Removes the token cookie form correct domain
 */
export const removeTokenCookie = () => {
  removeCookie('token')
}
