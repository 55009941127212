import { Leva, useControls } from 'leva'
import { observer } from 'mobx-react'
import React from 'react'

import { modeStore } from '@editor/state'

import { useProgrammaticSchema } from './adminStore'

export const LevaConfig = observer(() => {
  const schema = useProgrammaticSchema((state) => state.schema)

  useControls(schema, [schema])

  return (
    <Leva hidden={!modeStore.devConsole} titleBar={{ title: 'Dev console' }} />
  )
})
