const colors = require('tailwindcss/colors')

const tailwindConfig = {
  // content should be defined in individual apps config
  // content: ['./apps/**/*.{ts,tsx}', './packages/**/*.{ts,tsx}'],
  darkMode: 'class',
  theme: {
    extend: {
      colors: {
        orange: colors.orange,
        teal: colors.teal,
        blueGray: { ...colors.slate, 1000: '#0C1322' },
        amber: colors.amber,
        coolGray: colors.coolGray,
        lime: colors.lime,
        green: colors.emerald,
        yellow: colors.amber,
        purple: colors.violet,
        gray: colors.coolGray
      },
      fontFamily: {
        inter: ['Inter'],
        bebasNeue: ['Bebas Neue', 'cursive'],
        dmSans: ['DM Sans', 'sans-serif'],
        ebGaramond: ['EB Garamond', 'serif'],
        niconne: ['Niconne', 'cursive'],
        spaceGrotesk: ['Space Grotesk', 'sans-serif'],
        eudoxus: [`'Eudoxus Sans'`]
      }
    }
  },
  corePlugins: {
    aspectRatio: false
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography')
  ]
}

module.exports = tailwindConfig
