import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import toast from 'react-hot-toast'

import { uploadMediaToCloud } from '@editor/api/cdn/upload'
import {
  MediaElementInterface,
  MediaElementParams,
  MediaInterface,
  MediaPlaybackState,
  MediaPosition,
  MediaProps,
  MediaType,
  MultiplayerActions,
  MultiplayerActionType,
  SyncedMediaPlaybackState,
  VideoContextCallback
} from '@editor/interfaces'
import { bugHook, isProd } from '@editor/utils'

import {
  annotateModeStore,
  fileStore,
  mediaStore,
  modeStore,
  store,
  thumbnailStore,
  videoContextStore
} from '../../index'
import { executeMultiplayerActions } from '../../multiplayer'
import { blobUrlCache } from '../helpers/blobCache'
import { getDefaultMediaPosition } from '../utils'
import { getImageProps, getVideoProps } from '../utils/getElementProps'
import { isPlayable } from '..'

type ReducerState = ReturnType<typeof store.getState>

export type getFileProps = {
  name: string
  format: string
  usableDuration: number
  duration: number
  _id: string
  defaultPosition: MediaPosition
}

export class Media implements MediaInterface {
  _id: string
  display: boolean = true
  /**
   * Static medias have this set as true and is overridden in StaticMedia class
   */
  isStatic: boolean = false
  name: string
  type: MediaType

  /**
   * @deprecated The use of url is essentially deprecated and now is fixed value of /api/cdn/${id}
   *
   * Use source instead
   */
  url: string

  /**
   * Contains the file user dropped on the site while adding the media
   * Wont be there after refresh of the page
   * If you need the file from file store use getFileStoreFile
   */
  file?: File

  mediaPlaybackState = MediaPlaybackState.noMedia

  // Subset of MediaPlaybackState which is synced across multiplayer
  syncedMediaPlaybackState = SyncedMediaPlaybackState.noMedia

  /**
   * Allows us to add callbacks for changes we want to propagate towards videocontext directly from media,
   * without having to go through react lifecycle
   *
   * Currently two types of callbacks
   * 1. MediaPlaybackStateCallbacks {@link MediaPlaybackStateCallback}
   * 2. SourceChangeCallback {@link SourceChangeCallback}
   *
   */
  videoContextCallbacks: VideoContextCallback[] = []

  duration: number

  elements: string[] = []

  defaultPosition: MediaPosition | null = null

  uploadProgress: number = 0

  constructor(props: MediaProps) {
    this._id = props._id
    this.name = props.name
    this.type = props.type
    this.url = `/api/cdn/${this._id}` // Fixed url
    this.duration = props.duration
    this.elements = props.elements || []
    const propUploadingState =
      props.mediaPlaybackState as MediaPlaybackState | null
    this.mediaPlaybackState = propUploadingState || MediaPlaybackState.noMedia

    makeObservable(this, {
      url: observable,
      mediaPlaybackState: observable,
      defaultPosition: observable,
      uploadProgress: observable,
      offline: computed,
      upload: action,
      updateFile: action,
      updateMediaPlaybackState: action,
      _updateMediaPlaybackStateAction: action
    })

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.blobCache = blobUrlCache
    }
  }

  async upload() {
    if (this.file) {
      const url = URL.createObjectURL(this.file)

      blobUrlCache[this._id] = url
      this.updateSyncedMediaPlaybackState(SyncedMediaPlaybackState.uploading)
      await this.updateMediaPlaybackState(MediaPlaybackState.playable)
      // We want to play the video but tell the user it is downloading
      this.mediaPlaybackState = MediaPlaybackState.uploading
      console.log(`Uploading ${this.name}`)
      await uploadMediaToCloud(this, {
        complete: () => {
          console.log(`Uploaded ${this.name}`)
          toast.success(`Uploaded ${this.name}!`)
        },
        progress: (value) => {
          runInAction(() => {
            this.uploadProgress = value
          })
        }
      })
    } else {
      console.error('No file, not uploading')
    }
  }

  /**
   * Used to upload a new file for missing media
   */
  updateFile = async (file: File) => {
    this.setFile(file)
    await this.upload()
    // update the thumbnail in store
    thumbnailStore.generateThumbnail(this._id)
    // refresh nodes because they will be in error state if media was offline
    videoContextStore.refreshMedia(this._id)
  }

  /**
   * Returns the file node for this media from the file store
   */
  getFileStoreFile() {
    return fileStore.files[this._id]
  }

  /**
   * Convert between syncMediaPlaybackState -> MediaPlaybackState
   * @param syncedMedia SyncedMediaPlaybackState
   */
  private syncMediaPlaybackStateToMediaPlaybackState = (
    syncedMedia: SyncedMediaPlaybackState
  ) => {
    switch (syncedMedia) {
      case SyncedMediaPlaybackState.failedToUpload: {
        return MediaPlaybackState.failedToUpload
      }
      case SyncedMediaPlaybackState.noMedia: {
        return MediaPlaybackState.noMedia
      }
      case SyncedMediaPlaybackState.preprocessing: {
        return MediaPlaybackState.preprocessing
      }
      case SyncedMediaPlaybackState.uploading: {
        return MediaPlaybackState.uploading
      }
      case SyncedMediaPlaybackState.uploaded: {
        return MediaPlaybackState.uploaded
      }
    }
  }

  /**
   * Convert between mediaPlaybackState -> SyncedMediaPlaybackState
   * @param mediaPlaybackState MediaPlaybackState
   */
  private mediaPlaybackStateToSyncedMediaPlayback = (
    mediaPlaybackState: MediaPlaybackState
  ) => {
    switch (mediaPlaybackState) {
      case MediaPlaybackState.noMedia: {
        this.syncedMediaPlaybackState = SyncedMediaPlaybackState.noMedia
        break
      }
      case MediaPlaybackState.preprocessing: {
        this.syncedMediaPlaybackState = SyncedMediaPlaybackState.preprocessing
        break
      }
      case MediaPlaybackState.uploading: {
        this.syncedMediaPlaybackState = SyncedMediaPlaybackState.uploading
        break
      }
      case MediaPlaybackState.uploaded: {
        this.syncedMediaPlaybackState = SyncedMediaPlaybackState.uploaded
        break
      }
      case MediaPlaybackState.failedToUpload: {
        this.syncedMediaPlaybackState = SyncedMediaPlaybackState.failedToUpload
        break
      }
    }
  }

  updateSyncedMediaPlaybackState(
    syncedState: SyncedMediaPlaybackState,
    skipVideoCtxUpdate: boolean = false
  ) {
    this.syncedMediaPlaybackState = syncedState
    console.log('this.syncedMediaPlaybackState', this.syncedMediaPlaybackState)

    const mediaPlaybackState =
      this.syncMediaPlaybackStateToMediaPlaybackState(syncedState)

    this.updateMediaPlaybackState(mediaPlaybackState, skipVideoCtxUpdate)
  }

  // Dummy function so mobx knows how to re-render
  _updateMediaPlaybackStateAction = (
    newState: MediaPlaybackState,
    skipVideoCtxUpdate: boolean = false
  ) => {
    this.mediaPlaybackState = newState

    if (modeStore.isAnno) {
      // Weird bug where the thing is not reflecting the change properly
      // BANG: This shouldn't happen, if it does JS will panic
      mediaStore.medias[this._id]!.mediaPlaybackState = newState
    }
    this.mediaPlaybackStateToSyncedMediaPlayback(newState)

    console.log('(Class) updates mediaPlaybackState', this.mediaPlaybackState)

    if (!skipVideoCtxUpdate) {
      const callbacks = this.videoContextCallbacks

      for (const callback of callbacks) {
        if (
          callback._type === 'mediaPlaybackState' &&
          callback.fn !== undefined &&
          typeof callback.fn === 'function'
        ) {
          console.log('Calling video context callback', callback)
          callback.fn(this.mediaPlaybackState)
        }
      }

      // if this media was offline and is now back online refresh the nodes
      videoContextStore.refreshMedia(this._id)
    }

    this.updateMultiplayerMedia()

    // The idea here is when you go form offline -> playable you re-generate or generate thumbnail
    if (isPlayable(this.mediaPlaybackState) && mediaStore.medias[this._id]) {
      if (!thumbnailStore.getThumbnail(this._id)) {
        thumbnailStore.generateThumbnail(this._id)
      }
    }
  }

  updateMediaPlaybackState(
    newState: MediaPlaybackState,
    skipVideoCtxUpdate: boolean = false
  ) {
    return new Promise<void>((resolve) => {
      this._updateMediaPlaybackStateAction(newState, skipVideoCtxUpdate)
      resolve()
    })
  }

  public getMediaElement(
    id: string,
    reducerState?: ReducerState
  ): MediaElementInterface | undefined {
    const state = reducerState ?? store.getState()

    const mediaElements = state.media.mediaElements

    const mediaElement = mediaElements[id]

    return mediaElement
  }

  setFile = (file: File) => {
    this.file = file
  }

  addElement = (id: string) => {
    if (!this.elements.includes(id)) {
      this.elements.push(id)
      this.updateMultiplayerMedia()
    } else {
      console.log('[Media.addElement] element with id already exists')
    }
  }

  setElements = (
    elements: string[] | ((_elements: string[]) => string[]),
    noMultiplayer?: boolean
  ) => {
    if (typeof elements === 'function') {
      this.elements = elements(this.elements)
    } else {
      this.elements = elements
    }
    return this.updateMultiplayerMedia(noMultiplayer)
  }

  /**
   * @deprecated
   *
   * TODO: @patheticGeek this is the wrong way to calculate offlined media
   *
   * Offline media is mean to be when a error triggers from the video context side on the media and then we have to check. What you did is really dumb
   */
  get offline() {
    return false
  }

  startedCaching: boolean = false
  cachedFailed: boolean = false

  /**
   * Download url from cdn and cache it to blob url
   */
  cacheUrlToBlob = async () => {
    try {
      if (this.startedCaching || this.cachedFailed) return

      this.startedCaching = true
      await this.updateMediaPlaybackState(MediaPlaybackState.downloading, true)

      const res = await fetch(`${this.url}?noRedirect=1&contentType=1`, {
        redirect: 'follow'
      })

      console.trace('Caching to blob')

      const { url } = await res.json()

      const blobRes = await fetch(url)

      if (blobRes.status > 300) {
        this.startedCaching = false
        console.error('Caching failed redirected')

        this.cachedFailed = true

        try {
          const res = await fetch(`/api/cdn/exists/${this._id}`)

          console.log('Exists', res)

          // This is on the cacher having a problem
          // The file exists
          if (res.status === 200) {
            this.cachedFailed = false
            console.log('res.status === 200')
            this.cacheUrlToBlob()
          } else {
            throw new Error('File does not exist')
          }
        } catch (err) {
          // File does not exist
          toast.error('Error downloading file')
          annotateModeStore.setAnnoDownloadError(true)

          if (isProd) {
            bugHook(`Failed to download file on ${window.location.href}`)
          }
        }

        return
      }

      const blob = await blobRes.blob()

      const blobUrl = URL.createObjectURL(blob)
      console.log('cached, setting url to', blobUrl)
      blobUrlCache[this._id] = blobUrl

      // TODO refresh sources

      const callbacks = this.videoContextCallbacks

      for (const callback of callbacks) {
        if (
          callback._type === 'sourceChange' &&
          callback.fn !== undefined &&
          typeof callback.fn === 'function'
        ) {
          callback.fn(this.source)
        }
      }

      await this.updateMediaPlaybackState(MediaPlaybackState.playable)
    } catch (err) {
      this.startedCaching = false
      console.error(err)
    }
  }

  /**
   * The local source is how the media is meant to be accessed.
   *
   * It is not actually stored but rather generated locally for each user
   */
  get source() {
    if (this.type === MediaType.text || this.type === MediaType.thirdPartyEmbed)
      return ''
    const blobUrl = blobUrlCache[this._id]

    if (blobUrl) return blobUrl

    if (this.mediaPlaybackState >= MediaPlaybackState.uploaded) {
      this.cacheUrlToBlob()
    }

    return this.url
  }

  getMediaElementProps = (properties?: { startOnTimeline?: number }) => {
    const params: MediaElementParams = {
      mediaId: this._id,
      type: this.type,
      usableDuration: this.duration || 0,
      startOnTimeline: properties?.startOnTimeline || 0,
      position: this.defaultPosition || undefined
    }

    return params
  }

  /**
   * Update this media in yjs
   */
  updateMultiplayerMedia = (
    noMultiplayer?: boolean
  ): MultiplayerActionType | undefined => {
    if (noMultiplayer) {
      return { type: MultiplayerActions.SetMedia, payload: this }
    } else {
      executeMultiplayerActions([
        { type: MultiplayerActions.SetMedia, payload: this }
      ])
    }
  }

  /**
   * Will only be called for image and video medias
   * Audio has an override for this
   * and text and static elements dont need this function
   */
  static getFileProps = async <T extends MediaType>(
    file: File,
    type: T
  ): Promise<getFileProps> => {
    const _id = nanoid()

    const { duration, width, height } =
      type === MediaType.image
        ? { ...(await getImageProps(file)), duration: Infinity }
        : await getVideoProps(file)

    const defaultPosition = getDefaultMediaPosition({ height, width })

    return {
      _id,
      name: file.name,
      format: file.type,
      usableDuration: duration,
      duration,
      defaultPosition
    }
  }

  addVideoContextCallback = (newCallback: VideoContextCallback) => {
    this.videoContextCallbacks.push(newCallback)
  }
}
