import { action, computed, makeAutoObservable, observable } from 'mobx'

import {
  AudioInterface,
  MediaType,
  ThumbnailData,
  ThumbnailType,
  VideoInterface
} from '@editor/interfaces'
import { switchMediaType } from '@editor/utils'

import mediaStore from './mediaStore'

class ThumbnailStore {
  @observable imageThumbnails: Record<string, string> = {}
  @observable enabled: boolean = true

  /**
   * Audio thumbnails are stores as svg paths of the waveform, instead of images
   */
  @observable audioWaveforms: Record<string, string> = {}

  constructor() {
    makeAutoObservable(this)

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.thumbnailStore = this
    }
  }

  @computed getThumbnail = (id: string): ThumbnailData | null => {
    const media = mediaStore.medias[id]

    if (!media) {
      console.error(`Media with id ${id} does not exists`)
      return null
    }

    const { type } = media

    if (type === MediaType.video) {
      const svg = this.audioWaveforms[id]
      const image = this.imageThumbnails[id]
      if (image) {
        return { type: ThumbnailType.Video, image, svg }
      }
    }

    if (type === MediaType.audio) {
      const svg = this.audioWaveforms[id]
      if (svg) {
        return { type: ThumbnailType.Audio, svg }
      }
    }

    const image = this.imageThumbnails[id]
    if (image) {
      return { type: ThumbnailType.Image, image }
    }

    return null
  }

  @action setImageThumbnail = (id: string, url: string) => {
    this.imageThumbnails[id] = url
  }

  @action setAudioWaveform = (id: string, path: string) => {
    this.audioWaveforms[id] = path
  }

  @action setEnabled(enabled: boolean) {
    this.enabled = enabled
  }

  /**
   * Generated thumbnails for medias appropriately
   * @param id Media id for which to generate thumbnail
   */
  generateThumbnail = async (id: string) => {
    if (!this.enabled) return
    const media = mediaStore.medias[id]

    if (!media) throw new Error(`Media with id ${id} does not exists`)

    const { _id, type, source } = media

    switchMediaType(type, {
      video: async () => {
        const thumbnailPromise = (media as VideoInterface).generateThumbnail()
        const pathPromise = (media as VideoInterface).generateSvgPath()
        // Keep these separate so that if one fails other still works
        thumbnailPromise
          .then((thumbnail) => this.setImageThumbnail(_id, thumbnail))
          .catch((err) => console.error(err))
        pathPromise
          .then((path) => this.setAudioWaveform(_id, path))
          .catch((err) => console.error(err))
      },
      audio: async () => {
        const path = await (media as AudioInterface).generateSvgPath()
        this.setAudioWaveform(_id, path)
      },
      image: () => {
        this.setImageThumbnail(_id, source)
      }
    })
  }
}

export default new ThumbnailStore()
