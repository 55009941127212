import { store } from '../store'

export type DispatchNoMultiplayer = typeof store['dispatch']

export const dispatchNoMultiplayer = (
  ...params: Parameters<DispatchNoMultiplayer>
) => {
  const action = params[0]

  // @ts-expect-error Payload is expected
  action.noMultiplayer = true

  store.dispatch(action)
}
