import { MediaPosition, TextInterface } from '@editor/interfaces'

import CanvasTxt from './canvas-txt'

/**
 * Draws a rounded rectangle using the current state of the canvas.
 */
export function drawRoundRect(
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  x: number,
  y: number,
  radius: number = 0,
  fill: boolean = true,
  stroke: boolean = false
) {
  let finalRadius: { tl: number; tr: number; br: number; bl: number } = {
    tl: 0,
    tr: 0,
    br: 0,
    bl: 0
  }
  if (typeof radius === 'number') {
    finalRadius = { tl: radius, tr: radius, br: radius, bl: radius }
  } else {
    for (const side in finalRadius) {
      finalRadius[side] = radius[side] || finalRadius[side]
    }
  }

  ctx.beginPath()
  ctx.moveTo(x + finalRadius.tl, y)
  ctx.lineTo(x + width - finalRadius.tr, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + finalRadius.tr)
  ctx.lineTo(x + width, y + height - finalRadius.br)
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - finalRadius.br,
    y + height
  )
  ctx.lineTo(x + finalRadius.bl, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - finalRadius.bl)
  ctx.lineTo(x, y + finalRadius.tl)
  ctx.quadraticCurveTo(x, y, x + finalRadius.tl, y)
  ctx.closePath()
  if (fill) {
    ctx.fill()
  }
  if (stroke) {
    ctx.stroke()
  }
}

/**
 * Draws a text interface on canvas with background
 */
export function drawTextToCanvas(
  canvas: HTMLCanvasElement,
  textInterface: TextInterface,
  position: MediaPosition
): [canvas: HTMLCanvasElement, height?: number] {
  // Set height and width of canvas
  canvas.height = position.height
  canvas.width = position.width

  const ctx = canvas.getContext('2d')

  const textStyle = textInterface.style

  if (!ctx) throw new Error('Cannot get canvas context')

  // Clear the canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  // Set text properties
  ctx.font = `${textStyle.fontWeight} ${textStyle.fontSize}px ${textStyle.fontFamily}`
  ctx.textAlign = textStyle.textAlign
  ctx.textBaseline = textStyle.verticalAlign

  // Draw background
  ctx.fillStyle = textStyle.backgroundColor

  // the canvas will be positioned on the right coordinate (x,y)
  // so we dont need to put the x,y here
  drawRoundRect(
    ctx,
    position.width,
    position.height,
    0,
    0,
    textStyle.borderRadius
  )

  let width = 0
  for (const text of textInterface.text.split('\n')) {
    const textWidth = ctx.measureText(text).width
    width = Math.max(width, textWidth)
  }

  // Draw the text
  ctx.fillStyle = textStyle.textColor

  const canvasTxt = new CanvasTxt({
    align: textStyle.textAlign,
    vAlign: textStyle.verticalAlign,
    fontSize: textStyle.fontSize,
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: textStyle.fontWeight,
    font: textStyle.fontFamily,
    lineHeight: textStyle.lineHeight
  })

  // On canvas text is to be
  const textPosOnCanvas = {
    ...position,
    x: 0,
    y: 0
  }
  const { height } = canvasTxt.drawText(
    ctx,
    textInterface.text,
    textPosOnCanvas
  )

  if (position.height < height) {
    return [canvas, height]
  }

  return [canvas]
}

/**
 * A utility function to promisify canvas.toBlob
 * @param canvas The canvas to convert to blob
 * @returns The blob (png) representation of canvas
 */
export const canvasToBlob = (canvas: HTMLCanvasElement) => {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob)
      } else {
        reject(new Error('Could not get blob'))
      }
    })
  })
}
