import React from 'react'

import {
  BackgroundElementProperties,
  BackgroundElementType,
  GradientElementProperties
} from '@editor/interfaces'

export const MISSING_MEDIA_MESSAGE = 'This media is offline please re-upload it'
export const MEDIA_UPLOADING_MESSAGE = 'This media is being uploaded'

export const backgroundElementsTypes: Array<{
  title: string
  type: BackgroundElementType
  icon: (_props: { className: string }) => React.ReactElement
  defaultProps: BackgroundElementProperties
}> = [
  {
    title: 'Solid',
    type: 'Solid',
    icon: ({ className }) => (
      <svg
        className={className}
        viewBox="0 0 300 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="300" height="49" fill="#444B6E" />
      </svg>
    ),
    defaultProps: {
      type: 'Solid',
      color: '#444B6E'
    }
  },
  {
    title: 'Gradient',
    type: 'Gradient',
    icon: ({ className }) => (
      <svg
        className={className}
        viewBox="0 0 300 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="300" height="49" fill="url(#gradient)" />
        <defs>
          <linearGradient
            id="gradient"
            x1="180.882"
            y1="-3.0625"
            x2="174.375"
            y2="57.9556"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.147287" stopColor="#FF6600" />
            <stop offset="0.621018" stopColor="#F2994A" />
            <stop offset="1" stopColor="#F2C94C" />
          </linearGradient>
        </defs>
      </svg>
    ),
    defaultProps: {
      type: 'Gradient',
      from: '#FF6600',
      to: '#F2C94C',
      direction: 'from left'
    }
  },
  {
    title: 'Waves',
    type: 'Wave',
    icon: ({ className }) => (
      <svg
        className={className}
        viewBox="0 0 300 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="300" height="49" fill="#444B6E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.42857C0 1.53503 1.53502 0 3.42857 0V0C6.85714 0 13.7143 0 19.7143 4.08333C26.5714 8.16667 33.4286 16.3333 40.2857 20.4167C46.2857 24.5 53.1429 24.5 60 21.5833C66.8571 18.6667 73.7143 12.25 79.7143 13.4167C86.5714 14.5833 93.4286 22.75 100.286 19.25C106.286 16.3333 113.143 2.33333 120 5.25C126.857 8.16667 133.714 28.5833 139.714 30.9167C146.571 32.6667 153.429 16.3333 160.286 9.33333C166.286 2.33333 173.143 4.08333 180 11.0833C186.857 18.6667 193.714 30.9167 199.714 33.8333C206.571 36.75 213.429 30.9167 220.286 24.5C226.286 18.6667 233.143 12.25 240 10.5C246.857 8.16667 253.714 10.5 259.714 12.25C266.571 14.5833 273.429 16.3333 280.286 22.75C286.286 28.5833 293.143 39.0833 296.571 43.75L300 49V49H296.571C293.143 49 286.286 49 280.286 49C273.429 49 266.571 49 259.714 49C253.714 49 246.857 49 240 49C233.143 49 226.286 49 220.286 49C213.429 49 206.571 49 199.714 49C193.714 49 186.857 49 180 49C173.143 49 166.286 49 160.286 49C153.429 49 146.571 49 139.714 49C133.714 49 126.857 49 120 49C113.143 49 106.286 49 100.286 49C93.4286 49 86.5714 49 79.7143 49C73.7143 49 66.8571 49 60 49C53.1429 49 46.2857 49 40.2857 49C33.4286 49 26.5714 49 19.7143 49C13.7143 49 6.85714 49 3.42857 49V49C1.53502 49 0 47.465 0 45.5714V3.42857Z"
          fill="#0099FF"
        />
      </svg>
    ),
    defaultProps: {
      type: 'Wave',
      layers: 3,
      waves: 10,
      variance: 0.5,
      color: '#0099FF'
    }
  },
  {
    title: 'Blob',
    type: 'Blob',
    icon: ({ className }) => (
      <svg
        className={className}
        viewBox="0 0 300 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="300" height="49" fill="#FF0066" />
        <path
          x="250"
          d="M148,32.5 Q131,42,131,22.5 Q131,3,148,13 Q165,23,148,32.5 Z"
          fill="#981248"
        />
      </svg>
    ),
    defaultProps: {
      type: 'Blob',
      growth: 6,
      points: 5,
      size: 500,
      color: '#BB004B',
      backgroundColor: '#FF0066'
    }
  }
]

export const gradientDirections: Record<
  GradientElementProperties['direction'],
  string
> = {
  'from top': 'Top to bottom',
  'from left': 'Left to right',
  'from right': 'Right to left',
  'from bottom': 'Bottom to top'
}
