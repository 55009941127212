import {
  ImageFormat,
  ImageInterface,
  MediaPosition,
  MediaProps,
  MediaType
} from '@editor/interfaces'

import { getFileProps, Media } from './Media'
import { StaticMedia } from './StaticMedia'

export type getImageFileProps = Required<getFileProps>

export type ImageProps = Omit<MediaProps, 'type'> & {
  _id: string
  format: string
  defaultPosition: MediaPosition
}

export class Image extends StaticMedia implements ImageInterface {
  format: ImageFormat
  defaultPosition: MediaPosition

  constructor({ format, defaultPosition, ...props }: ImageProps) {
    super({
      ...props,
      type: MediaType.image,
      duration: Infinity
    })
    this.format = format
    this.defaultPosition = defaultPosition
  }

  static getFileProps = async (file: File) => {
    return Media.getFileProps(
      file,
      MediaType.image
    ) as Promise<getImageFileProps>
  }

  get source() {
    return this.superSource()
  }
}
