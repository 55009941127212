import React, { useCallback, useEffect, useState } from 'react'

type useContentEditableProps = {
  value?: string
  editable: boolean
  onChange?: (_value: string) => void
}

export const useContentEditable = ({
  value,
  editable,
  onChange
}: useContentEditableProps) => {
  const [elRef, setElRef] = useState<HTMLElement | null>(null)
  const [valueState, setValue] = useState(value || '')

  useEffect(() => {
    if (value) {
      if (elRef && elRef.innerText !== value) {
        setValue(value)
        elRef.innerText = value
      }
    }
  }, [value, elRef])

  const onChangeHandler = useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (!elRef) return
      const newValue = elRef.innerText

      setValue(newValue || '')
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange, elRef]
  )

  /**
   * Don't want to propogate space or enter up from here
   * TODO Fix trailing space bug
   */
  const onBlur: React.FocusEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const callbackRef = (ref: HTMLElement | null) => {
    setElRef(ref)
  }

  const attributes = {
    ref: callbackRef,
    contentEditable: editable,
    onInput: onChangeHandler,
    onBlur: onBlur,
    tabIndex: 0
  }

  const changeValue = (newValue: string) => {
    if (!elRef) return
    elRef.innerText = newValue
    setValue(newValue)
  }

  return {
    value: valueState,
    setValue: changeValue,
    attributes
  }
}
