import { Middleware } from 'redux'

import { MultiplayerActions, MultiplayerActionType } from '@editor/interfaces'

import { executeMultiplayerActions } from '../../multiplayer'
import {
  addMediaElement,
  MediaSliceState,
  move,
  previewSelectMedia,
  removeMediaElement,
  setEndpoints,
  unSelectPreviewMedia,
  updateIsPrime,
  updateMediaElement,
  updatePosition,
  updateXYPosition
} from '../slices/mediaSlice'
import { RootState } from '../store'

/**
 * Closure to hold multiplayer actions from noMultiplayer
 * This is used as a temporary store of completed actions that should be executed as a transaction in the future
 * This should be reset every time it needs to be used by doing multiplayerActions.length = 0
 */
export let multiplayerActions: MultiplayerActionType[] = []

export const multiplayerMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const result = next(action)
    const state = store.getState()

    if (action.payload) {
      if (action.noMultiplayer) {
        const multiplayerAction = getMultiplayerActionFromType({
          type: action.type,
          payload: {
            id: action.payload.id,
            ...state.media
          }
        })
        multiplayerActions = multiplayerActions.concat(multiplayerAction)
        return result
      }
      runMultiplayerAction({
        type: action.type,
        payload: {
          id: action.payload.id,
          ...state.media
        }
      })
    }

    return result
  }

export const getMultiplayerActionFromType = ({
  type,
  payload
}: {
  type: string
  payload: {
    id: string
  } & MediaSliceState
}) => {
  /**
   * Store the id of media element to be updated if any
   * in end of this function if this is string
   * multiplayer action set media element is called
   */
  let mediaElemToUpdate: string | null = null

  const multiplayerActions: MultiplayerActionType[] = []

  switch (type) {
    case removeMediaElement.type:
      multiplayerActions.push({
        type: MultiplayerActions.DeleteMediaElement,
        payload: payload.id
      })
      break
    case addMediaElement.type:
      mediaElemToUpdate = payload.id
      break
    case updateMediaElement.type:
      mediaElemToUpdate = payload.id
      break
    case move.type:
      mediaElemToUpdate = payload.id
      break
    case setEndpoints.type:
      mediaElemToUpdate = payload.id
      break
    case updatePosition.type:
      mediaElemToUpdate = payload.id
      break
    case updateXYPosition.type:
      mediaElemToUpdate = payload.id
      break
    case updateIsPrime.type:
      mediaElemToUpdate = payload.id
      break
    case previewSelectMedia.type:
      multiplayerActions.push({
        type: MultiplayerActions.SelectMedia,
        payload: payload.previewSelectedMedia
      })
      break
    case unSelectPreviewMedia.type:
      multiplayerActions.push({
        type: MultiplayerActions.SelectMedia,
        payload: payload.previewSelectedMedia
      })
      break
    default:
      break
  }

  if (mediaElemToUpdate) {
    multiplayerActions.push({
      type: MultiplayerActions.SetMediaElement,
      // BANG: tbh not sure what is happening here
      payload: payload.mediaElements[mediaElemToUpdate]!
    })
  }

  return multiplayerActions
}

export const runMultiplayerAction = ({
  type,
  payload
}: {
  type: string
  payload: {
    id: string
  } & MediaSliceState
}) => {
  const multiplayerActions = getMultiplayerActionFromType({ type, payload })

  executeMultiplayerActions(multiplayerActions)
}
