import React from 'react'

import CutCursor from './CutCursor'
import DefaultCursor from './DefaultCursor'

export type CursorProps = {
  color?: string
  className?: string
  type: 'default' | 'cut'
}

const defaultProps: CursorProps = {
  type: 'default'
}

export const Cursor = ({
  color,
  className,
  type
}: CursorProps = defaultProps) => {
  if (type === 'default') {
    return <DefaultCursor className={className} fill={color} />
  } else if (type === 'cut') {
    return <CutCursor className={className} fill={color} />
  }

  return null
}

export default Cursor
