/* eslint-disable no-unused-vars */

import { ThirdPartyEmbedType, TrackType } from '.'

export enum DragType {
  timeline = 'timeline',
  file = 'file',
  fromFileToTimeline = 'fromFileToTimeline'
}

export enum FileDragType {
  thumbnail = 'thumbnail',
  panel = 'panel',
  multiPanel = 'multiPanel'
}

export enum MediaType {
  audio = 'audios',
  video = 'videos',
  thirdPartyEmbed = 'thirdPartyEmbed',
  image = 'images',
  text = 'texts',
  backgroundElement = 'backgroundElement',
  tlDrawElement = 'tlDrawElement',
  comments = 'comments'
}

export enum TimelineMode {
  default = 'default',
  cut = 'cut',
  text = 'text',
  backgroundElement = 'backgroundElement',
  // Time warp is speeding up or slowing down element
  timeWarp = 'timeWarp'
}

export enum AnnotateMode {
  default = 'default',
  draw = 'draw',
  comment = 'comment'
}

/**
 * This is the current playable state of the media, it will be everything contain everything regarding the same
 *
 * A bit about how MediaPlaybackState works
 *
 * By default, it is no media (this is an error state)
 *
 * When a media is added, it go to uploading (this is a processing state)
 *
 * When media is uploaded, it goes to uploaded state.
 *  Note: Uploaded should be a processing state too, but for the time being it is a playable state.
 *  This is to ensure smooth merge till the HEVC branch is done
 *
 * If the media fails to upload, it goes to a failedToUpload state, (this is an error state)
 *
 * If the media needs pre processing, it goes to preprocessing (this is a processing state)
 *
 * Finally the media is ready to play, it is a playable
 *
 * If at any time the media becomes unplayable it becomes offlined (this is an error state)
 *
 *
 * Each error state is slightly different in terms of what action we should take tho:
 *
 * 1. noMedia: No action required from our part, this means unreachable code
 *
 * 2. failedToUpload: Trigger a failed to missing media modal and ask user to reupload media
 *
 * 3. offlined: Try redownloading the media from the cloud first, if that fails convert to failedToUpload and trigger modal
 *
 *
 * NOTE: This is not synced across multiplayer and is local to the single user
 *
 * These are stored as number so we can use greater than or lesser than, this is the progression of a video
 */
export enum MediaPlaybackState {
  noMedia = -1,
  preprocessing = 0,
  uploading = 1,
  uploaded = 2,
  downloading = 3,
  playable = 4,
  offlined = 5,
  failedToUpload = 6
}

/**
 * This is a subset of {@link MediaPlaybackState} which is do with the uploading state of the video, which is synced across multiplayer
 */
export enum SyncedMediaPlaybackState {
  noMedia = 'noMedia added',
  preprocessing = 'transcoding from hevc',
  uploading = 'uploading',
  uploaded = 'uploaded',
  failedToUpload = 'error uploading'
}

export enum MultiplayerActions {
  SetMediaElement,
  DeleteMediaElement,
  SetFile,
  DeleteFile,
  SetFolder,
  DeleteFolder,
  SetMedia,
  DeleteMedia,
  Cut,
  UpdateTracks,
  UpdateTrackPrime,
  UpdateTrackAbove,
  UpdateTrackBelow,
  SelectMedia,
  AddMedia,
  SetMediaUploading,
  updateFolders
}

export type EnumBlackList =
  | DragType
  | FileDragType
  | MediaType
  | TimelineMode
  | MultiplayerActions
  // Not an Enums but should be ignored regardless
  | TrackType
  | ThirdPartyEmbedType

export enum CommentFilter {
  All = 'All',
  CurrentVersion = 'Current Version',
  Resolved = 'Resolved',
  Open = 'Open'
}

export enum AnnoCreationStage {
  Selection,
  Embed,
  NameProject,
  Onboard,
  Loading
}

export enum OgImageType {
  // eslint-disable-next-line no-unused-vars
  audio = 'Audio',
  // eslint-disable-next-line no-unused-vars
  video = 'Video'
}
