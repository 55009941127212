import { NextPageContext } from 'next'
import Cookies from 'universal-cookie'

import { setCookie, setTokenCookie } from '@editor/utils'

const cookies = new Cookies()
const token = cookies.get('token')
const PROJECT_ID_COOKIE_KEY = 'projectId'
const projectId = cookies.get(PROJECT_ID_COOKIE_KEY)

export const additionalHeaders: Record<string, string> = {}

export const generateGraphqlHeaders = (tok?: string, projId?: string) => {
  const h: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-auth-token': tok || token,
    ...additionalHeaders
  }

  if (projectId) h.projectId = projectId
  if (projId) h.projectId = projId

  return h
}

export const setProjectId = (id: string) => {
  setCookie(PROJECT_ID_COOKIE_KEY, id, { path: '/' })
}

export const getToken = ({
  req,
  overwriteToken
}: {
  req?: NextPageContext['req']
  overwriteToken?: string
}): string | undefined => {
  let tok: string | undefined

  // if context has request info aka Server Side
  if (req) {
    // ugly way to get cookie value from a string of values
    // good enough for demostration
    tok = req.headers?.cookie?.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    )
  } else if (overwriteToken) {
    if (typeof window !== 'undefined') {
      setTokenCookie(overwriteToken)
    }
    tok = overwriteToken
  } else {
    // we dont have request info aka Client Side
    tok = cookies.get('token')
  }

  const token = overwriteToken ?? tok
  return token
}
