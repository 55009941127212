import React from 'react'

export default function DefaultCursor({
  fill,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 15 15" fill={fill || 'currentColor'} {...props}>
      <path d="M1.71427 0.0894402C1.25 -0.0962677 0.719729 0.0125749 0.366152 0.366152C0.0125749 0.71973 -0.0962676 1.25 0.0894402 1.71427L5.08943 14.2142C5.27224 14.6713 5.70626 14.9785 6.19807 14.9989C6.68988 15.0194 7.14791 14.7493 7.36805 14.309L9.09245 10.8602L12.8661 14.6339C13.3543 15.122 14.1457 15.122 14.6339 14.6339C15.122 14.1457 15.122 13.3543 14.6339 12.8661L10.8602 9.09245L14.309 7.36805C14.7493 7.14791 15.0194 6.68988 14.9989 6.19807C14.9785 5.70626 14.6713 5.27224 14.2142 5.08943L1.71427 0.0894402Z" />
    </svg>
  )
}
