import { makeAutoObservable } from 'mobx'
import React, { createRef } from 'react'

class TimelineStore {
  constructor() {
    makeAutoObservable(this)

    this.globalTimelineRef = createRef<HTMLDivElement>()
  }

  globalTimelineRef: React.RefObject<HTMLDivElement>
}

export default new TimelineStore()
