import { MediaElementId } from '@editor/interfaces'

import {
  addMediaElement,
  dispatchNoMultiplayer,
  mediaStore,
  store,
  unSelectPreviewMedia,
  updateMediaElement
} from '../../index'
import executeMultiplayerActions from '../../multiplayer/lib/multiplayerAction'
import { multiplayerActions } from '../../redux/middleware/multiplayerMiddleware'
import { createMediaElement } from '../helpers/createMedia'
import { getContainer, getTrackFromContainer } from '../utils'

const getMediaElement = (id: string) => {
  return store.getState().media.mediaElements[id]
}

/**
 * Executes cut on a given id and cutPoint
 * @param id
 * @param cutPoint
 *
 *
 * How it works:
 *
 * Splits the media into two elements based on the relative cut point
 * How it works in visualized in `cut.tldr`
 *
 *  Steps:
 *  1. Create left, right media element (based on parents existing)
 *  1 a. Set the display false on the media element that was cut
 *  2. Find elements track
 *  3. Replace element with left and right in track
 *  4. Add media elements to store
 *  5. Update tracks with the updated track
 */
export const cut = (id: string, cutPoint: number) => {
  // reset multiplayer actions
  multiplayerActions.length = 0
  // Step 1. Unselect Media
  dispatchNoMultiplayer(unSelectPreviewMedia())
  // Note unselect media has no payload and will not have any multiplayer action

  const mediaElement = getMediaElement(id)

  if (!mediaElement) {
    throw new Error('Unable to cut element as mediaElement is undefined')
  }

  const { start, duration, startOnTimeline, isPrime, position, type, mediaId } =
    mediaElement

  // BG: Media should exist here
  const media = mediaStore.medias[mediaId]!
  const isStatic = media.isStatic

  const leftMediaElement = createMediaElement(
    {
      usableDuration: isStatic ? Infinity : cutPoint,
      start,
      duration: cutPoint,
      startOnTimeline: startOnTimeline,
      defaultStart: start,
      isPrime,
      type,
      position,
      mediaId: mediaId,
      parent: id as MediaElementId
    },
    isStatic
  )

  const rightMediaElement = createMediaElement(
    {
      usableDuration: isStatic ? Infinity : duration - cutPoint,
      start: start + cutPoint,
      duration: duration - cutPoint,
      startOnTimeline: startOnTimeline + cutPoint,
      defaultStart: start + cutPoint,
      isPrime,
      type,
      position,
      mediaId: mediaId,
      parent: id as MediaElementId
    },
    isStatic
  )

  // Step 1 a. Set the display false on the media element that was cut

  dispatchNoMultiplayer(
    updateMediaElement({
      id: mediaElement.id,
      mediaElement: { display: false }
    })
  )

  // Set Media Elements for Media Id to cut elements
  // This also removes the original
  const setElementsAction = mediaStore.medias[mediaId]?.setElements(
    [leftMediaElement.id, rightMediaElement.id],
    true
  )
  if (setElementsAction) {
    multiplayerActions.push(setElementsAction)
  }

  const container = getContainer(id)

  if (!container) throw new Error('No container for cut')

  const track = getTrackFromContainer(container)

  /**
   * Step 3. Flat Map flattens one layer of output
   */
  const newTrack = track.flatMap((elm) => {
    if (elm === id) {
      return [leftMediaElement.id, rightMediaElement.id]
    } else {
      return elm
    }
  })

  // Step 4. Add Media Elements

  dispatchNoMultiplayer(addMediaElement(leftMediaElement))

  dispatchNoMultiplayer(addMediaElement(rightMediaElement))

  let { tracks } = mediaStore

  if (container.trackType === 'prime') {
    tracks.prime = newTrack
  } else {
    tracks[container.trackType][container.index] = newTrack
  }
  tracks = { ...tracks }

  // Step 5. Update Tracks

  const updateTrackAction = mediaStore.updateTracks(tracks, true)

  if (updateTrackAction) {
    multiplayerActions.push(updateTrackAction)
  } else {
    throw new Error(
      'Expected updateTrackAction to be defined. Should be unreachable state'
    )
  }

  executeMultiplayerActions(multiplayerActions)

  return [leftMediaElement, rightMediaElement]
}
