import { Middleware } from 'redux'

import { MediaType } from '@editor/interfaces'
import {
  getTrackFromMediaElementId,
  getZIndexFromContainer
} from '@editor/state/media/utils'
import { videoContextStore } from '@editor/state/mobx'
import { warnError } from '@editor/utils'

import {
  move,
  previewSelectMedia,
  removeMediaElement,
  setEndpoints,
  setPlaybackRate,
  unSelectPreviewMedia,
  updateMediaElement,
  updatePosition,
  updateXYPosition
} from '../slices/mediaSlice'
import { RootState } from '../store'

/**
 * This function runs when we make changes to the redux store as a middleware
 *
 * It handles propagation of changes from redux store to video context
 */
export const videoContextMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    // The state before any actions happen
    const beforeStore = store.getState()
    const result = next(action)
    const { type, payload } = action
    const mediaElements = store.getState().media.mediaElements

    if (!payload) return result

    const { id, type: mediaType } = payload

    switch (type) {
      case removeMediaElement.type: {
        // Remove media
        videoContextStore.removeNode(id)
        break
      }
      case setEndpoints.type: {
        videoContextStore.moveNode(id, mediaElements)
        break
      }
      case move.type: {
        videoContextStore.moveNode(id, mediaElements)
        break
      }
      case updateMediaElement.type: {
        const mediaElement = mediaElements[id]
        if (!mediaElement) {
          warnError('MediaElement for id is undefined')
          break
        }
        videoContextStore.moveNode(id, mediaElements)
        if (typeof mediaElement.volume === 'number') {
          videoContextStore.updateNodeVolume(id, mediaElement.volume)
        }
        if (!mediaElement.display) {
          videoContextStore.removeNode(id)
          break
        }
        break
      }
      case updatePosition.type: {
        const mediaElement = mediaElements[id]
        if (!mediaElement) {
          warnError('MediaElement for id is undefined')
          break
        }
        videoContextStore.updateNodePosition(id, mediaElement.position)
        break
      }
      case updateXYPosition.type: {
        const mediaElement = mediaElements[id]
        if (!mediaElement) {
          warnError('MediaElement for id is undefined')
          break
        }
        videoContextStore.updateNodePosition(id, mediaElement.position)
        break
      }
      /**
       * When a media element is select it is rendered by the editable preview wrapper
       * and not video ctx, so we remove the node from video ctx
       */
      case previewSelectMedia.type: {
        if (mediaType === MediaType.text) {
          videoContextStore.removeNode(id)
        }

        const previousId = beforeStore.media.previewSelectedMedia.id
        const previousType = beforeStore.media.previewSelectedMedia.type

        // If some media element was selected previously and now some other is selected
        // add the old media element back in video ctx
        if (previousId && previousType) {
          if (previousType === MediaType.text) {
            const container = getTrackFromMediaElementId(previousId)
            if (container) {
              const zIndex = getZIndexFromContainer(container)
              videoContextStore.addElement(mediaElements, previousId, zIndex)
            }
          }
        }

        break
      }
      /**
       * When a media element is unselected it is now rendered by video ctx
       * so add it back in there
       */
      case unSelectPreviewMedia.type: {
        if (mediaType === MediaType.text) {
          const container = getTrackFromMediaElementId(id)
          if (container) {
            const zIndex = getZIndexFromContainer(container)
            videoContextStore.addElement(mediaElements, id, zIndex)
          }
        }

        break
      }
      case setPlaybackRate.type: {
        const mediaElement = mediaElements[id]
        if (!mediaElement) {
          warnError('MediaElement for id is undefined')
          break
        }
        videoContextStore.moveNode(id, mediaElements)
        videoContextStore.updateNodePlaybackRate(
          id,
          // This is optional for backwards compatibility
          mediaElement.playbackRate || 1
        )
        break
      }
      default: {
        break
      }
    }

    return result
  }
