/* eslint-disable no-unused-vars */
import { action, makeAutoObservable, observable } from 'mobx'

import {
  BackgroundElementInterface,
  MediaElements,
  MediaPlaybackState,
  MediaPlaybackStateCallback,
  MediaPosition,
  MediaTrack,
  MediaType,
  SourceChangeCallback,
  TextInterface
} from '@editor/interfaces'
import {
  canvasToBlob,
  drawTextToCanvas,
  toastError,
  warnError
} from '@editor/utils'
import { drawSvgOnCanvas } from '@editor/utils/svg'
import type VideoContext from '@modfy/videocontext'
import type { MediaErrorEvent } from '@modfy/videocontext/MediaErrorTarget'
import MediaNode, { MediaSrc } from '@modfy/videocontext/SourceNodes/medianode'
import type SourceNode from '@modfy/videocontext/SourceNodes/sourcenode'
import type { Sources } from '@modfy/videocontext/SourceNodes/sourcenode'
import type { MediaStatus } from '@modfy/videocontext/types'

import { isPlayable } from '../media'
import { store, updatePosition } from '../redux'

import mediaStore from './mediaStore'
import settingStore from './settingsStore'

const MediaStatusOk = (): MediaStatus => ({ _type: 'ok' })
const MediaStatusOffline = (): MediaStatus => ({
  _type: 'offline',
  path: '/images/media/offline.jpg'
})
const MediaStatusTranscoding = (): MediaStatus => ({
  _type: 'transcoding',
  path: '/images/media/transcoding.jpg'
})
const MediaStatusUploading = (): MediaStatus => ({
  _type: 'uploading',
  path: '/images/media/upload.jpg'
})
const MediaStatusDownloading = (): MediaStatus => ({
  _type: 'downloading',
  path: '/images/media/download.jpg'
})

type SourceNodeType = SourceNode<Sources>

class VideoContextStore {
  constructor() {
    makeAutoObservable(this)
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.videoContextStore = this
    }
  }

  /**
   * When we switch to render mode and back, we need to
   * swap the VideoContext's canvas
   * @deprecated
   */
  @observable shouldSwapCanvas = false
  @observable canvasId = 'canvas'
  @observable canvas: HTMLCanvasElement | null = null

  @observable videoCtx: VideoContext | undefined
  @observable contextNodes: Record<
    string,
    { node: SourceNodeType; type: MediaType; zIndex: number }
  > = {}

  @observable drawingCanvas: Record<string, HTMLCanvasElement> = {}

  @action setContext = (ctx: VideoContext) => {
    this.videoCtx = ctx
  }

  @action takeScreenshot = async () => {
    if (this.videoCtx?._canvas) {
      const blob = await canvasToBlob(this.videoCtx._canvas)
      return blob
    }
  }

  @action setCanvas = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas
  }

  /**
   * @deprecated
   */
  @action setShouldSwapCanvas = (shouldSwap: boolean) => {
    this.shouldSwapCanvas = shouldSwap
  }

  reDrawTextOnCanvas = ({
    id,
    textInterface,
    startTime,
    endTime,
    type,
    zIndex
  }: {
    id: string
    textInterface: TextInterface
    startTime: number
    endTime: number
    type: MediaType
    zIndex: number
  }) => {
    if (id in this.contextNodes) {
      const node = this.contextNodes[id]?.node

      // @ts-ignore
      node?.disconnect(this.videoCtx?.destination)
      node?.destroy()
      delete this.contextNodes[id]
    }

    const position =
      store.getState().media.mediaElements[textInterface._id]?.position

    const canvas = this.drawingCanvas[id]

    if (!canvas || !position) {
      console.warn(
        '[WARN] canvas or position is not defined while redrawing text'
      )
      return
    }

    const [returnedCanvas, height] = drawTextToCanvas(
      canvas,
      textInterface,
      position
    )

    if (height) {
      store.dispatch(
        updatePosition({
          id: textInterface._id,
          position: {
            height: height
          }
        })
      )
    }

    /**
     * We don't want to render text node if it is selected, as we are drawing it using react
     */
    const selectedId = store.getState().media.previewSelectedMedia.id
    if (selectedId === id) {
      return
    }

    if (this.videoCtx) {
      const newNode: SourceNodeType = this.videoCtx.canvas(returnedCanvas, '')

      newNode.startAt(startTime)
      newNode.stopAt(endTime)
      this.setPositionOnNode(newNode, position)

      // @ts-ignore
      newNode.connect(this.videoCtx?.destination, zIndex)

      this.contextNodes[id] = { node: newNode, type, zIndex }
    }
  }

  /**
   * Draws text to canvas for the first time when the element is created
   */
  initialDrawTextOnCanvas = ({
    id,
    textInterface
  }: {
    id: string
    textInterface: TextInterface
  }) => {
    if (id in this.contextNodes) {
      const node = this.contextNodes[id]?.node

      // @ts-ignore
      node?.disconnect(this.videoCtx?.destination)
      node?.destroy()
      delete this.contextNodes[id]
    }

    const canvas = this.drawingCanvas[id]

    // @ts-ignore
    // node.disconnect(this.videoCtx?.destination)

    const position =
      store.getState().media.mediaElements[textInterface._id]?.position

    if (!canvas || !position) {
      console.warn(
        '[WARN] canvas or position is not defined on initial text draw'
      )
      return
    }

    const [returnedCanvas, height] = drawTextToCanvas(
      canvas,
      textInterface,
      position
    )

    if (height) {
      store.dispatch(
        updatePosition({
          id: textInterface._id,
          position: {
            height: height
          }
        })
      )
    }

    const selectedId = store.getState().media.previewSelectedMedia.id

    /**
     * We don't want to render the text node if it is the selected node.
     * Note: this is at the end so that we can use the drawTextToCanvas to get text width
     * TODO: Get text width and height outside drawTextToCanvas,so we can move this to the top
     */
    if (selectedId === id) {
      return
    }

    if (this.videoCtx) {
      const newNode: SourceNodeType = this.videoCtx.canvas(returnedCanvas, '')
      this.setPositionOnNode(newNode, position)

      textInterface.focusElement()

      return newNode
    } else {
      throw new Error('video ctx not defined')
    }
  }

  drawBackgroundElementOnCanvas = ({
    id,
    backgroundElement
  }: {
    id: string
    backgroundElement: BackgroundElementInterface
  }) => {
    if (id in this.contextNodes) {
      const node = this.contextNodes[id]?.node

      // @ts-ignore
      node?.disconnect(this.videoCtx?.destination)
      node?.destroy()
      delete this.contextNodes[id]
    }

    const canvas = this.drawingCanvas[id]
    const position =
      store.getState().media.mediaElements[backgroundElement._id]?.position

    if (!canvas || !position) {
      warnError(
        '[WARN] canvas or position is not defined while drawing static element'
      )
      return
    }

    canvas.height = position.height
    canvas.width = position.width

    try {
      drawSvgOnCanvas(backgroundElement.svg, canvas)
    } catch (err) {
      // this might throw an error when there's some error in a color or something in svg
      // which can happen when user is inputting a color
      // so just logging the error for now
      console.warn(err)
    }

    if (!this.videoCtx) throw new Error('video ctx not defined')

    const newNode: SourceNodeType = this.videoCtx.canvas(canvas, '')
    this.setPositionOnNode(newNode, position)

    return newNode
  }

  reDrawBackgroundElementOnCanvas = ({
    id,
    backgroundElement,
    startTime,
    endTime,
    type,
    zIndex
  }: {
    id: string
    backgroundElement: BackgroundElementInterface
    startTime: number
    endTime: number
    type: MediaType
    zIndex: number
  }) => {
    const newNode = this.drawBackgroundElementOnCanvas({
      id,
      backgroundElement
    })

    if (!newNode) {
      console.warn('[WARN] static element node is undefined while redrawing')
      return
    }

    newNode.startAt(startTime)
    newNode.stopAt(endTime)

    // @ts-ignore
    newNode.connect(this.videoCtx?.destination, zIndex)

    this.contextNodes[id] = { node: newNode, type, zIndex }
  }

  mediaPlaybackStateToMediaStatus = (
    mediaPlaybackState: MediaPlaybackState
  ) => {
    switch (mediaPlaybackState) {
      case MediaPlaybackState.playable: {
        return MediaStatusOk()
      }
      case MediaPlaybackState.uploading: {
        return MediaStatusUploading()
      }
      case MediaPlaybackState.uploaded: {
        // Temp patch, will change later
        return MediaStatusOk()
      }
      case MediaPlaybackState.downloading: {
        return MediaStatusDownloading()
      }
      case MediaPlaybackState.preprocessing: {
        return MediaStatusTranscoding()
      }
      case MediaPlaybackState.offlined: {
        return MediaStatusOffline()
      }
      case MediaPlaybackState.failedToUpload: {
        return MediaStatusOffline()
      }
      default: {
        return MediaStatusOffline()
      }
    }
  }

  addElement = (mediaElements: MediaElements, id: string, zIndex: number) => {
    const { videoCtx } = this

    if (!(id in mediaElements)) {
      console.error('Could not find media element with id', id, mediaElements)
      return
    }

    const {
      start,
      duration,
      startOnTimeline,
      type,
      mediaId,
      position,
      playbackRate,
      volume
    } =
      //  BANG: Literally checking that the id exists two line above
      mediaElements[id]!

    const media = mediaStore.medias[mediaId]

    if (!media) {
      console.error('Could not find media', mediaId, mediaStore.medias)
      return
    }

    const { source, mediaPlaybackState } = media

    if (videoCtx) {
      let node: SourceNodeType | undefined
      switch (type) {
        case MediaType.video:
          node = videoCtx.video(source, mediaId, start, 15)
          break
        case MediaType.audio:
          node = videoCtx.audio(source, mediaId, start)
          break
        case MediaType.text: {
          if (!(id in this.drawingCanvas)) {
            const canvas = document.createElement('canvas')
            this.drawingCanvas[id] = canvas
          }

          node = this.initialDrawTextOnCanvas({
            id,
            textInterface: mediaStore.mediaObject<TextInterface>(mediaId)
          })!

          break
        }
        case MediaType.backgroundElement: {
          if (!(id in this.drawingCanvas)) {
            const canvas = document.createElement('canvas')
            this.drawingCanvas[id] = canvas
          }

          node = this.drawBackgroundElementOnCanvas({
            id: id,
            backgroundElement:
              mediaStore.mediaObject<BackgroundElementInterface>(mediaId)
          })

          break
        }
        case MediaType.image: {
          node = videoCtx.image(source, mediaId)
          break
        }
        case MediaType.tlDrawElement: {
          console.log(mediaId, 'tlDrawElement not creating node')
          return
        }
        default:
          throw new Error('Invalid node type')
      }

      if (!node) {
        toastError(`Failed to create element of type ${type}`)
        return
      }

      node.startAt(startOnTimeline)
      node.stopAt(startOnTimeline + duration)
      this.setPositionOnNode(node, position)

      this.contextNodes[id] = { node, type, zIndex }

      if (playbackRate) {
        this.updateNodePlaybackRate(id, playbackRate)
      }
      if (typeof volume === 'number') {
        this.updateNodeVolume(id, volume)
      }

      media.addVideoContextCallback(
        /**
         * This will transfer state from Media Class -> Video Context
         */
        MediaPlaybackStateCallback((newState) => {
          const mediaStatus = this.mediaPlaybackStateToMediaStatus(newState)

          node?.setMediaStatus(mediaStatus)
        })
      )

      media.addVideoContextCallback(
        SourceChangeCallback((source) => {
          if (type !== MediaType.text) {
            // Video, Audio and Image Nodes have refresh function on them
            ;(node as MediaNode<MediaSrc, 'video'>).refresh(source)
          }
        })
      )

      const mediaStatus =
        this.mediaPlaybackStateToMediaStatus(mediaPlaybackState)

      node.setMediaStatus(mediaStatus)

      node.connect(videoCtx.destination, zIndex)
    }
  }

  addElementsInTrack = (
    mediaElements: MediaElements,
    track: string[],
    zIndex: number
  ) => {
    for (const elm of track) {
      this.addElement(mediaElements, elm, zIndex)
    }
  }

  /**
   * This is the primarily function for the video context lifecycle
   *
   * It is triggered whenever media or tracks change and it refreshes and reconnects all the nodes
   *
   * This is a temporary solution, in the future we definitely do not want to reconnect all of these nodes everytime.
   *
   * You can optionally pass in a new canvas element, which is to be used when editor modes are swapped and a new canvas
   * element is created.
   *
   * @deprecated - Should not be used anymore
   */
  initNodes = (
    tracks: MediaTrack,
    mediaElements: MediaElements,
    playheadInSeconds: number,
    options?: { newCtx?: VideoContext; noReset?: boolean }
  ) => {
    const noReset = options?.noReset
    const newCtx = options?.newCtx
    try {
      let videoCtx: VideoContext = this.videoCtx!
      /**
       * This removes all callbacks and all nodes
       *
       * https://sourcegraph.modfy.video/github.com/modfy/VideoContext/-/blob/src/videocontext.js#L1022:37
       */
      if (!noReset) {
        videoCtx?.reset()
      }

      if (newCtx) {
        this.setContext(newCtx)
        videoCtx = newCtx
        newCtx.toggleUpdater(false)
      }

      const addElementsInTrack = (tracks: string[], zIndex: number) => {
        this.addElementsInTrack(mediaElements, tracks, zIndex)
      }

      /**
       * We want to connect nodes in a bottom up manner, that is we connect the bottom most nodes first then go from there
       *
       * So we start with below tracks, and connect the last below track first
       * Then connect prime track
       * Then connect above tracks
       */
      for (let i = tracks.below.length - 1; i >= 0; i--) {
        const track = tracks.below[i]
        if (track) {
          addElementsInTrack(track, -(i + 1))
        }
      }

      addElementsInTrack(tracks.prime, 0)

      tracks.above.forEach((track, index) => {
        addElementsInTrack(track, index + 1)
      })

      if (!this.videoCtx)
        throw new Error('video ctx undefined while initing nodes')

      this.videoCtx.currentTime = parseFloat(playheadInSeconds.toFixed(2))
    } catch (err) {
      console.error((err as any).message)
    }
  }

  @action refreshTracks = (
    tracks: MediaTrack,
    mediaElements: MediaElements
  ) => {
    /**
     * Handle Track updates with three main conditions
     *
     * 1. No change, element on same track
     * 2. Moved tracks, we will move the elements zPosition
     * 3. New element, we will add element
     */
    const selectedId = store.getState().media.previewSelectedMedia.id

    const handleTrack = (track: string[], trackZIndex: number) => {
      const { contextNodes, videoCtx } = this
      if (videoCtx) {
        for (const id of track) {
          if (id in contextNodes) {
            // BANG: Checking in the line above
            const { zIndex, node } = contextNodes[id]!
            if (zIndex !== trackZIndex) {
              node.moveZPosition(videoCtx.destination, trackZIndex)
              // BANG: Checking this
              this.contextNodes[id]!.zIndex = trackZIndex
            }
          } else {
            // Ignoring selected media as they are rendered on their own
            if (selectedId === id) {
              return
            }

            this.addElement(mediaElements, id, trackZIndex)
          }
        }
      }
    }

    for (let i = tracks.below.length - 1; i >= 0; i--) {
      const track = tracks.below[i]
      if (track) {
        handleTrack(track, -(i + 1))
      }
    }

    handleTrack(tracks.prime, 0)

    tracks.above.forEach((track, index) => {
      handleTrack(track, index + 1)
    })
  }

  /**
   * This is called when an error occurs in one of the source nodes inside video context
   *
   *
   * TODO: Pass media id down to video context and throw error with it
   */
  handleMediaError = (err: MediaErrorEvent) => {
    console.log('handleMediaError', err)
    try {
      // const id = getIdFromReadUrl(err.url)
      const id = ''
      const media = mediaStore.medias[id]
      if (!media) throw new Error(`Media error on non-existing media ${id}`)

      // If media is not already errored and the uploading has finished then set media's state to error
      if (isPlayable(media.mediaPlaybackState))
        media.updateMediaPlaybackState(MediaPlaybackState.offlined)
    } catch (err) {
      console.log('handleMediaError error', err)
    }
  }

  refreshNode = (id: string) => {
    const { videoCtx, contextNodes } = this
    if (videoCtx && id in contextNodes) {
      // BANG: Checking in the line above
      const { node } = contextNodes[id]!
      node.refresh() // This function will exists on VideoNode
    }
  }

  /**
   * Used to refresh all source nodes related to a media
   * eg. when a media is offline and it becomes available, node needs to be refreshed
   */
  refreshMedia = (id: string) => {
    const media = mediaStore.medias[id]
    if (!media) return
    media.elements.forEach((elemId) => this.refreshNode(elemId))
  }

  @action removeNode = async (id: string) => {
    const { videoCtx, contextNodes } = this
    if (videoCtx && id in contextNodes) {
      // BANG: Checking in the line above
      const { node } = contextNodes[id]!
      node.disconnect(videoCtx.destination)
      node.destroy()

      delete this.contextNodes[id]
      console.log('Removed node', id, this.videoCtx?.currentFrame)

      await this.videoCtx?.updateFrame(5)

      console.log('Updated frame', this.videoCtx?.currentFrame)
    }
  }

  @action moveNode = (id: string, medias: MediaElements) => {
    const { contextNodes } = this

    if (id in contextNodes) {
      try {
        // BANG: Checking in the line above
        const { node } = contextNodes[id]!

        const media = medias[id]

        if (!media)
          throw new Error(`media for ${id} is undefined while moving node `)

        const { start, duration, startOnTimeline } = media

        node.move(startOnTimeline, start, duration)
      } catch (err) {
        console.error(err)
      }
    }
  }

  @action moveZPosition = (id: string, zIndex: number) => {
    const { contextNodes, videoCtx } = this

    if (id in contextNodes && videoCtx) {
      // BANG: Checking in the line above
      const { node } = contextNodes[id]!

      node.moveZPosition(videoCtx.destination, zIndex)
    }
  }

  /**
   * Sets the size and position on source node
   * @param node The source node to set position on
   * @param position the position to set
   */
  @action setPositionOnNode = (
    node: SourceNodeType,
    position: MediaPosition
  ) => {
    if (!this.videoCtx) throw new Error('Video ctx not defined')
    const { width: cw, height: ch } = settingStore.dimensions
    const { width, height, x, y } = position

    // Even though VideoContext will adjust the Y position
    // we still have to set it here because sometimes resizing will
    // move position (e.g. resizing from one of the corners).
    // Later we can change VideoCtx to invert Y
    node.setPosition(cw, ch, [x, -y + (ch - height)])
    node.setSize(cw, ch, [width, height])

    // The `this.videoCtx.degrees` function just returns the same value but gives it the Degrees type so
    // videoctx can accept it
    // Negate degrees so it is more intuitive for the user
    node.setRotation(this.videoCtx.degrees(-(position.rotationDegrees || 360)))
  }

  @action updateNodePlaybackRate = (id: string, playbackRate: number) => {
    const { contextNodes } = this

    if (id in contextNodes) {
      try {
        // BANG: Checking in if statement
        const { node } = contextNodes[id]!

        const nodeTyped = node as MediaNode<MediaSrc, 'video'>

        // We are forcefully typing this here, some node may not have a playback rate to set
        if (nodeTyped.playbackRate) {
          nodeTyped.playbackRate = playbackRate
        } else {
          warnError(
            'The node you are trying to update playback rate for does not have a playback rate'
          )
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  @action updateNodePosition = (id: string, position: MediaPosition) => {
    const { contextNodes } = this

    if (id in contextNodes) {
      try {
        // BANG: Checking in the line above
        const { node } = contextNodes[id]!

        this.setPositionOnNode(node, position)
      } catch (err) {
        console.error(err)
      }
    }
  }

  @action updateNodeVolume = (id: string, volume: number) => {
    const { contextNodes } = this

    if (id in contextNodes) {
      try {
        // BANG: Checking in the line above
        const { node } = contextNodes[id]!

        const nodeTyped = node as MediaNode<MediaSrc, 'video'>

        console.log('setting volume')
        nodeTyped.volume = volume
      } catch (err) {
        console.error(err)
      }
    } else {
      console.log(`node not found ${id}`)
    }
  }
}

export default new VideoContextStore()
