import { MediaElementInterface } from '@editor/interfaces'

import { getRealStartAndEnd } from './realStartEnd'

export const collidesPure = (
  realStart: number,
  realEnd: number,
  curStart: number,
  curEnd: number
): boolean => {
  return (
    // [   |   ] ---->
    //     [      ]
    (realStart <= curStart && realEnd >= curStart) ||
    // |  [ ] |
    // [     ]
    (realStart >= curStart && realEnd <= curEnd) ||
    //  |   [ |     ]
    // [     ]
    (realStart >= curStart && realStart <= curEnd)
  )
}

export const collides = (
  checkElement: MediaElementInterface,
  collider: MediaElementInterface
) => {
  const { realStart, realEnd } = getRealStartAndEnd(checkElement)
  const { realStart: curStart, realEnd: curEnd } = getRealStartAndEnd(collider)
  return collidesPure(realStart, realEnd, curStart, curEnd)
}
