/**
 * Utility functions for MediaPlayable state
 *
 * More of docs on the MediaPlaybackState is available with the enum definition
 *
 * This file is tsx because functions in this return classes which need to be generated by tailwind
 */

import { MediaPlaybackState } from '@editor/interfaces'

/**
 * Helper function for MediaPlaybackState which checks if the media is ready to play
 */
export const isPlayable = (mediaPlaybackSate: MediaPlaybackState) => {
  switch (mediaPlaybackSate) {
    case MediaPlaybackState.playable: {
      return true
    }
    /**
     * This is temporary cushion as we merge branches it is intended to be removed
     */
    case MediaPlaybackState.uploaded: {
      return true
    }
    default: {
      return false
    }
  }
}

/**
 * Helper function for MediaPlaybackState which checks if the state is errored
 */
export const isErrored = (mediaPlaybackSate: MediaPlaybackState) => {
  switch (mediaPlaybackSate) {
    case MediaPlaybackState.offlined: {
      return true
    }
    case MediaPlaybackState.failedToUpload: {
      return true
    }
    case MediaPlaybackState.noMedia: {
      return true
    }
    default: {
      return false
    }
  }
}

export const getMediaPlaybackStateStyle = (
  mediaPlaybackState: MediaPlaybackState
): {
  previewMessage: string
  bgColor: string
  barColor: string
  textColor: string
} | void => {
  switch (mediaPlaybackState) {
    case MediaPlaybackState.uploading: {
      return {
        previewMessage: 'Uploading ...',
        bgColor: 'bg-green-700',
        barColor: 'bg-green-500',
        textColor: 'text-green-500'
      }
    }
    case MediaPlaybackState.downloading: {
      return {
        previewMessage: 'Downloading...',
        bgColor: 'bg-yellow-400',
        barColor: 'bg-yellow-300',
        textColor: 'text-yellow-300'
      }
    }
    case MediaPlaybackState.preprocessing: {
      return {
        previewMessage: 'Transcoding...',
        bgColor: 'bg-yellow-400',
        barColor: 'bg-yellow-300',
        textColor: 'text-yellow-300'
      }
    }
    case MediaPlaybackState.offlined: {
      return {
        previewMessage: 'Error Loading',
        bgColor: 'bg-red-700',
        barColor: 'bg-red-500',
        textColor: 'text-red-500'
      }
    }
    case MediaPlaybackState.failedToUpload: {
      return {
        previewMessage: 'Failed to upload',
        bgColor: 'bg-red-700',
        barColor: 'bg-red-500',
        textColor: 'text-red-500'
      }
    }
  }
}
