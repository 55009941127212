import { levaStore, useControls } from 'leva'
import { Schema } from 'leva/src/types'
import { useEffect } from 'react'
import create from 'zustand'

export const useProgrammaticSchema = create<{
  schema: Schema
  setSchema: (_schema: Schema) => void
}>((set) => ({
  schema: {},
  setSchema: (schema) =>
    set((state) => ({ ...state, schema: { ...state.schema, ...schema } }))
}))

export const adminStore = useProgrammaticSchema

export const updateAdminSchema = (schema: Schema) => {
  adminStore.setState({
    schema: { ...adminStore.getState().schema, ...schema }
  })
}

export const setAdminValue = (path: string, value: any) => {
  levaStore.set({ [path]: value }, true)
}

export const upsertAdminString = (
  path: string,
  value: string,
  label?: string
) => {
  const data = levaStore.get(path)
  if (data) {
    levaStore.set({ [path]: value }, true)
  } else {
    updateAdminSchema({
      [path]: {
        value: value,
        rows: true,
        editable: false,
        label
      }
    })
  }
}

export const adminLogger = (path: string, newLog: string) => {
  const data = levaStore.get(`${path}.log`)

  if (data) {
    levaStore.set({ [`${path}.log`]: `${data} \n ${newLog}` }, true)
  } else {
    updateAdminSchema({
      [`${path}.log`]: {
        value: newLog,
        label: 'log',
        rows: true,
        editable: false
      }
    })
  }
}

export type SchemaItemWithOptions = Schema extends Record<string, infer P>
  ? P
  : never

export const useReactiveControls = (
  path: string,
  schema: SchemaItemWithOptions
) => {
  // @ts-ignore
  const value: any | undefined = schema.value || schema

  const [_, set] = useControls(() => ({
    [path]: schema
  }))

  useEffect(() => {
    set({ [path]: value })
  }, [path, set, value])
}

export const useSetOnChange = (path: string, value: any) => {
  useEffect(() => {
    setAdminValue(path, value)
  }, [path, value])
}
