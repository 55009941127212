import {
  EndPoints,
  MediaElementInterface,
  MediaElements
} from '@editor/interfaces'

/**
 * Returns a transformed value based on various bounding boxes
 *
 * See the getTransformedValue.tldr for some more explanation
 *
 * For start:
 * 1. It should be greater than the defaultStart
 *    (which is 0 by default, or the min start value if this is a cut element)
 * 2. It should be less than the end of media (current start + duration)
 *
 * For duration:
 * 1. It should not be less than 0
 * 2. It should not be more than (usableDuration - how much start point has moved (start - defaultStart))
 *    (describe more below)
 *
 * @param point : {@link EndPoints} - which endpoint the value is meant for
 * @param value : number - which is the value of given endpoint
 */
export const computeTransformedValue = (
  mediaElement: MediaElementInterface,
  point: EndPoints,
  value: number
) => {
  const { start, duration, usableDuration, defaultStart } = mediaElement

  // this value will get updated according to constraints and returned
  let newValue = value

  if (point === 'duration') {
    const deltaStart = start - defaultStart
    const maxDuration = usableDuration - deltaStart

    newValue = clamp(0, value, maxDuration)
  } else if (point === 'start') {
    const currentEnd = start + duration

    // Infinite elements can move start backwards infinitely
    const isInfiniteElem = usableDuration === Infinity
    const minStart = isInfiniteElem ? -Infinity : defaultStart

    newValue = clamp(minStart, value, currentEnd)
  }

  return newValue
}

export const getTransformedValue = (
  id: string,
  mediaElements: MediaElements,
  point: EndPoints,
  value: number
) => {
  const currentMediaElement = mediaElements[id]

  if (!currentMediaElement) throw new Error('Media element does not exist')

  return computeTransformedValue(currentMediaElement, point, value)
}

const clamp = (min: number, value: number, max: number) => {
  if (value < min) return min
  if (value > max) return max
  return value
}
