import { action, computed, makeAutoObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import {
  FileData,
  FileNode,
  FilePanelSelections,
  FileSelections,
  FilesMap,
  FileTreeNode,
  FileViewerMode,
  FileViewerModeEnum,
  FolderNode,
  FoldersMap,
  isFileNode,
  isFileTreeNode,
  isFolderNode,
  MultiplayerActions,
  PanelMap
} from '@editor/interfaces'
import { arrayHasAll } from '@editor/utils'

import { executeMultiplayerActions } from '../multiplayer'

import {
  getFolderChildren,
  makeFileNode,
  makeFolderNode
} from './helpers/fileStoreUtils'
import { mediaStore } from '.'

const folderNotFoundError = (id: string) =>
  new Error(`Folder with id ${id} not found`)
const fileNotFoundError = (id: string) =>
  new Error(`File with id ${id} not found`)
const nodeNotFoundError = (id: string) =>
  new Error(`Node with id ${id} not found`)

/**
 * Files and folders are stored in a flattened structure:
 * - folders are in an object of IDs pointing to a FolderNode
 * - files are in an object of IDs pointing to a FileNode
 *
 * The root folder has the ID 'root' and cannot be deleted.
 *
 * Refer to the wiki for more information:
 * https://www.notion.so/modfy/File-Viewer-b86088dc793d48ebae064e6403cfc4cb
 */
class FileStore {
  /**
   * Used for cycling through FileViewerModes
   */
  @observable viewerModeIdx = 0
  @observable viewerMode: FileViewerMode = FileViewerModeEnum[0]!

  /**
   * ID of the root
   */
  @observable root: string = 'root'
  /**
   * Mapping of Folder ID -> FolderNode
   */
  @observable folders: FoldersMap = {
    root: makeFolderNode('root', 'root', [])
  }

  /**
   * Mapping of File ID -> FolderNode
   */
  @observable files: FilesMap = {}

  /**
   * Mapping of FileTreeNode ID -> Preview Blob URL for use in
   * panel view mode
   */
  /**
   * The panels (folders and their items) that are visible in panel view mode
   */
  @observable panelSelections: FilePanelSelections = ['root']
  /**
   * The files that are highlighted for each panel
   */
  @observable fileSelections: FileSelections = [undefined, undefined]
  /**
   * ID of the selected folder breadcrumbs in tile view mode
   */
  @observable selectedFoldersBreadcrumb: string[] = ['root']
  @observable selectedFolder: string = 'root'

  /**
   * This is to decide the file shown on the preview
   */
  @observable selectedFile: string | null = null

  constructor() {
    makeAutoObservable(this)

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.fileStore = this
    }
  }

  @action reset() {
    this.root = 'root'
    this.folders = {
      root: makeFolderNode('root', 'root', [])
    }
    this.files = {}
    this.panelSelections = ['root']
    this.fileSelections = [undefined, undefined]
    this.selectedFoldersBreadcrumb = ['root']
    this.selectedFolder = 'root'
    this.selectedFile = null
  }

  @action init = (root: string, folders: FoldersMap, files: FilesMap) => {
    this.root = root
    for (const folder of Object.values(folders)) {
      this.folders[folder.id] = folder
      executeMultiplayerActions([
        { type: MultiplayerActions.SetFolder, payload: folder }
      ])
    }
    for (const file of Object.values(files)) {
      this.files[file.id] = file
      executeMultiplayerActions([
        { type: MultiplayerActions.SetFile, payload: file }
      ])
    }
  }

  /**
   * Checks if every file loads, if it doesn't mark the medias as offline
   */
  @action checkFileIntegrity = async () => {
    const files = { ...this.files }

    for (const file of Object.values(files)) {
      const media = mediaStore.medias[file.id]
      if (!media) continue
      try {
        // if this throws an error that means the file is missing
        await fetch(media.source, {
          headers: { Range: '0-1' },
          redirect: 'follow'
        })
      } catch (err) {
        // media.updateMediaPlaybackState(MediaPlaybackState.failedToUpload)
      }
    }

    this.files = { ...files }
  }

  getSelectedFolder = () => {
    // BANG: This will never be out of bounds
    return this.selectedFoldersBreadcrumb[
      this.selectedFoldersBreadcrumb.length - 1
    ]!
  }

  @action setSelectedFolder = (folderOrId: string | FolderNode) => {
    let id: string
    if (typeof folderOrId === 'string') {
      id = folderOrId
    } else {
      id = folderOrId.id
    }

    const idx = this.selectedFoldersBreadcrumb.indexOf(id)
    // User is moving back, so cascade all subsequent folders:
    // 1. root -> folder_1 -> folder_2 -> folder_3
    // 2. root -> folder_1
    if (idx !== -1) {
      this.selectedFoldersBreadcrumb = this.selectedFoldersBreadcrumb.slice(
        0,
        idx + 1
      )
      return
    }

    // If the folder is not in our breadcrumb then the user is adding it,
    // since that is the only remaining possible interaction in tile view mode
    this.selectedFoldersBreadcrumb = this.selectedFoldersBreadcrumb.concat(id)
  }

  @action setViewerMode = (mode: FileViewerMode) => {
    this.viewerMode = mode
  }

  getNode = (id: string): FileNode | FolderNode | undefined => {
    if (this.folders[id]) return this.folders[id]
    if (this.files[id]) return this.files[id]
  }

  @action handleMultiplayerFileSet = (file: FileNode) => {
    this.files = { ...this.files, [file.id]: file }
  }

  @action handleMultiplayerFileRemove = (fileId: string) => {
    delete this.files[fileId]
  }

  @action handleMultiplayerFolderSet = (folder: FolderNode) => {
    this.folders = { ...this.folders, [folder.id]: folder }
  }

  @action handleMultiplayerFolderRemove = (folderId: string) => {
    delete this.folders[folderId]
  }

  /**
   * Adds a file to a directory, defaults to root dir
   * @param file FileData object minus the id property, this will be added
   * @param fileId The ID for the FileTreeNode
   * @param dir The ID of the parent
   * @returns FileData object with the id set to the new id
   */
  @action add = async (
    file: FileData,
    fileId: string,
    dir: string = this.root
  ): Promise<FileData> => {
    const folder = this.folders[dir]
    if (!folder) throw new Error('Unknown folder')

    const fileNode = makeFileNode(file, fileId)
    this.folders = {
      ...this.folders,
      [dir]: { ...folder, children: [...folder.children, fileId] }
    }
    this.files[fileId] = fileNode

    executeMultiplayerActions([
      {
        type: MultiplayerActions.SetFolder,
        // BANG: We set it above so okay to bang
        payload: this.folders[dir]!
      },
      { type: MultiplayerActions.SetFile, payload: fileNode }
    ])
    return file as FileData
  }

  @action updateFile = (newFile: FileNode) => {
    this.files = {
      ...this.files,
      [newFile.id]: newFile
    }

    executeMultiplayerActions([
      { type: MultiplayerActions.SetFile, payload: newFile }
    ])
  }

  /**
   * Removes a directory, deleting children if necessary
   */
  @action removeDir = async (id: string): Promise<void> => {
    if (id === 'root') throw new Error("Can't delete root")
    const folder = this.folders[id]
    if (!folder) throw folderNotFoundError(id)

    // TODO: This is really inefficient, we should instead collect a
    // list of IDs and remove those here instead
    for (const childId of folder.children) {
      await this.remove(childId)
    }

    const parent = Object.keys(this.folders)
      // BANG: Because we are iterating over keys
      .map((folderId) => this.folders[folderId]!)
      .find((folder) => folder.children.includes(id))

    if (!parent) throw new Error(`Parent not found for node with id ${id}`)

    this.folders[parent.id] = {
      ...parent,
      children: [...parent.children.filter((childId) => childId !== id)]
    }

    delete this.folders[id]

    this.panelSelections = this.panelSelections.filter(
      (selectionId) => selectionId !== id
    )

    executeMultiplayerActions([
      {
        type: MultiplayerActions.SetFolder,
        // BANG: We set it above
        payload: this.folders[parent.id]!
      },
      {
        type: MultiplayerActions.DeleteFolder,
        payload: id
      }
    ])
  }

  /**
   * Removes a file from the file viewer
   * Side effect will remove file from mediaStore
   * @param id - Media id
   */
  @action removeFile = async (id: string, parent: string): Promise<void> => {
    const file = this.files[id]
    if (!file) throw fileNotFoundError(id)

    const folder = this.folders[parent]
    if (!folder) throw folderNotFoundError(id)

    this.folders[folder.id] = {
      ...folder,
      children: [...folder.children.filter((childId) => childId !== id)]
    }
    delete this.files[id]

    await mediaStore.removeMedia(id)

    // Here also removing separately cause we cant delete from
    // copy and then pass to multiplayer action
    executeMultiplayerActions([
      { type: MultiplayerActions.DeleteFile, payload: id },
      {
        type: MultiplayerActions.SetFolder,
        // BANG: We set it above
        payload: this.folders[folder.id]!
      }
    ])
  }

  /**
   * Removes a node. If it is a folder it will also delete all of its children.
   * If it is a file it will delete it from cache and cloud storage too.
   */
  @action remove = async (id: string, dir?: string): Promise<void> => {
    const folder = this.folders[id]
    if (folder) {
      await this.removeDir(id)
      return
    }

    await this.removeFile(id, dir || 'root')
  }

  /**
   * Adds a folder. If a folder with the same name exists in the parent folder,
   * it will append a number to make it unique
   * @param label The name of the folder
   * @param dir The id of the parent folder
   * @returns FolderNode object with the id set to the new id, and new label if it changes
   */
  @action addDir = (label: string, dir: string = this.root): FolderNode => {
    const folder = this.folders[dir]
    if (!folder) throw new Error('Unknown parent')
    if (label === '') {
      label = 'Untitled folder'
    }

    const id = nanoid()
    const newFolder = makeFolderNode(label, id)

    const names = getFolderChildren(folder, this.files, this.folders).map(
      (child) => child.label
    )

    if (!names.includes(label)) {
      this.folders[id] = { ...newFolder }
      this.folders[dir] = { ...folder, children: [...folder.children, id] }

      executeMultiplayerActions([
        {
          type: MultiplayerActions.SetFolder,
          // BANG: We set it above
          payload: this.folders[id]!
        },
        {
          type: MultiplayerActions.SetFolder,
          // BANG: We set it above
          payload: this.folders[dir]!
        }
      ])
      return newFolder
    }

    if (names.includes(label)) {
      let i = 1
      let newLabel = `${label} ${i}`
      while (names.includes(newLabel)) {
        newLabel = `${label} ${i}`
        i += 1
      }
      newFolder.label = newLabel
    }

    this.folders[id] = { ...newFolder }
    this.folders[dir] = { ...folder, children: [...folder.children, id] }

    executeMultiplayerActions([
      {
        type: MultiplayerActions.SetFolder,
        // BANG: We set it above
        payload: this.folders[id]!
      },
      {
        type: MultiplayerActions.SetFolder,
        // BANG: We set it above
        payload: this.folders[dir]!
      }
    ])

    return newFolder
  }

  /**
   * Set or update a folder with the given data
   */
  @action setFolder = (id: string, data: Partial<FolderNode>) => {
    const folder = this.folders[id]
    if (!folder && !isFolderNode(data))
      throw new Error('data is missing some keys')

    this.folders[id] = folder
      ? { ...folder, ...data }
      : ({ id, ...data } as FolderNode)
    executeMultiplayerActions([
      {
        type: MultiplayerActions.SetFolder,
        // BANG: We checked/set it above
        payload: this.folders[id]!
      }
    ])
  }

  /**
   * Set the panel selection for the given index. It will clear all
   * selections after, (because subsequent sub-folders will be invalid)
   */
  @action setPanelSelection = (
    idx: number,
    node: FolderNode | string | undefined
  ): void => {
    this.panelSelections = [
      // Selections after `idx` will be sub-folders
      // so we must clear them
      ...this.panelSelections.slice(0, idx),
      isFolderNode(node) ? node?.id : node
    ]

    this.fileSelections = [...this.fileSelections.slice(0, idx)]
  }

  /**
   * Clears panel selections after `idx`
   */
  @action cascadePanelSelections = (idx: number): void => {
    this.panelSelections = this.panelSelections.slice(0, idx + 1)
    this.fileSelections = this.fileSelections.slice(0, idx)
  }

  /**
   * Sets the file selection at the specified index
   */
  @action setFileSelection = (
    idx: number,
    node: FileTreeNode | string | undefined
  ): void => {
    this.fileSelections = this.fileSelections
      .slice(0, idx)
      .concat([
        !node ? undefined : isFileTreeNode(node) ? node.id : node,
        ...this.fileSelections.slice(idx + 1)
      ])
  }

  /**
   * Cycle to the next view mode
   */
  @action cycleViewMode = () => {
    this.viewerModeIdx += 1
    this.viewerMode =
      // BANG: We mod by the length of the array so its guaranteed to be defined
      FileViewerModeEnum[this.viewerModeIdx % FileViewerModeEnum.length]!
  }

  /**
   * Renames a node
   */
  @action rename = (name: string, id: string) => {
    const folder = this.folders[id]
    if (folder) {
      // BANG: We literally checked above
      this.folders[id]!.label = name
      executeMultiplayerActions([
        {
          type: MultiplayerActions.SetFolder,
          // BANG: We literally checked above
          payload: this.folders[id]!
        }
      ])
      return
    }

    const file = this.files[id]
    if (file) {
      // BANG: We literally checked above
      this.files[id]!.label = name
      this.files[id]!.data.name = name
      executeMultiplayerActions([
        {
          type: MultiplayerActions.SetFile,
          payload: this.files[id]!
        }
      ])
      return
    }

    throw nodeNotFoundError(id)
  }

  /**
   * Propagates changes to fileStore
   */
  @action syncToStore = (panelMap: PanelMap) => {
    for (const panelId of Object.keys(panelMap)) {
      this.setFolder(panelId.slice(10), {
        children: panelMap[panelId]
      })
    }
    executeMultiplayerActions([
      { type: MultiplayerActions.updateFolders, payload: panelMap }
    ])
    return panelMap
  }

  /**
   * For caching the file size calculation
   */
  filesCalculationCache: { files: Array<string>; size: number } = {
    files: [],
    size: 0
  }

  /**
   * Traverses file tree, calculating the entire size in MB. The
   * computation is cached and safe to call many times
   */
  calculateTotalSize = () => {
    // File sizes won't change so our cache is fresh if the file IDs are equal
    if (arrayHasAll(Object.keys(this.files), this.filesCalculationCache.files))
      return this.filesCalculationCache.size

    let bytes = 0
    for (const id of Object.keys(this.files)) {
      // BANG: We are iterating over the keys
      bytes += this.files[id]!.data.size
    }

    const size = bytes // megabyte
    this.filesCalculationCache = { files: Object.keys(this.files), size }
    return size
  }

  public calculateSize = (nodeOrId: FileNode | FolderNode | string): number => {
    let node: FileTreeNode
    if (typeof nodeOrId === 'string') {
      node = this.getNode(nodeOrId)!
      if (!node) throw nodeNotFoundError(nodeOrId)
    } else {
      node = nodeOrId
    }

    if (isFileNode(node)) return node.data.size

    let sum = 0
    for (const child of (node as FolderNode).children) {
      sum += this.calculateSize(child)
    }

    return sum
  }

  @computed
  get stateString() {
    const folderList = Object.keys(this.folders).toString()
    const fileList = Object.keys(this.files).toString()
    const panelList = this.panelSelections.toString()
    const fileSelectionList = this.fileSelections.toString()
    return `${folderList}-${fileList}-${panelList}-${fileSelectionList}`
  }

  // Selected File for Preview

  @action unSelectFile = () => {
    this.selectedFile = null
  }

  @action setSelectedFile = (fileId: string) => {
    this.selectedFile = fileId
  }
}

export default new FileStore()
