import axios from 'axios'

const getUploadUrl = (id: string, type: string) =>
  `/api/cdn/getSignedUploadURL?contentType=${type}&id=${id}`

const getStartMultipartUploadUrl = (id: string, type: string) =>
  `/api/cdn/multipartUpload/start?contentType=${type}&id=${id}`

const getCompleteMultipartUploadUrl = (id: string, uploadId: string) =>
  `/api/cdn/multipartUpload/complete?id=${id}&uploadId=${uploadId}`

export const getSignedUploadURL = async (id: string, type: string) => {
  const path = getUploadUrl(id, type)

  try {
    const response = await axios.get<{ url: string }>(path)
    return response.data.url
  } catch (err) {
    throw new Error('Could not fetch signed url')
  }
}

export const getMultipartUploadUrls = async (id: string, type: string) => {
  const path = getStartMultipartUploadUrl(id, type)

  try {
    const response = await axios.get<{ urls: string[]; uploadId: string }>(path)
    return response.data
  } catch (err) {
    throw new Error('Could not fetch multipart upload urls')
  }
}

export const completeMultipartUpload = async (id: string, uploadId: string) => {
  const path = getCompleteMultipartUploadUrl(id, uploadId)

  try {
    const response = await axios.get<{ error: boolean }>(path)
    return response.data
  } catch (err) {
    throw new Error('Could not complete multipart upload')
  }
}

/**
 * To manually upload a file without it being handled by sw
 */
export const addManualAsset = async ({
  id,
  contentType,
  data
}: {
  id: string
  contentType: string
  data: Blob
}) => {
  // Get url to upload to
  const {
    data: { url }
  } = await axios.get<{ url: string }>(
    `/api/cdn/getSignedUploadURL?id=${id}&contentType=${contentType}`
  )

  // upload to that url
  await axios.put(url, data, { headers: { 'Content-Type': contentType } })

  const readUrl = `/api/manualGet/${id}`

  return readUrl
}
