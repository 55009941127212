import {
  AudioFormat,
  AudioInterface,
  MediaPlaybackState,
  MediaProps,
  MediaType
} from '@editor/interfaces'
import { mediaStore } from '@editor/state/mobx'

import { getAudioBufferFromUrl, getPathFromAudioBuffer } from '../helpers/audio'

import { Media } from './Media'
import { Video } from './Video'

export type AudioProps = Omit<MediaProps, 'type'> & {
  _id: string
  format: AudioFormat
  linkedVideoId?: string
}

export class Audio extends Media implements AudioInterface {
  format: AudioFormat
  linkedVideoId?: string
  defaultPosition = null

  constructor({ linkedVideoId, format, ...props }: AudioProps) {
    super({
      ...props,
      type: MediaType.audio
    })
    this.format = format
    this.linkedVideoId = linkedVideoId
  }

  get source() {
    if (!this.linkedVideoId) return super.source

    return mediaStore.mediaObject<Video>(this.linkedVideoId).source
  }

  static getFileProps = async (file: File) => {
    return Media.getFileProps(file, MediaType.audio)
  }

  /**
   * Generates a svg path of the audios waveform
   */
  generateSvgPath = async () => {
    const audioBuffer = await getAudioBufferFromUrl(this.source)

    const path = getPathFromAudioBuffer(audioBuffer)

    // Audio is considered playable after the svg is generated
    this.updateMediaPlaybackState(MediaPlaybackState.playable)

    return path
  }
}
